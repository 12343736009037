import gql from 'graphql-tag'

export const SHOP_BILLING = gql`
  query shopBilling {
    shop {
      id
      billingPlanStatus
      billingPlanType

      billingPaymentMethods {
        id
        brand
        last4
        expMonth
        expYear
        primary
      }
    }
  }
`

export const CREATE_PAYMENT_METHOD = gql`
  mutation BillingCreatePaymentMethod($token: String!) {
    billingCreatePaymentMethod(token: $token) {
      id
      brand
      last4
      expMonth
      expYear
      primary

      shop {
        id
        shopifyHostname
      }
    }
  }
`

export const BILLING_SUBSCRIBE = gql`
  mutation BillingSubscribe($planId: String!) {
    billingSubscribe(planId: $planId) {
      id
      name
      shopifyHostname
      billingPlanStatus
      billingPlanType
      shopifyHostname
      billingCustomerExternalId
    }
  }
`

export const BILLING_CANCEL = gql`
  mutation BillingCancel {
    billingCancel {
      id
      billingPlanStatus
      billingPlanType
      shopifyHostname
    }
  }
`
