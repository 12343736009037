import React, { useState } from 'react'
import { CirclePicker } from 'react-color'
import FloatingLabelInput from 'components/FloatingLabelInput'

const InputColorPicker = ({ colors, label, hex, onChange }) => {
  const [showPicker, setShowPicker] = useState(false)

  const handleChange = value => {
    const hexVal = value.hex.length === 0 ? '#' : value.hex[0] !== '#' ? `#${value.hex}` : value.hex
    onChange({ hex: hexVal.substring(0, 7) })
  }

  return (
    <div className="relative">
      <div className="absolute right-0 mr-2 mt-2 z-20">
        <div
          onClick={() => setShowPicker(!showPicker)}
          className="h-8 w-8 rounded shadow cursor-pointer"
          style={{ backgroundColor: hex }}
        />
      </div>
      <FloatingLabelInput
        className="relative z-10"
        label={label}
        onChange={e => handleChange({ hex: e.target.value })}
        value={hex}
      />
      {showPicker ? (
        <div className="absolute right-0 bg-white shadow rounded p-2 mr-1 z-40">
          <CirclePicker
            width="100%"
            colors={colors}
            hex={hex}
            onChange={handleChange}
            onChangeComplete={() => setShowPicker(false)}
          />
        </div>
      ) : null}
    </div>
  )
}

export default InputColorPicker
