import React from 'react'
import { useQuery } from '@apollo/client'
import { INTEGRATIONS } from './cards/GraphQL'
import IntegrationCard from './cards/IntegrationCard'

const ShopIntegrations = () => {
  const { data, loading } = useQuery(INTEGRATIONS)

  return (
    <div className="mt-4 flex items-start justify-between flex-wrap">
      <div className="w-full lg:w-1/2 pr-2">
        <IntegrationCard service="GOOGLE_AUTOCOMPLETE" data={data} loading={loading} />
      </div>

      <div className="w-full lg:w-1/2 pl-2">
        <IntegrationCard service="KLAVIYO" data={data} loading={loading} />
      </div>

      <div className="w-full lg:w-1/2 pr-2 mt-4">
        <IntegrationCard service="GOOGLE_TAG_MANAGER" data={data} loading={loading} />
      </div>
    </div>
  )
}

export default ShopIntegrations
