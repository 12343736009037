import React from 'react'
import { useQuery } from '@apollo/client'
import { CUSTOM_SCRIPTS } from './GraphQL'
import ScriptCard from './ScriptCard'

const ShopCustomScripts = () => {
  const { data, loading } = useQuery(CUSTOM_SCRIPTS)

  return (
    <div className="flex items-start justify-between flex-wrap">
      {loading ? (
        <div className="text-gray-400">Loading...</div>
      ) : (
        data.customScripts.map(script => (
          <div className="w-1/2 pr-2 mb-4" key={script.id}>
            <ScriptCard script={script} />
          </div>
        ))
      )}
    </div>
  )
}

export default ShopCustomScripts
