import gql from 'graphql-tag'

export const FUNNELS = gql`
  query funnels {
    funnels {
      id
      name
      tag
    }
  }
`

export const SMS_SHORT_LINK_DOMAINS = gql`
  query SmsShortLinkDomains {
    smsShortLinkDomains {
      id
      domain
    }
  }
`

export const TWILIO_NUMBERS = gql`
  query TwilioNumbers {
    shop {
      id

      twilioNumbers {
        id
        phoneNumber
      }
    }
  }
`

export const AVAILABLE_NUMBERS = gql`
  query AvilableNumbers {
    availableNumbers {
      phoneNumber
    }
  }
`

export const CLAIM_NUMBER = gql`
  mutation claimNumber($phoneNumber: String!) {
    claimNumber(phoneNumber: $phoneNumber) {
      id
      phoneNumber
    }
  }
`

export const RELEASE_NUMBER = gql`
  mutation releaseNumber($id: ID!) {
    releaseNumber(id: $id) {
      id
    }
  }
`

export const SMS_AUTOMATIONS = gql`
  query SmsAutomations {
    shop {
      id
      billingPlanType

      billingPaymentMethods {
        id
      }
    }

    smsAutomations {
      id
      name
      enabled
      trigger
      clickCount
      sendCount
      conversionRate
      revenue
      price
    }
  }
`

export const SMS_AUTOMATION = gql`
  query SmsAutomation($id: ID!) {
    smsAutomation(id: $id) {
      id
      name
      enabled
      trigger
      clickCount
      sendCount
      conversionRate
      revenue
      price

      twilioNumber {
        id
        phoneNumber
      }

      smsShortLinkDomain {
        id
        domain
      }

      funnels {
        id
        name
        tag
      }

      smsMessages {
        id
        delayUnit
        delayValue
        image
        imageSrc
        body
        clickCount
        sendCount
        conversionRate
        revenue
        price
      }
    }
  }
`

export const CREATE_SMS_AUTOMATION = gql`
  mutation createSmsAutomation($smsAutomation: CreateSmsAutomationInput!, $funnelIds: [ID!]!) {
    createSmsAutomation(smsAutomation: $smsAutomation, funnelIds: $funnelIds) {
      id
      name
      enabled
      trigger
      sendCount
      clickCount
      conversionRate
      revenue
    }
  }
`
export const UPDATE_SMS_AUTOMATION = gql`
  mutation updateSmsAutomation($id: ID!, $smsAutomation: UpdateSmsAutomationInput, $funnelIds: [ID!]) {
    updateSmsAutomation(id: $id, smsAutomation: $smsAutomation, funnelIds: $funnelIds) {
      id
      name
      enabled
      trigger
      sendCount
      clickCount
      conversionRate
      revenue
    }
  }
`

export const DESTROY_SMS_AUTOMATION = gql`
  mutation destroySmsAutomation($id: ID!) {
    destroySmsAutomation(id: $id) {
      id
    }
  }
`

export const CREATE_SMS_MESSAGE = gql`
  mutation createSmsMessage($smsAutomationId: ID!, $smsMessage: CreateSmsMessageInput!) {
    createSmsMessage(smsAutomationId: $smsAutomationId, smsMessage: $smsMessage) {
      id
      body
      delayUnit
      delayValue
      image
      imageSrc
    }
  }
`

export const UPDATE_SMS_MESSAGE = gql`
  mutation updateSmsMessage($smsAutomationId: ID!, $id: ID!, $smsMessage: UpdateSmsMessageInput!) {
    updateSmsMessage(smsAutomationId: $smsAutomationId, id: $id, smsMessage: $smsMessage) {
      id
      body
      delayUnit
      delayValue
      image
      imageSrc
    }
  }
`

export const DESTROY_SMS_MESSAGE = gql`
  mutation destroySmsMessage($smsAutomationId: ID!, $id: ID!) {
    destroySmsMessage(smsAutomationId: $smsAutomationId, id: $id) {
      id
    }
  }
`
