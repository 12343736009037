import React from 'react'
import ContentWrapper from 'components/ContentWrapper'

const Loading = () => {
  return (
    <>
      <div className="w-full">
        <div className="m-4">
          <div className="flex">
            <div className="flex-1 text-2xl font-light text-gray-800 align-top mb-2">Branding</div>
          </div>
          <ContentWrapper>
            <div className="flex">
              <div className="m-4">Loading...</div>
            </div>
          </ContentWrapper>
        </div>
      </div>
      <div className="w-full">
        <div className="m-4">
          <div className="flex">
            <div className="flex-1 text-2xl font-light text-gray-800 align-top mb-2">Integrations</div>
          </div>
          <ContentWrapper>
            <div className="flex">
              <div className="m-4">Loading...</div>
            </div>
          </ContentWrapper>
        </div>
      </div>
      <div className="w-full">
        <div className="m-4">
          <div className="flex">
            <div className="flex-1 text-2xl font-light text-gray-800 align-top mb-2">Tracking Pixels</div>
          </div>
          <ContentWrapper>
            <div className="flex">
              <div className="m-4">Loading...</div>
            </div>
          </ContentWrapper>
        </div>
      </div>
      <div className="w-full">
        <div className="m-4">
          <div className="flex">
            <div className="flex-1 text-2xl font-light text-gray-800 align-top mb-2">Payment Gateways</div>
          </div>
          <ContentWrapper>
            <div className="flex">
              <div className="m-4">Loading..</div>
            </div>
          </ContentWrapper>
        </div>
      </div>
    </>
  )
}

export default Loading
