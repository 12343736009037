import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { Switch, message, Tooltip } from 'antd'
import { FaChevronLeft, FaChevronDown, FaCog } from 'react-icons/fa'
import { FUNNEL, UPDATE_FUNNEL } from './GraphQL'
import UpsellCard from './UpsellCard'
import NewFunnelStepCard from './NewFunnelStepCard'
import BumpProductForm from './BumpProductForm'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DateRangeButton from 'components/DateRangeButton'
import moment from 'moment'

const FunnelPage = ({ match }) => {
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  })

  const { loading, error, data } = useQuery(FUNNEL, {
    variables: {
      id: match.params.funnelId,
    },
  })

  const [updateFunnel] = useMutation(UPDATE_FUNNEL, {
    refetchQueries: [
      {
        query: FUNNEL,
        variables: {
          id: match.params.funnelId,
        },
      },
    ],
    onCompleted: () => {
      message.success('Funnel Successfully Updated')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleDateRangeChange = dateRange => {
    setDateRange(dateRange)
  }

  const handleStatusChange = value => {
    updateFunnel({
      variables: {
        id: funnel.id,
        funnel: {
          enabled: value,
        },
      },
    })
  }

  if (loading) {
    return <div className="m-6 text-lg text-center text-gray-400">loading Funnel...</div>
  }

  if (error) {
    return (
      <div className="m-6 text-lg text-center text-red-400">
        whoops, there was an error that occurred, please refresh the page.
      </div>
    )
  }

  const { funnel } = data

  return (
    <>
      <div className="w-full">
        <div className="sticky top-0 z-50 w-full px-4 py-2 bg-white shadow-md">
          <div className="flex max-w-6xl">
            <div className="self-center flex-1">
              <Link
                to="/dashboard/funnels"
                className="flex text-base font-light text-gray-500 cursor-pointer hover:text-gray-600 hover:underline">
                <FaChevronLeft className="self-center flex-shrink mr-1 group-hover:text-gray-600" />
                <div className="self-center flex-shrink group-hover:text-gray-600">All Funnels</div>
              </Link>
            </div>

            <div className="flex-shrink ml-auto">
              <DateRangeButton dateRange={dateRange} onChange={handleDateRangeChange} />
            </div>
          </div>
        </div>

        <div className="m-4 mt-2">
          <div className="flex items-center max-w-6xl my-4">
            <div className="flex-shrink mr-4">
              <div className="text-2xl font-light text-gray-800">{data.funnel.name}</div>
            </div>{' '}
            <div className="flex-shrink">
              <Switch checked={funnel.enabled} onChange={value => handleStatusChange(value)} />
            </div>
            <div className="self-end flex-shrink ml-auto">
              <div className="h-full">
                <Link to={`/dashboard/funnels/${match.params.funnelId}/settings`}>
                  <div className="px-3 py-2 text-center text-gray-800 bg-gray-300 rounded cursor-pointer no-select hover:bg-gray-400">
                    <div className="flex items-center">
                      <FaCog className="text-lg" />
                      <div className="ml-2 text-sm">Edit Settings</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full max-w-6xl">
            <div className="w-full overflow-hidden border-2 border-gray-200 rounded shadow-xl">
              <div className="flex">
                <div className="flex-shrink w-20 h-20 bg-gray-800">
                  <div className="flex h-full">
                    <div className="self-center flex-1">
                      <img src="/images/shopify_logo.svg" alt="Shopify" className="w-1/2 m-auto" />
                    </div>
                  </div>
                </div>
                <div className="flex-shrink ml-4">
                  <div className="flex content-center h-full">
                    <div className="self-center flex-1">
                      <div className="text-lg font-medium text-gray-800">Shopify Cart</div>

                      <div className="text-sm font-light text-gray-600">
                        Carts that contain a product with tag:{' '}
                        <CopyToClipboard
                          text={funnel.tag}
                          onCopy={() => message.success('Tag copied to your clipboard!')}>
                          <Tooltip title="Click to copy">
                            <span className="p-2 overflow-hidden text-xs leading-none text-center text-gray-800 bg-gray-400 rounded-full cursor-pointer hover:bg-gray-300">
                              {funnel.tag}
                            </span>
                          </Tooltip>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full my-6 text-center">
              <FaChevronDown className="m-auto text-xl text-gray-500" />
            </div>

            <div className="w-full overflow-hidden border-2 border-gray-200 rounded shadow-xl">
              <div className="flex">
                <div className="flex-shrink w-20 bg-gray-800">
                  <div className="flex h-full">
                    <div className="self-center flex-1">
                      <img src="/images/better_cart_logo.svg" alt="Better Cart" className="w-3/5 m-auto" />
                    </div>
                  </div>
                </div>

                <div className="flex-grow ml-4">
                  <div className="flex content-center h-full">
                    <div className="self-center flex-1">
                      <div className="text-lg font-medium text-gray-800">Better Cart Checkout</div>
                      <div className="text-sm font-light text-gray-600">Customer completes Better Cart Checkout</div>
                    </div>
                  </div>
                </div>

                <div className="w-1/2">
                  <BumpProductForm funnel={funnel} primaryBrandColor={funnel.shop.primaryBrandColor} />
                </div>
              </div>
            </div>

            <div className="w-full my-6 text-center">
              <FaChevronDown className="m-auto text-xl text-gray-500" />
            </div>

            {funnel.funnelSteps.map((funnelStep, index) => (
              <UpsellCard
                funnel={funnel}
                funnelStep={funnelStep}
                index={index}
                isUpsell={true}
                key={funnelStep.id}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
              />
            ))}

            <NewFunnelStepCard funnel={funnel} index={funnel.funnelSteps.length} isUpsell={true} />

            <div className="w-full my-6 text-center">
              <FaChevronDown className="m-auto text-xl text-gray-500" />
            </div>

            <div className="w-full overflow-hidden border-2 border-gray-200 rounded shadow-xl">
              <div className="flex">
                <div className="flex-shrink w-20 h-20 bg-gray-800">
                  <div className="flex h-full">
                    <div className="self-center flex-1">
                      <img src="/images/thank_you.svg" alt="Thank You" className="w-2/3 m-auto" />
                    </div>
                  </div>
                </div>
                <div className="flex-shrink ml-4">
                  <div className="flex content-center h-full">
                    <div className="self-center flex-1">
                      <div className="text-lg font-medium text-gray-800">Better Cart Thank You</div>
                      <div className="text-sm font-light text-gray-600">Customer completes Better Cart Funnel</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(FunnelPage)
