import React, { useState, useMemo, useEffect } from 'react'
import { DayPickerRangeController } from 'react-dates'
import { FaCalendarAlt } from 'react-icons/fa'
import { Popover, Dropdown, Menu, Button } from 'antd'
import moment from 'moment'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'styles/react_dates_overrides.css'

const DatePickerContent = ({ dateRange, onChange, currentSelectionTitle, onKeyChange, onAccept }) => {
  const [focusedInput, setFocusedInput] = useState('startDate')

  const canAccept = useMemo(() => dateRange.startDate && dateRange.endDate, [dateRange])

  const handleRangeSelection = event => {
    onKeyChange(event.key)

    const startOfToday = moment().startOf('day')
    const endOfToday = moment().endOf('day')

    switch (event.key) {
      case 'today': {
        onChange({ startDate: startOfToday, endDate: endOfToday })
        break
      }
      case 'yesterday': {
        onChange({
          startDate: moment().subtract(1, 'days').startOf('day'),
          endDate: moment().subtract(1, 'days').endOf('day'),
        })
        break
      }
      case '7days': {
        onChange({ startDate: startOfToday.subtract(7, 'days'), endDate: endOfToday })
        break
      }
      case '30days': {
        onChange({ startDate: startOfToday.subtract(30, 'days'), endDate: endOfToday })
        break
      }
      case '60days': {
        onChange({ startDate: startOfToday.subtract(60, 'days'), endDate: endOfToday })
        break
      }
      case '90days': {
        onChange({ startDate: startOfToday.subtract(90, 'days'), endDate: endOfToday })
        break
      }
      case 'thisMonth': {
        onChange({ startDate: moment().startOf('month'), endDate: moment().endOf('month') })
        break
      }
      case 'lastMonth': {
        onChange({
          startDate: moment().subtract(1, 'months').startOf('month'),
          endDate: moment().subtract(1, 'months').endOf('month'),
        })
        break
      }
      case 'thisYear': {
        onChange({ startDate: moment().startOf('year'), endDate: moment().endOf('year') })
        break
      }
      case 'lastYear': {
        onChange({
          startDate: moment().subtract(1, 'years').startOf('year'),
          endDate: moment().subtract(1, 'years').endOf('year'),
        })
        break
      }
      case 'custom':
        break
      default: {
        onChange({ startDate: startOfToday, endDate: endOfToday })
        break
      }
    }
  }

  const ranges = (
    <Menu onClick={handleRangeSelection}>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="today">
        Today
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="yesterday">
        Yesterday
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="7days">
        Last 7 Days
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="30days">
        Last 30 Days
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="60days">
        Last 60 Days
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="90days">
        Last 90 Days
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="thisMonth">
        This Month
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="lastMonth">
        Last Month
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="thisYear">
        This Year
      </Menu.Item>
      <Menu.Item className="text-gray-800 hover:bg-gray-300" key="lastYear">
        Last Year
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div className="w-full mb-2">
        <Dropdown overlay={ranges} className="w-full">
          <Button className="text-base text-gray-800 border-gray-400">{currentSelectionTitle}</Button>
        </Dropdown>
      </div>
      <DayPickerRangeController
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onDatesChange={({ startDate, endDate }) => {
          onChange({
            startDate: startDate ? moment(startDate).startOf('day') : null,
            endDate: endDate ? moment(endDate).endOf('day') : null,
          })
          onKeyChange('custom')
        }}
        focusedInput={focusedInput}
        onFocusChange={input => setFocusedInput(!input ? 'startDate' : input)}
        enableOutsideDays={true}
        isOutsideRange={day => day > moment().endOf('day')}
        initialVisibleMonth={() => moment().subtract(1, 'month')}
        numberOfMonths={2}
        hideKeyboardShortcutsPanel={true}
        minimumNights={0}
      />
      <div className="flex items-center justify-end w-full mt-3">
        <div
          onClick={canAccept ? onAccept : null}
          className={`flex-shrink text-base no-select rounded text-center px-4 py-2 ${
            canAccept ? 'bg-gray-800 text-white cursor-pointer hover:bg-gray-700' : 'bg-gray-300'
          }`}>
          Apply
        </div>
      </div>
    </>
  )
}

const DateRangeButton = ({ dateRange, onChange }) => {
  const [showing, setShowing] = useState(false)
  const [localDateRange, setLocalDateRange] = useState(dateRange)
  const [selectedKey, setSelectedKey] = useState('today')

  const handleAccept = () => {
    setShowing(false)
    onChange(localDateRange)
  }

  const currentSelectionTitle = useMemo(() => {
    switch (selectedKey) {
      case 'today':
        return 'Today'
      case 'yesterday':
        return 'Yesterday'
      case '7days':
        return 'Last 7 Days'
      case '30days':
        return 'Last 30 Days'
      case '60days':
        return 'Last 60 Days'
      case '90days':
        return 'Last 90 Days'
      case 'thisMonth':
        return 'Month to Date'
      case 'lastMonth':
        return 'Last Month'
      case 'thisYear':
        return 'Year To Date'
      case 'lastYear':
        return 'Last Year'
      case 'custom': {
        return `${localDateRange.startDate ? localDateRange.startDate.format('MMM D') : '...'} - ${
          localDateRange.endDate ? localDateRange.endDate.format('MMM D') : '...'
        }`
      }
      default:
        return 'Unknown'
    }
  }, [selectedKey, localDateRange])

  useEffect(() => {
    if (!showing && (!localDateRange.startDate || !localDateRange.endDate)) {
      setLocalDateRange(dateRange)
    }
  }, [showing, localDateRange, dateRange])

  return (
    <Popover
      visible={showing}
      onVisibleChange={visible => setShowing(visible)}
      trigger="click"
      placement="bottomLeft"
      content={
        <DatePickerContent
          dateRange={localDateRange}
          onChange={setLocalDateRange}
          onKeyChange={setSelectedKey}
          currentSelectionTitle={currentSelectionTitle}
          onAccept={handleAccept}
        />
      }>
      <div className="px-3 py-2 text-center text-gray-800 bg-gray-300 rounded cursor-pointer no-select hover:bg-gray-400">
        <div className="flex items-center justify-center">
          <div>
            <FaCalendarAlt className="text-base" />
          </div>
          <div className="ml-2 text-sm text-semibold no-select">{currentSelectionTitle}</div>
        </div>
      </div>
    </Popover>
  )
}

export default DateRangeButton
