import React, { useContext, useMemo } from 'react'
import { OnboardingContext } from 'contexts/OnboardingContext'
import { withRouter } from 'react-router-dom'

const OnboardingNavigation = ({ history, nextTitle, nextCallback }) => {
  const { onboardingContext, onboardingDispatch } = useContext(OnboardingContext)

  const hasErrors = useMemo(() => {
    return onboardingContext.errors && onboardingContext.errors.length > 0 ? true : false
  }, [onboardingContext.errors])

  return (
    <>
      <div
        className={`text-lg text-center p-3 w-1/2 mx-auto select-none cursor-pointer rounded  ${
          hasErrors ? 'bg-red-300 text-white hover:bg-red-200' : 'bg-gray-800 text-white hover:bg-gray-700'
        }`}
        onClick={() => {
          onboardingDispatch({ type: 'NEXT' })
          if (nextCallback) {
            nextCallback()
          }
        }}>
        {nextTitle || onboardingContext.currentStep.nextTitle || 'Next'}
      </div>
      {hasErrors ? (
        <div className="text-red-400 text-center">
          {onboardingContext.errors.map((e, i) => (
            <div className="mt-2" key={i}>
              {e.message}
            </div>
          ))}
        </div>
      ) : null}
      <div className="text-center mt-4">
        <div
          className="inline-block text-lg text-gray-500 select-none cursor-pointer hover:underline"
          onClick={() => onboardingDispatch({ type: 'PREV' })}>
          Back
        </div>
      </div>
    </>
  )
}

export default withRouter(OnboardingNavigation)
