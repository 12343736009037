import React from 'react'
import FloatingLabelInput from 'components/FloatingLabelInput'

export const GoogleAutocompleteDescription = () => {
  return (
    <>
      Connect the Google API to enable Google Places autocomplete for customers entering their shipping and billing
      addresses during checkout. When generating an API key, make sure to have the following services enabled:{' '}
      <span className="font-semibold">Google Maps JavaScript API, Geocoding API, and Places API.</span>
    </>
  )
}

export const GoogleAutocompleteForm = ({ shopIntegration, setShopIntegration }) => {
  return (
    <>
      <FloatingLabelInput
        label="API Key"
        value={shopIntegration.apiData.apiKey}
        onChange={e => setShopIntegration({ ...shopIntegration, apiData: { apiKey: e.target.value } })}
      />
    </>
  )
}
