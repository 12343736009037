import gql from 'graphql-tag'

export const FUNNEL = gql`
  query funnel($id: ID!) {
    funnel(id: $id) {
      id
      name
      tag
      phoneOptional
      enabled
      checkoutTemplate
      bumpProductDescription
      bumpProductBgColor
      bumpProductTextColor
      bumpProductPriceColor
      bumpProductPriceBgColor
      bumpProductImage
      showSummary

      shopPaymentGateway {
        id
      }

      paypalShopPaymentGateway {
        id
      }

      bumpProduct {
        id
        title
        shopifyId

        productImages {
          id
          src
        }

        productVariants {
          id
          shopifyId
          default
          price
          compareAtPrice

          productImage {
            id
            src
          }
        }
      }

      funnelSteps {
        id
        offerType
        offerValue
        shippingCost
        position
        enabled
        product {
          id
          title
          handle
          productImages {
            id
            position
            src
            width
            height
          }
        }
        childFunnelStep {
          id
          offerType
          offerValue
          shippingCost
          position
          enabled
          product {
            id
            title
            handle
            productImages {
              id
              position
              src
              width
              height
            }
          }
        }
      }

      shop {
        id
        billingPlanType
        primaryBrandColor

        shopPaymentGateways {
          id
          nickname

          paymentGateway {
            id
            name
            logo
            slug
            gatewayType
          }
        }
      }
    }
  }
`

export const FUNNEL_STEP_STATS = gql`
  query funnelStepStats($funnelId: ID!, $id: ID!, $startDate: DateTime, $endDate: DateTime) {
    funnelStepStats(funnelId: $funnelId, id: $id, startDate: $startDate, endDate: $endDate) {
      id
      views
      conversions
      conversionRate
      revenue
    }
  }
`

export const UPDATE_FUNNEL = gql`
  mutation updateFunnel($id: ID!, $funnel: UpdateFunnelInput!) {
    updateFunnel(id: $id, funnel: $funnel) {
      id
      name
      tag
      phoneOptional
      checkoutTemplate
      bumpProductDescription
      bumpProductBgColor
      bumpProductTextColor
      bumpProductPriceColor
      bumpProductPriceBgColor
      bumpProductImage
      showSummary

      bumpProduct {
        id
        title
        shopifyId

        productImages {
          id
          src
        }

        productVariants {
          id
          shopifyId
          default
          price
          compareAtPrice

          productImage {
            id
            src
          }
        }
      }
    }
  }
`

export const CREATE_FUNNEL_STEP = gql`
  mutation createFunnelStep($funnelId: ID!, $shopifyProductId: String!, $parentFunnelStepId: ID) {
    createFunnelStep(
      funnelId: $funnelId
      shopifyProductId: $shopifyProductId
      parentFunnelStepId: $parentFunnelStepId
    ) {
      id
    }
  }
`

export const UPDATE_FUNNEL_STEP = gql`
  mutation updateFunnelStep($funnelId: ID!, $id: ID!, $funnelStep: UpdateFunnelStepInput!) {
    updateFunnelStep(funnelId: $funnelId, id: $id, funnelStep: $funnelStep) {
      id
    }
  }
`

export const MOVE_FUNNEL_STEP_UP = gql`
  mutation moveFunnelStepUp($funnelId: ID!, $id: ID!) {
    moveFunnelStepUp(funnelId: $funnelId, id: $id) {
      id
      position
    }
  }
`

export const MOVE_FUNNEL_STEP_DOWN = gql`
  mutation moveFunnelStepDown($funnelId: ID!, $id: ID!) {
    moveFunnelStepDown(funnelId: $funnelId, id: $id) {
      id
      position
    }
  }
`

export const DUPLICATE_FUNNEL_STEP = gql`
  mutation duplicateFunnelStep($funnelId: ID!, $id: ID!) {
    duplicateFunnelStep(funnelId: $funnelId, id: $id) {
      id
    }
  }
`

export const DESTROY_FUNNEL_STEP = gql`
  mutation destroyFunnelStep($funnelId: ID!, $id: ID!) {
    destroyFunnelStep(funnelId: $funnelId, id: $id) {
      id
      archived
    }
  }
`
