import React, { useState, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { FiPlus } from 'react-icons/fi'
import { Radio } from 'antd'
import CurrencyFormat from 'react-currency-format'
import NumberFormat from 'react-number-format'
import ShopifyProductPicker from 'components/ShopifyProductPicker'
import { FiImage } from 'react-icons/fi'

const SHOPIFY_PRODUCT = gql`
  query ShopifyProduct($id: String!) {
    shopifyProduct(id: $id) {
      id
      title
      handle

      variants {
        id
        price
      }

      images {
        id
        src
      }
    }
  }
`

const StepCard = ({ step, setStep }) => {
  const [showProductPicker, setShowProductPicker] = useState(false)

  const [loadProduct] = useLazyQuery(SHOPIFY_PRODUCT, {
    onCompleted: data => {
      setStep({
        ...step,
        cachedProduct: {
          id: data.shopifyProduct.id,
          title: data.shopifyProduct.title,
          price: data.shopifyProduct.variants[0].price,
          image: data.shopifyProduct.images[0],
        },
      })
    },
  })

  const offerPrice = useMemo(() => {
    if (!step.cachedProduct) return 0

    switch (step.offerType) {
      case 'PERCENTAGE': {
        return step.cachedProduct.price - (step.offerValue / 100) * (step.cachedProduct.price || 0)
      }
      case 'FIXED_AMOUNT': {
        console.log('offerValue', step.offerValue)
        return (step.cachedProduct.price || 0) - (step.offerValue || 0)
      }
      default: {
        return step.cachedProduct.price || 0
      }
    }
  }, [step.cachedProduct, step.offerType, step.offerValue])

  const handleProductSelected = id => {
    loadProduct({
      variables: { id },
    })
  }

  return (
    <>
      <div className="flex items-center">
        {step.cachedProduct ? (
          <div className="">
            <div
              style={{
                backgroundImage: `url(${
                  step.cachedProduct ? (step.cachedProduct.image ? step.cachedProduct.image.src : '') : ''
                })`,
              }}
              onClick={() => setShowProductPicker(true)}
              className="relative h-24 w-24 flex items-center justify-center flex-col border border-gray-400 rounded cursor-pointer select-none bg-cover bg-center group">
              {!step.cachedProduct.image ? <FiImage className="text-5xl text-gray-300" /> : null}
              <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-25 z-10 hidden group-hover:block" />
              <div className="absolute top-12 text-white text-center text-xs px-1 z-20 hidden group-hover:block">
                Change Product
              </div>
            </div>
          </div>
        ) : (
          <div
            onClick={() => setShowProductPicker(true)}
            className="flex items-center justify-center flex-col text-gray-500 border border-dashed border-gray-400 rounded cursor-pointer select-none px-3 py-5 hover:border-gray-500 bg-cover bg-center">
            <FiPlus className="text-2xl" />
            <div className="font-thin">Select Product</div>
          </div>
        )}

        <div className="flex flex-col items-center justify-center ml-4">
          <div className="">
            <Radio.Group
              size="large"
              className="w-full text-center select-none"
              buttonStyle="solid"
              value={step.offerType}
              onChange={e => setStep({ ...step, offerType: e.target.value })}>
              <Radio value="NONE" className="text-gray-800">
                None
              </Radio>
              <Radio value="PERCENTAGE" className="text-gray-800">
                % OFF
              </Radio>
              <Radio value="FIXED_AMOUNT" className="text-gray-800">
                $ OFF
              </Radio>
            </Radio.Group>
            <div className="mt-2">
              {step.offerType === 'NONE' ? (
                <FloatingLabelInput disabled={true} placeholder="Full Price" />
              ) : step.offerType === 'FIXED_AMOUNT' ? (
                <NumberFormat
                  label="Dollars Off"
                  customInput={FloatingLabelInput}
                  value={step.offerValue}
                  onValueChange={({ value }) => setStep({ ...step, offerValue: value })}
                  prefix="$"
                  thousandSeparator={true}
                />
              ) : (
                <NumberFormat
                  label="Percentage Off"
                  customInput={FloatingLabelInput}
                  value={step.offerValue}
                  onValueChange={({ value }) => setStep({ ...step, offerValue: value })}
                  suffix="%"
                  thousandSeparator={false}
                />
              )}
            </div>
          </div>
        </div>

        <div className="ml-8 self-start">
          {step.cachedProduct ? (
            <>
              <div className="text-lg font-semibold text-gray-800">{step.cachedProduct.title}</div>
              <div className="text-base font-light text-gray-500 line-through">
                <CurrencyFormat
                  value={step.cachedProduct.price}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              </div>

              <div className="text-lg font-light text-red-500">
                <CurrencyFormat
                  value={offerPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <ShopifyProductPicker open={showProductPicker} setOpen={setShowProductPicker} onSelect={handleProductSelected} />
    </>
  )
}

export default StepCard
