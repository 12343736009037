import React from 'react'
import FloatingLabelInput from 'components/FloatingLabelInput'

export const GoogleTagManagerDescription = () => {
  return (
    <>
      Adding your GTM Container ID will allow you to have a simple one stop solution for all your tracking needs. We
      send all the enhanced-ecommerce data layers throughout the checkout process.
    </>
  )
}

export const GoogleTagManagerForm = ({ shopIntegration, setShopIntegration }) => {
  return (
    <>
      <FloatingLabelInput
        label="Container ID"
        value={shopIntegration.apiData.containerId}
        onChange={e => setShopIntegration({ ...shopIntegration, apiData: { containerId: e.target.value } })}
      />
    </>
  )
}
