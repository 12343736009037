import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import './styles/tailwind.css'
import './styles/globals.css'

import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import FullStory from 'react-fullstory'

const bugsnagClient = bugsnag({
  apiKey: '5236600c59a427f7074e0d0ab2e5cc7a',
  appType: 'client',
  beforeSend: report => {
    new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === 'development') {
        report.ignore()
      }
      resolve()
    })
  },
})
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
  <ErrorBoundary>
    <App />
    {process.env.NODE_ENV === 'production' ? <FullStory org="RHH88" /> : null}
  </ErrorBoundary>,
  document.getElementById('root')
)

serviceWorker.unregister()
