import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_CUSTOM_SCRIPT, CUSTOM_SCRIPTS, DESTROY_CUSTOM_SCRIPT } from './GraphQL'
import FloatingLabelInput from 'components/FloatingLabelInput'
import CodeEditor from 'components/CodeEditor'
import { Select, Switch } from 'antd'

const { Option } = Select

const LocationName = ({ location }) => {
  switch (location) {
    case 'ALL':
      return 'All Pages'
    case 'CHECKOUT':
      return 'Checkout Page'
    case 'OFFER':
      return 'Offer Pages'
    case 'FIRST_OFFER':
      return 'First Offer Page'
    case 'THANK_YOU':
      return 'Thank You Pages'
    default:
      return null
  }
}

const ScriptCard = ({ script }) => {
  const [localScript, setLocalScript] = useState(script)
  const [formShowing, setFormShowing] = useState(false)

  const [updateCustomScript, { loading }] = useMutation(UPDATE_CUSTOM_SCRIPT, {
    refetchQueries: [
      {
        query: CUSTOM_SCRIPTS,
      },
    ],
    onError: () => {},
    onCompleted: () => {
      setFormShowing(false)
    },
  })

  const [destroyCustomScript] = useMutation(DESTROY_CUSTOM_SCRIPT, {
    refetchQueries: [
      {
        query: CUSTOM_SCRIPTS,
      },
    ],
    onError: () => {},
    onCompleted: () => {
      setFormShowing(false)
    },
  })

  const update = () => {
    if (loading) return false

    updateCustomScript({
      variables: {
        id: script.id,
        customScript: { ...localScript, id: undefined },
      },
    })
  }

  const remove = () => {
    destroyCustomScript({
      variables: { id: script.id },
    })
  }

  return (
    <div className="p-4 rounded shadow-xl bg-white border-2 border-gray-200">
      <div className="text-sm text-gray-800">
        <div className="flex items-center justify-between w-full">
          <div>
            <LocationName location={script.location} />
          </div>
          <div className="text-gray-500">{script.name}</div>
        </div>
      </div>
      {formShowing ? (
        <div className="mt-4">
          <div>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select Platform"
              value={script.location}
              onChange={v => setLocalScript({ ...localScript, location: v })}>
              <Option value="ALL">All Pages</Option>
              <Option value="CHECKOUT">Checkout Page</Option>
              <Option value="OFFER">Offer Pages</Option>
              <Option value="FIRST_OFFER">First Offer Page</Option>
              <Option value="THANK_YOU">Thank You Page</Option>
            </Select>
            <div className="mt-4">
              <FloatingLabelInput
                label="Name"
                value={localScript.name}
                onChange={e => setLocalScript({ ...localScript, name: e.target.value })}
              />
            </div>
            <div className="mt-4 flex items-center">
              <div className="mr-4">Load Asyncronously</div>
              <Switch
                checked={localScript.async}
                onChange={value => setLocalScript({ ...localScript, async: value })}
              />
            </div>
            <div className="mt-4">
              <CodeEditor code={localScript.body} setCode={code => setLocalScript({ ...localScript, body: code })} />
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div
              className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
              onClick={() => setFormShowing(false)}>
              Cancel
            </div>

            <div
              className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
              onClick={update}>
              {loading ? 'Loading' : 'Update'}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between mt-4">
          <div
            className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
            onClick={() => setFormShowing(true)}>
            Edit
          </div>
          <div
            className="ml-4 py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
            onClick={remove}>
            Remove
          </div>
        </div>
      )}
    </div>
  )
}

export default ScriptCard
