import React from 'react'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/snippets/html'
import 'ace-builds/src-noconflict/theme-tomorrow'

const CodeEditor = ({ code, setCode }) => {
  return (
    <AceEditor
      className="border border-gray-400 w-full rounded"
      width="100%"
      height="200px"
      mode="html"
      theme="tomorrow"
      value={code}
      onChange={value => setCode(value)}
      fontSize={12}
      highlightActiveLine={true}
      showGutter={true}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  )
}

export default CodeEditor
