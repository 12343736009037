import React, { useState } from 'react'
import { Select, Input } from 'antd'
import { useMutation } from '@apollo/client'
import { CREATE_TRACKING_PIXEL, TRACKING_PIXELS } from './GraphQL'

const { Option } = Select

const PlatformFields = ({ platform, pixel, setPixel }) => {
  switch (platform) {
    case 'FACEBOOK':
      return (
        <>
          <div className="mt-4">
            <Input
              placeholder="Pixel ID"
              value={pixel.data.trackingId}
              onChange={e => setPixel({ ...pixel, data: { ...pixel.data, trackingId: e.target.value } })}
            />
          </div>
        </>
      )
    case 'GOOGLE_ANALYTICS':
      return (
        <>
          <div className="mt-4">
            <Input
              placeholder="Tracking ID"
              value={pixel.data.trackingId}
              onChange={e => setPixel({ ...pixel, data: { ...pixel.data, trackingId: e.target.value } })}
            />
          </div>
        </>
      )
    case 'GOOGLE_ADS':
      return (
        <>
          <div className="mt-4">
            <Input
              placeholder="Account ID"
              value={pixel.data.trackingId}
              onChange={e => setPixel({ ...pixel, data: { ...pixel.data, trackingId: e.target.value } })}
            />
          </div>
          <div className="mt-4">
            <Input
              placeholder="Purchase Event Label"
              value={pixel.data.purchaseLabel}
              onChange={e => setPixel({ ...pixel, data: { ...pixel.data, purchaseLabel: e.target.value } })}
            />
          </div>
        </>
      )
    case 'SNAPCHAT':
      return (
        <>
          <div className="mt-4">
            <Input
              placeholder="Pixel ID"
              value={pixel.data.trackingId}
              onChange={e => setPixel({ ...pixel, data: { ...pixel.data, trackingId: e.target.value } })}
            />
          </div>
        </>
      )
    default:
      return null
  }
}

const NewTrackingPixelModal = ({ setShowNewPixelModal }) => {
  const [platform, setPlatform] = useState()
  const [pixel, setPixel] = useState({
    name: '',
    data: {
      trackingId: '',
      purchaseLabel: '',
    },
  })

  const [createTrackingPixel, { loading, error }] = useMutation(CREATE_TRACKING_PIXEL, {
    refetchQueries: [
      {
        query: TRACKING_PIXELS,
      },
    ],
    onCompleted: () => {
      if (!error) {
        setShowNewPixelModal(false)
        setPixel({
          name: '',
          data: {
            trackingId: '',
            purchaseLabel: '',
          },
        })
      }
    },
  })

  const handleCreate = () => {
    createTrackingPixel({
      variables: {
        trackingPixel: {
          platform,
          name: pixel.name,
          data: pixel.data,
        },
      },
    })
  }

  return (
    <>
      <div className="text-sm text-gray-600 pb-4 border-b border-gray-200">
        Select a supported tracking pixel that you wish to connect. Simply supply the basic informaion needed to
        integrate your pixel, and we will automatically trigger every event possible within the ecommerce domain
        throughout the checkout process.
      </div>
      <div className="mt-4">
        <div className="">
          <Select
            style={{ width: '100%' }}
            placeholder="Select Platform"
            value={platform}
            onChange={v => setPlatform(v)}>
            <Option value="FACEBOOK">Facebook Pixel</Option>
            <Option value="GOOGLE_ANALYTICS">Google Analytics</Option>
            <Option value="GOOGLE_ADS">Google Ads</Option>
            <Option value="SNAPCHAT">Snap Pixel</Option>
          </Select>
        </div>
        {platform ? (
          <div className="mt-4">
            <Input
              placeholder="Name (Optional)"
              value={pixel.name}
              onChange={e => setPixel({ ...pixel, name: e.target.value })}
            />
            <PlatformFields pixel={pixel} setPixel={setPixel} platform={platform} />

            {error ? (
              <div className="mt-4">
                {error.graphqlErrors.map(({ message }, index) => (
                  <div className="text-sm text-red-400">{message}</div>
                ))}
              </div>
            ) : null}

            {loading ? (
              <div className="mt-4 p-4 text-sm text-white text-center bg-gray-300 cursor-pointer rounded hover:bg-gray-400">
                Loading...
              </div>
            ) : (
              <div
                className="mt-4 p-4 text-sm text-white text-center bg-gray-800 cursor-pointer rounded hover:bg-gray-700"
                onClick={handleCreate}>
                Connect
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default NewTrackingPixelModal
