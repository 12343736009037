import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_TRACKING_PIXEL, TRACKING_PIXELS, DESTROY_TRACKING_PIXEL } from './GraphQL'
import { FiCheckCircle } from 'react-icons/fi'
import { FacebookDescription, FacebookForm } from './Facebook'
import { GoogleAnalyticsDescription, GoogleAnalyticsForm } from './GoogleAnalytics'
import { GoogleAdsDescription, GoogleAdsForm } from './GoogleAds'
import { SnapDescription, SnapForm } from './Snap'
import { ReactComponent as FacebookLogo } from 'images/facebook.svg'
import { ReactComponent as GoogleLogo } from 'images/google-analytics.svg'
import { ReactComponent as GoogleAdsLogo } from 'images/google-ads.svg'
import { ReactComponent as SnapLogo } from 'images/snap.svg'

const Icon = ({ platform }) => {
  switch (platform) {
    case 'FACEBOOK':
      return <FacebookLogo className="h-6" />
    case 'GOOGLE_ANALYTICS':
      return <GoogleLogo className="h-6" />
    case 'GOOGLE_ADS':
      return <GoogleAdsLogo className="h-6" />
    case 'SNAPCHAT':
      return <SnapLogo className="h-6" />
    default:
      return null
  }
}

const Description = ({ pixel }) => {
  switch (pixel.platform) {
    case 'FACEBOOK':
      return <FacebookDescription pixel={pixel} />
    case 'GOOGLE_ANALYTICS':
      return <GoogleAnalyticsDescription pixel={pixel} />
    case 'GOOGLE_ADS':
      return <GoogleAdsDescription pixel={pixel} />
    case 'SNAPCHAT':
      return <SnapDescription pixel={pixel} />
    default:
      return null
  }
}

const Form = ({ pixel, setPixel }) => {
  switch (pixel.platform) {
    case 'FACEBOOK':
      return <FacebookForm pixel={pixel} setPixel={setPixel} />
    case 'GOOGLE_ANALYTICS':
      return <GoogleAnalyticsForm pixel={pixel} setPixel={setPixel} />
    case 'GOOGLE_ADS':
      return <GoogleAdsForm pixel={pixel} setPixel={setPixel} />
    case 'SNAPCHAT':
      return <SnapForm pixel={pixel} setPixel={setPixel} />
    default:
      return null
  }
}

const PixelCard = ({ pixel }) => {
  const [localPixel, setLocalPixel] = useState(pixel)
  const [formShowing, setFormShowing] = useState(false)

  const [updateTrackingPixel, { loading }] = useMutation(UPDATE_TRACKING_PIXEL, {
    refetchQueries: [
      {
        query: TRACKING_PIXELS,
      },
    ],
    onError: () => {},
    onCompleted: () => {
      setFormShowing(false)
    },
  })

  const [destroyTrackingPixel] = useMutation(DESTROY_TRACKING_PIXEL, {
    refetchQueries: [
      {
        query: TRACKING_PIXELS,
      },
    ],
    onError: () => {},
    onCompleted: () => {
      setFormShowing(false)
    },
  })

  const update = () => {
    if (loading) return false

    updateTrackingPixel({
      variables: {
        id: pixel.id,
        trackingPixel: { ...localPixel, id: undefined },
      },
    })
  }

  const remove = () => {
    destroyTrackingPixel({
      variables: { id: pixel.id },
    })
  }

  return (
    <div className="p-4 rounded shadow-xl bg-white border-2 border-gray-200">
      <div className="flex items-center justify-between">
        <Icon platform={pixel.platform} />
        <div className="ml-6 text-green-400 font-semibold">
          <div className="flex items-center">
            <FiCheckCircle className="text-xl" />
            <div className="ml-2">Connected</div>
          </div>
        </div>
      </div>
      <div className="mt-4 text-sm text-gray-800">
        <Description pixel={pixel} />
      </div>
      {formShowing ? (
        <div className="mt-4">
          <div>
            <Form pixel={localPixel} setPixel={setLocalPixel} />
          </div>
          <div className="flex justify-between mt-4">
            <div
              className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
              onClick={() => setFormShowing(false)}>
              Cancel
            </div>

            <div
              className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
              onClick={update}>
              {loading ? 'Loading' : 'Update'}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between mt-4">
          <div
            className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
            onClick={() => setFormShowing(true)}>
            Edit
          </div>
          <div
            className="ml-4 py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
            onClick={remove}>
            Remove
          </div>
        </div>
      )}
    </div>
  )
}

export default PixelCard
