import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import ShopifyProductPicker from 'components/ShopifyProductPicker'
import { FaTags, FaTruck, FaEllipsisH } from 'react-icons/fa'
import { Modal, Radio, Input, Menu, Dropdown, message, Tooltip } from 'antd'
import CurrencyFormat from 'react-currency-format'
import RateFormat from 'components/RateFormat'
import NumberFormat from 'components/NumberFormat'
import OfferFormat from 'components/OfferFormat'
import { FUNNEL, FUNNEL_STEP_STATS, UPDATE_FUNNEL_STEP, DESTROY_FUNNEL_STEP, DUPLICATE_FUNNEL_STEP } from './GraphQL'
import LoadingSpinner from 'components/LoadingSpinner'
import { BsExclamationCircle } from 'react-icons/bs'

const FunnelStepCard = ({ index, funnel, funnelStep, isUpsell = true, startDate, endDate }) => {
  const [editOfferModalShowing, setEditOfferModalShowing] = useState(false)
  const [editOfferModalLoading, setEditOfferModalLoading] = useState(false)
  const [tempOffer, setTempOffer] = useState({ offerType: funnelStep.offerType, offerValue: funnelStep.offerValue })

  const [editShippingModalShowing, setEditShippingModalShowing] = useState(false)
  const [editShippingModalLoading, setEditShippingModalLoading] = useState(false)
  const [showShippingInput, setShowShippingInput] = useState(funnelStep.shippingCost > 0)
  const [tempShippingCost, setTempShippingCost] = useState(funnelStep.shippingCost)

  const [showProductPicker, setShowProductPicker] = useState(false)

  const { data, loading, error } = useQuery(FUNNEL_STEP_STATS, {
    fetchPolicy: 'network-only',
    variables: {
      funnelId: funnel.id,
      id: funnelStep.id,
      startDate,
      endDate,
    },
  })

  const [updateFunnelStep] = useMutation(UPDATE_FUNNEL_STEP, {
    refetchQueries: [
      {
        query: FUNNEL,
        variables: {
          id: funnel.id,
        },
      },
    ],
    onError: error => {
      message.error(error.message)
      throw error
    },
  })

  const [duplicateFunnelStep] = useMutation(DUPLICATE_FUNNEL_STEP, {
    refetchQueries: [
      {
        query: FUNNEL,
        variables: {
          id: funnel.id,
        },
      },
    ],
    onError: error => {
      message.error(error.message)
      throw error
    },
  })

  const [destroyFunnelStep] = useMutation(DESTROY_FUNNEL_STEP, {
    refetchQueries: [
      {
        query: FUNNEL,
        variables: {
          id: funnel.id,
        },
      },
    ],
    onCompleted: () => {
      message.success('Offer Successfully Removed')
    },
    onError: error => {
      message.error(error.message)
    },
  })

  const handleEditOffer = async () => {
    try {
      setEditOfferModalLoading(true)
      await updateFunnelStep({
        variables: {
          funnelId: funnel.id,
          id: funnelStep.id,
          funnelStep: {
            offerType: tempOffer.offerType,
            offerValue: parseFloat(tempOffer.offerValue),
          },
        },
      })
      setEditOfferModalShowing(false)
      message.success('Offer Updated')
    } finally {
      setEditOfferModalLoading(false)
    }
  }

  const handleEditShippingCost = async () => {
    try {
      setEditShippingModalLoading(true)
      await updateFunnelStep({
        variables: {
          funnelId: funnel.id,
          id: funnelStep.id,
          funnelStep: {
            shippingCost: parseFloat(tempShippingCost),
          },
        },
      })
      setEditShippingModalShowing(false)
      message.success('Shipping Updated')
    } finally {
      setEditShippingModalLoading(false)
    }
  }

  const handleEditProduct = async id => {
    await updateFunnelStep({
      variables: {
        funnelId: funnel.id,
        id: funnelStep.id,
        funnelStep: {
          shopifyProductId: id,
        },
      },
    })

    setShowProductPicker(false)
    message.success('Offer Product Updated')
  }

  const handleEnableUpdate = async enabled => {
    await updateFunnelStep({
      variables: {
        funnelId: funnel.id,
        id: funnelStep.id,
        funnelStep: {
          enabled,
        },
      },
    })

    if (enabled) {
      message.success('Offer Enabled')
    } else {
      message.warning('Offer Disabled')
    }
  }

  const handleClone = () => {
    duplicateFunnelStep({
      variables: {
        funnelId: funnel.id,
        id: funnelStep.id,
      },
    })
  }

  const handleDetroy = () => {
    destroyFunnelStep({
      variables: { funnelId: funnel.id, id: funnelStep.id },
    })
  }

  const optionsMenu = (
    <Menu>
      <Menu.Item className="hover:bg-white">
        <Link
          to={`/dashboard/funnels/${funnel.id}/step/${funnelStep.id}`}
          className="text-sm text-gray-800 hover:underline">
          Edit Offer Page
        </Link>
      </Menu.Item>
      <Menu.Item className="hover:bg-white">
        <div onClick={() => setEditOfferModalShowing(true)} className="text-sm text-gray-800 hover:underline">
          Edit Discount
        </div>
      </Menu.Item>
      <Menu.Item className="hover:bg-white">
        <div onClick={() => setEditShippingModalShowing(true)} className="text-sm text-gray-800 hover:underline">
          Edit Shipping
        </div>
      </Menu.Item>
      <Menu.Item className="hover:bg-white">
        <div onClick={() => setShowProductPicker(true)} className="text-sm text-gray-800 hover:underline">
          Edit Product
        </div>
      </Menu.Item>
      {funnelStep.enabled ? (
        <Menu.Item className="hover:bg-white">
          <div onClick={() => handleEnableUpdate(false)} className="text-sm text-yellow-400 hover:underline">
            Disable Offer
          </div>
        </Menu.Item>
      ) : (
        <Menu.Item className="hover:bg-white">
          <div onClick={() => handleEnableUpdate(true)} className="text-sm text-green-500 hover:underline">
            Enable Offer
          </div>
        </Menu.Item>
      )}
      {isUpsell ? (
        <Menu.Item className="hover:bg-white">
          <div onClick={handleClone} className="text-sm text-blue-600 hover:underline">
            Duplicate Offer
          </div>
        </Menu.Item>
      ) : null}
      <Menu.Item className="hover:bg-white">
        <div onClick={handleDetroy} className="text-sm text-red-700 hover:underline">
          Remove Offer
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div className="flex">
        <div className={`flex-shrink p-4 ${funnelStep.product.productImages[0] ? '' : 'bg-gray-200'}`}>
          <img
            src={funnelStep.product.productImages[0] ? funnelStep.product.productImages[0].src : '/images/backpack.svg'}
            alt="Product"
            className="object-cover h-24"
          />
        </div>
        <div className="flex-shrink m-4">
          <div className="flex flex-col items-stretch content-between h-full ">
            <div className="flex-1">
              <div className="text-base font-thin text-gray-600">
                {isUpsell ? 'Upsell Offer' : 'Downsell Offer'} #{index + 1}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-lg font-medium text-gray-800">{funnelStep.product.title}</div>
            </div>
            <div className="flex-1">
              <div className="flex h-full">
                <div className="self-end flex-grow">
                  <FaTags className="inline-block mr-1 text-xl text-green-400" />
                  <span className="inline-block text-xs font-light text-gray-600">
                    <OfferFormat offerType={funnelStep.offerType} offerValue={funnelStep.offerValue} />
                  </span>
                </div>
                <div className="self-end flex-grow ml-2">
                  <FaTruck className="inline-block mr-1 text-xl text-purple-400" />
                  <span className="inline-block text-xs font-light text-gray-600">
                    {funnelStep.shippingCost && funnelStep.shippingCost > 0 ? (
                      <CurrencyFormat
                        value={funnelStep.shippingCost}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={'$'}
                      />
                    ) : (
                      'FREE'
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-shrink m-6">
          <div className="flex h-full">
            <div className="self-center w-px h-full bg-gray-300" />
          </div>
        </div>

        <div className="flex-grow m-4">
          <div className="flex items-stretch content-between h-full text-center">
            <div className="self-center flex-1">
              <div className="mb-1 text-base font-light text-center text-gray-800">
                {error ? (
                  <Tooltip title="Error loading stats, refresh to try again">
                    <BsExclamationCircle className="mx-auto text-gray-600" />
                  </Tooltip>
                ) : loading ? (
                  <LoadingSpinner />
                ) : data && data.funnelStepStats ? (
                  <NumberFormat value={data.funnelStepStats.views} />
                ) : null}
              </div>
              <div className="mt-1 text-xs font-light text-gray-600">Views</div>
            </div>

            <div className="self-center flex-1">
              <div className="mb-1 text-base font-light text-gray-800">
                {error ? (
                  <Tooltip title="Error loading stats, refresh to try again">
                    <BsExclamationCircle className="mx-auto text-gray-600" />
                  </Tooltip>
                ) : loading ? (
                  <LoadingSpinner />
                ) : data && data.funnelStepStats ? (
                  <RateFormat value={data.funnelStepStats.conversionRate} />
                ) : null}
              </div>
              <div className="mt-1 text-xs font-light text-gray-600">Conversion</div>
            </div>

            <div className="self-center flex-1">
              <div className="mb-1 text-base font-light text-gray-800">
                {error ? (
                  <Tooltip title="Error loading stats, refresh to try again">
                    <BsExclamationCircle className="mx-auto text-gray-600" />
                  </Tooltip>
                ) : loading ? (
                  <LoadingSpinner />
                ) : data && data.funnelStepStats ? (
                  <CurrencyFormat
                    value={data.funnelStepStats.revenue}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                ) : null}
              </div>
              <div className="mt-1 text-xs font-light text-gray-600">Revenue</div>
            </div>

            <div className="self-center flex-1">
              <div className="mb-1 text-base font-light text-gray-800">
                {error ? (
                  <Tooltip title="Error loading stats, refresh to try again">
                    <BsExclamationCircle className="mx-auto text-gray-600" />
                  </Tooltip>
                ) : loading ? (
                  <LoadingSpinner />
                ) : data && data.funnelStepStats ? (
                  <CurrencyFormat
                    value={
                      data.funnelStepStats.revenue > 0 && data.funnelStepStats.views > 0
                        ? data.funnelStepStats.revenue / data.funnelStepStats.views
                        : 0
                    }
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                ) : null}
              </div>
              <div className="mt-1 text-xs font-light text-gray-600">$/Visit</div>
            </div>
          </div>
        </div>

        <div className="flex-shrink m-4 text-right">
          <Dropdown overlay={optionsMenu}>
            <div className="text-gray-400 cursor-pointer hover:text-gray-600">
              <FaEllipsisH className="text-base" />
            </div>
          </Dropdown>
        </div>
      </div>

      <Modal
        title={`Edit ${isUpsell ? 'Upsell' : 'Downsell'} Offer`}
        okText="Update"
        onOk={handleEditOffer}
        confirmLoading={editOfferModalLoading}
        onCancel={() => {
          setEditOfferModalShowing(false)
          setTempOffer({ offerType: funnelStep.offerType, offerValue: funnelStep.offerValue })
        }}
        visible={editOfferModalShowing}>
        <Radio.Group
          buttonStyle="solid"
          value={tempOffer.offerType}
          onChange={e => setTempOffer({ offerType: e.target.value, offerValue: null })}>
          <Radio.Button value="NONE">None</Radio.Button>
          <Radio.Button value="PERCENTAGE">Percentage</Radio.Button>
          <Radio.Button value="FIXED_AMOUNT">Fixed Amount</Radio.Button>
        </Radio.Group>
        {tempOffer.offerType !== 'NONE' ? (
          <Input
            placeholder={tempOffer.offerType === 'FIXED_AMOUNT' ? 'Dollars OFF' : 'Percent OFF'}
            value={tempOffer.offerValue}
            onChange={e => setTempOffer({ ...tempOffer, offerValue: e.target.value })}
            prefix={tempOffer.offerType === 'FIXED_AMOUNT' ? '$' : null}
            suffix={tempOffer.offerType === 'PERCENTAGE' ? '%' : null}
            className="mt-2"
          />
        ) : null}
      </Modal>

      <Modal
        title={`Edit ${isUpsell ? 'Upsell' : 'Downsell'} Shipping Cost`}
        okText="Update"
        onOk={handleEditShippingCost}
        confirmLoading={editShippingModalLoading}
        onCancel={() => {
          setEditShippingModalShowing(false)
          setTempShippingCost(funnelStep.shippingCost)
        }}
        visible={editShippingModalShowing}>
        <Radio.Group
          buttonStyle="solid"
          defaultValue={tempShippingCost <= 0 ? 'FREE' : 'CHARGE'}
          onChange={e => {
            if (e.target.value === 'FREE') {
              setTempShippingCost(0)
              setShowShippingInput(false)
            } else {
              if (tempShippingCost <= 0) {
                setTempShippingCost(null)
              }
              setShowShippingInput(true)
            }
          }}>
          <Radio.Button value="FREE">Free Shipping</Radio.Button>
          <Radio.Button value="CHARGE">Charge Shipping</Radio.Button>
        </Radio.Group>
        {showShippingInput ? (
          <Input
            placeholder="Shipping Cost"
            value={tempShippingCost}
            onChange={e => setTempShippingCost(e.target.value)}
            prefix="$"
            className="mt-2"
          />
        ) : null}
      </Modal>

      <ShopifyProductPicker open={showProductPicker} setOpen={setShowProductPicker} onSelect={handleEditProduct} />
    </>
  )
}

export default FunnelStepCard
