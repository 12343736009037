import React, { useRef } from 'react'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { useDrag, useDrop } from 'react-dnd'

export const Provider = ({ children }) => {
  return <DndProvider backend={Backend}>{children}</DndProvider>
}

export const OrderableItem = ({ itemType, index, data, item, moveItem, dropItem, children }) => {
  const ref = useRef(null)

  const [{ i, d }, drop] = useDrop({
    accept: itemType,
    collect: () => ({
      i: index,
      d: data,
    }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
    drop: () => (dropItem ? dropItem(i, d) : null),
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: itemType, index },
    collect: monitor => ({
      index,
      data,
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return React.createElement(
    itemType ? itemType : 'div',
    {
      ref: ref,
      style: { opacity },
    },
    children
  )
}
