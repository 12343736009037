import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import FunnelsPage from 'pages/dashboard/funnels/FunnelsPage/FunnelsPage'
import FunnelPage from 'pages/dashboard/funnels/FunnelPage/FunnelPage'
import FunnelStepPage from 'pages/dashboard/funnels/FunnelStepPage/FunnelStepPage'
import FunnelSettingsPage from 'pages/dashboard/funnels/FunnelSettingsPage/FunnelSettingsPage'

const DashboardRouter = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={FunnelsPage} />
      <Route path={`${match.url}/:funnelId/settings`} component={FunnelSettingsPage} />
      <Route path={`${match.url}/:funnelId/step/:funnelStepId`} component={FunnelStepPage} />
      <Route path={`${match.url}/:funnelId`} component={FunnelPage} />
    </Switch>
  )
}

export default withRouter(DashboardRouter)
