import gql from 'graphql-tag'

export const TRACKING_PIXELS = gql`
  query trackingPixels {
    trackingPixels {
      id
      name
      platform
      enabled
      data
    }
  }
`

export const CREATE_TRACKING_PIXEL = gql`
  mutation createTrackingPixel($trackingPixel: CreateTrackingPixelInput!) {
    createTrackingPixel(trackingPixel: $trackingPixel) {
      id
      name
      platform
      enabled
      data
    }
  }
`

export const UPDATE_TRACKING_PIXEL = gql`
  mutation updateTrackingPixel($id: ID!, $trackingPixel: UpdateTrackingPixelInput!) {
    updateTrackingPixel(id: $id, trackingPixel: $trackingPixel) {
      id
      name
      platform
      enabled
      data
    }
  }
`

export const DESTROY_TRACKING_PIXEL = gql`
  mutation destroyTrackingPixel($id: ID!) {
    destroyTrackingPixel(id: $id) {
      id
    }
  }
`
