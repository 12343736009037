import React, { useState, useMemo, useRef } from 'react'
import { Select, Input, Checkbox, Tag, Tooltip } from 'antd'
import FloatingLabelInput from 'components/FloatingLabelInput'
import ImageUploader from 'components/ImageUploader'
import { FaInfoCircle } from 'react-icons/fa'

const { Option } = Select
const { TextArea } = Input

const SmsMessageForm = ({ smsMessage, setSmsMessage }) => {
  const [cursorEnd, setCursorEnd] = useState(0)
  const bodyInput = useRef(null)

  const delayLabel = useMemo(() => {
    switch (smsMessage.delayUnit) {
      case 'MINUTE': {
        return 'Minutes'
      }
      case 'HOUR': {
        return 'Hours'
      }
      case 'DAY': {
        return 'Days'
      }
      default: {
        return 'Delay'
      }
    }
  }, [smsMessage.delayUnit])

  const messageLength = useMemo(() => {
    return smsMessage.body.length
  }, [smsMessage.body])

  const messageCount = useMemo(() => {
    return messageLength <= 160 ? 1 : 1 + Math.floor(messageLength / 161)
  }, [messageLength])

  const handleBodyChange = event => {
    setSmsMessage({ ...smsMessage, body: event.target.value })
    setCursorEnd(event.target.selectionEnd)
  }

  const handleAddToken = token => {
    const body = [smsMessage.body.slice(0, cursorEnd), `${token} `, smsMessage.body.slice(cursorEnd)].join('')
    setSmsMessage({ ...smsMessage, body })
    bodyInput.current.focus()
    setCursorEnd(body.length)
  }

  return (
    <>
      <div className="border-b border-gray-400 pb-6">
        <div className="text-lg font-bold text-gray-800">Delay</div>
        <div className="text-sm font-light text-gray-600">
          This is the delay that will be added to the message prior to this message. If this is the first message, this
          is the delay from the time of the automation trigger event.
        </div>
        <div className="flex items-center mt-2">
          <div className="w-1/2 pr-2">
            <FloatingLabelInput
              label={delayLabel}
              mask="99"
              maskChar=""
              value={smsMessage.delayValue}
              onChange={e => setSmsMessage({ ...smsMessage, delayValue: parseInt(e.target.value) })}
            />
          </div>
          <div className="w-1/2 pl-2">
            <Select
              style={{ width: '100%' }}
              size="large"
              defaultValue={smsMessage.delayUnit}
              onChange={value => setSmsMessage({ ...smsMessage, delayUnit: value })}>
              <Option value="MINUTE">Minute(s)</Option>
              <Option value="HOUR">Hour(s)</Option>
              <Option value="DAY">Day(s)</Option>
            </Select>
          </div>
        </div>
      </div>

      <div className="border-b border-gray-400 pb-6 mt-4">
        <div className="text-lg font-bold text-gray-800">Attach image or GIF</div>
        <div className="text-sm font-light text-gray-600">
          You can optionally include images with your messages (US Only). You can manually supply a static image or
          select AUTO to have the image to be selected from the checkout line items.
        </div>
        <div className="flex items-center mt-2">
          <Checkbox
            checked={smsMessage.image !== 'NONE'}
            onChange={e => setSmsMessage({ ...smsMessage, image: e.target.checked ? 'AUTO' : 'NONE' })}
          />
          <div className="ml-2 text-gray-800">
            Attach image or GIF <span className="font-bold">(U.S. recipients only)</span>
          </div>
        </div>
        {smsMessage.image !== 'NONE' ? (
          <div className="">
            <div className="flex items-center mt-2">
              <Checkbox
                checked={smsMessage.image === 'AUTO'}
                onChange={e => setSmsMessage({ ...smsMessage, image: e.target.checked ? 'AUTO' : 'CUSTOM' })}
              />
              <div className="ml-2 text-gray-800">Automatically attatch image based on cart line items.</div>
            </div>
            {smsMessage.image === 'CUSTOM' ? (
              <div className="mt-4">
                <ImageUploader
                  title="Message Image"
                  src={smsMessage.imageSrc}
                  onSrc={src => setSmsMessage({ ...smsMessage, imageSrc: src })}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className="border-b border-gray-400 pb-6 mt-4">
        <div className="text-lg font-bold text-gray-800">Text Message</div>
        <div className="text-sm font-light text-gray-600">
          Create the text message content your customers will receive when the automation is triggered.
        </div>
        <div className="mt-2">
          <div className="flex items-center">
            <div className="text-gray-800">Message Tokens:</div>
            <div className="text-gray-600 ml-1">
              <Tooltip title="Dynamically replace tokens to customize your message to each customer">
                <FaInfoCircle />
              </Tooltip>
            </div>
          </div>
          <div className="text-sm font-light text-gray-800 mt-2">
            <Tag onClick={() => handleAddToken('{SiteName}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{SiteName}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{LineItemCount}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{LineItemCount}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{CheckoutTotal}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{CheckoutTotal}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{FirstName}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{FirstName}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{LastName}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{LastName}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{Phone}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{Phone}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{Country}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{Country}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{Region}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{Region}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{City}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{City}'}
            </Tag>
            <Tag onClick={() => handleAddToken('{CheckoutUrl}')} className="m-1 cursor-pointer" color="#2d3748">
              {'{CheckoutUrl}'}
            </Tag>
          </div>
        </div>
        <div className="mt-2">
          <TextArea
            ref={bodyInput}
            value={smsMessage.body}
            onChange={handleBodyChange}
            onKeyUp={handleBodyChange}
            onClick={handleBodyChange}
            placeholder="Text Message Body"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
          <div className="flex items-center justify-end mt-1">
            <div className="text-gray-500">Characters: {messageLength}</div>
            <div className="text-gray-500 ml-2 border-l border-gray-500 pl-2">SMS Count: {messageCount}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmsMessageForm
