import gql from 'graphql-tag'

export const CUSTOM_SCRIPTS = gql`
  query customScripts {
    customScripts {
      id
      name
      enabled
      body
      location
      async
    }
  }
`

export const CREATE_CUSTOM_SCRIPT = gql`
  mutation createCustomScript($customScript: CreateCustomScriptInput!) {
    createCustomScript(customScript: $customScript) {
      id
      name
      enabled
      body
      location
      async
    }
  }
`
