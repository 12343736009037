import React from 'react'
import FloatingLabelInput from 'components/FloatingLabelInput'

export const KlaviyoDescription = () => {
  return (
    <>
      Connect Klaviyo in order to capture important events and respond to these events with automated flows like
      abandoned carts. Better Cart will add leads automatically to your lists as soon as enough information is gathered
      during checkout and populate your lists at Klaviyo automatically. Make sure to enter a{' '}
      <span className="font-semibold">Private API Key</span>.
    </>
  )
}

export const KlaviyoForm = ({ shopIntegration, setShopIntegration }) => {
  return (
    <>
      <FloatingLabelInput
        label="API Key"
        value={shopIntegration.apiData.apiKey}
        onChange={e => setShopIntegration({ ...shopIntegration, apiData: { apiKey: e.target.value } })}
      />
    </>
  )
}
