import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { UPDATE_FUNNEL } from './GraphQL'
import { useMutation } from '@apollo/client'
import ShopifyProductPicker from 'components/ShopifyProductPicker'
import { FiPlus } from 'react-icons/fi'
import { FaTrash, FaEdit } from 'react-icons/fa'
import { Input, Modal, message, Icon } from 'antd'
import InputColorPicker from 'components/InputColorPicker'
import ShopAssetUploaderDragable from 'components/ShopAssetUploaderDragable'

const Uploader = ({ title, src, loading, handleRemove }) => {
  if (src) {
    return (
      <div className="relative bg-gray-300 group">
        <div className="absolute top-0 bottom-0 left-0 right-0 flex-wrap items-center justify-center hidden group-hover:flex">
          <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-black opacity-50" />
          <div className="relative z-20 w-full text-white hover:underline">Change Image</div>
          {handleRemove ? (
            <div
              onClick={e => {
                handleRemove(e)
                e.stopPropagation()
              }}
              className="relative z-50 w-full text-white hover:underline">
              Remove Image
            </div>
          ) : null}
        </div>
        <div className="w-full h-24 bg-center bg-no-repeat bg-contain" style={{ backgroundImage: `url(${src})` }} />
      </div>
    )
  } else {
    return (
      <div className="p-4 text-center">
        {loading ? (
          <Icon className="text-4xl text-pink-500" type="loading" />
        ) : (
          <Icon className="text-4xl text-pink-500" type="inbox" />
        )}
        <div className="mt-1 text-sm text-gray-800">{title}</div>
        <div className="mt-1 text-xs text-gray-500">Click or drag file to this area to upload</div>
      </div>
    )
  }
}

const BumpProductForm = ({ funnel, primaryBrandColor }) => {
  const [showPicker, setShowPicker] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [description, setDescription] = useState(funnel.bumpProductDescription)
  const [customizations, setCustomizations] = useState({
    bumpProductBgColor: funnel.bumpProductBgColor ? funnel.bumpProductBgColor : primaryBrandColor,
    bumpProductTextColor: funnel.bumpProductTextColor ? funnel.bumpProductTextColor : '#FFFFFF',
    bumpProductPriceColor: funnel.bumpProductPriceColor ? funnel.bumpProductPriceColor : '#FFFFFF',
    bumpProductPriceBgColor: funnel.bumpProductPriceBgColor ? funnel.bumpProductPriceBgColor : '#68d391',
    bumpProductImage: funnel.bumpProductImage ? funnel.bumpProductImage : null,
  })

  const [updateFunnel, { loading }] = useMutation(UPDATE_FUNNEL, {
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleProductSelect = async id => {
    await updateFunnel({
      variables: {
        id: funnel.id,
        funnel: {
          bumpProductShopifyId: id,
        },
      },
    })
    setShowPicker(false)
  }

  const handleRemove = async () => {
    await updateFunnel({
      variables: {
        id: funnel.id,
        funnel: {
          bumpProductShopifyId: null,
        },
      },
    })
    setShowPicker(false)
  }

  const handleUpdateFunnel = async () => {
    await updateFunnel({
      variables: {
        id: funnel.id,
        funnel: {
          bumpProductDescription: description,
          ...customizations,
        },
      },
    })
    setShowModal(false)
  }

  if (funnel.shop.billingPlanType !== 'PRO') {
    return (
      <div className="p-2 m-2 border border-pink-300 rounded">
        <div className="flex content-center">
          <div className="flex items-center justify-center w-1/4 p-4">
            <img src="/images/checkboxes.svg" alt="checkboxes" />
          </div>
          <div className="w-3/4 ml-4">
            <div className="text-lg font-semibold text-gray-800">Checkout Bump Products</div>
            <div className="text-sm font-light text-gray-800">
              Allow customers to add products to their order by simply checking a box!
            </div>

            <div className="mt-2">
              <Link to="/dashboard/billing">
                <div className="w-auto p-1 mt-1 font-semibold text-center text-gray-800 bg-gray-200 border border-pink-300 rounded cursor-pointer hover:bg-gray-300">
                  Upgrade to PRO
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center h-full">
        <div className="flex-shrink w-20 h-20 bg-gray-800">
          <div className="flex h-full">
            <div className="self-center flex-1 w-full h-full bg-gray-800">
              {funnel.bumpProduct ? (
                <>
                  {funnel.bumpProductImage ? (
                    <img src={funnel.bumpProductImage} alt="Better Cart" className="w-full m-auto" />
                  ) : (
                    <div className="flex flex-col items-center justify-center w-full h-full text-center">
                      <div className="text-xl font-bold text-white">NO</div>
                      <div className="text-xl font-bold text-white">IMAGE</div>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="flex items-center justify-center w-full h-full cursor-pointer"
                  onClick={() => setShowPicker(true)}>
                  <FiPlus className="text-5xl text-white" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="self-center flex-1 ml-4">
          {funnel.bumpProduct ? (
            <div className="flex items-start justify-between">
              <div className="mt-2">
                <div className="text-lg font-medium text-gray-800">Checkout Product Bump</div>
                <div className="flex items-center">
                  <div className="text-gray-800">{funnel.bumpProduct.title}</div>
                  <div
                    className="px-2 py-1 ml-2 text-xs text-center text-gray-800 bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
                    onClick={() => setShowPicker(true)}>
                    Change
                  </div>
                </div>
                <div className="my-2 text-xs">{funnel.bumpProductDescription}</div>
              </div>
              <div className="flex items-center mt-2 mr-2">
                <div
                  className="text-2xl text-gray-400 cursor-pointer hover:text-gray-500"
                  onClick={() => setShowModal(true)}>
                  <FaEdit />
                </div>

                <div className="ml-2 text-2xl text-gray-400 cursor-pointer hover:text-gray-500" onClick={handleRemove}>
                  <FaTrash />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="text-lg font-medium text-gray-800">Checkout Product Bump</div>
              <div className="text-sm font-light text-gray-600">Add a product bump to your checkout page.</div>
            </>
          )}
        </div>
      </div>
      <ShopifyProductPicker open={showPicker} setOpen={setShowPicker} onSelect={handleProductSelect} />

      <Modal
        title="Bump Product Settings"
        okText="Save"
        loading={loading}
        visible={showModal}
        onOk={handleUpdateFunnel}
        onCancel={() => setShowModal(false)}>
        <>
          <div className="text-sm font-semibold text-gray-800">Bump Description</div>
          <div className="mt-1">
            <Input.TextArea
              placeholder="Product Bump Description"
              value={description || ''}
              onChange={e => setDescription(e.target.value)}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </div>

          <div className="flex items-center mt-3">
            <div className="w-1/2 pr-1">
              <InputColorPicker
                label="Bump Bar Color"
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                  '#000000',
                  '#FFFFFF',
                ]}
                hex={customizations.bumpProductBgColor}
                onChange={color => setCustomizations({ ...customizations, bumpProductBgColor: color.hex })}
              />
            </div>

            <div className="w-1/2 pl-1">
              <InputColorPicker
                label="Bump Text Color"
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                  '#000000',
                  '#FFFFFF',
                ]}
                hex={customizations.bumpProductTextColor}
                onChange={color => setCustomizations({ ...customizations, bumpProductTextColor: color.hex })}
              />
            </div>
          </div>

          <div className="flex items-center mt-3">
            <div className="w-1/2 pr-1">
              <InputColorPicker
                label="Price Background Color"
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                  '#000000',
                  '#FFFFFF',
                ]}
                hex={customizations.bumpProductPriceBgColor}
                onChange={color => setCustomizations({ ...customizations, bumpProductPriceBgColor: color.hex })}
              />
            </div>

            <div className="w-1/2 pl-1">
              <InputColorPicker
                label="Price Color"
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                  '#000000',
                  '#FFFFFF',
                ]}
                hex={customizations.bumpProductPriceColor}
                onChange={color => setCustomizations({ ...customizations, bumpProductPriceColor: color.hex })}
              />
            </div>
          </div>

          <div className="mt-3">
            <ShopAssetUploaderDragable
              onPublicUrl={src => setCustomizations({ ...customizations, bumpProductImage: src })}>
              <Uploader
                title="Bump Product Image"
                src={customizations.bumpProductImage}
                handleRemove={e => setCustomizations({ ...customizations, bumpProductImage: null })}
              />
            </ShopAssetUploaderDragable>
          </div>
        </>
      </Modal>
    </>
  )
}

export default BumpProductForm
