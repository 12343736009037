import React, { useMemo } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import CurrencyFormat from 'react-currency-format'
import moment from 'moment'
import LineChart from 'components/charts/LineChart'

const AVERAGE_ORDER_VALUE = gql`
  query averageOrderValue($startDate: DateTime!, $endDate: DateTime!) {
    averageOrderValue(startDate: $startDate, endDate: $endDate)
  }
`

const AverageOrderValue = ({ startDate, endDate }) => {
  const { data, loading } = useQuery(AVERAGE_ORDER_VALUE, {
    fetchPolicy: 'network-only',
    variables: { startDate, endDate },
  })

  const averageOrderValue = useMemo(() => {
    if (!data || !data.averageOrderValue) return 0

    const arr = data.averageOrderValue.averageOrderValue.truncs.filter(t => t.aov > 0)
    const aov = (arr.reduce((sum, t) => sum + t.aov, 0) / arr.length).toFixed(2)
    return aov > 0 ? aov : 0.0
  }, [data])

  const chartData = useMemo(() => {
    if (data && data.averageOrderValue) {
      return [data.averageOrderValue.averageOrderValue.truncs.map(t => ({ x: moment(t.trunc).unix(), y: t.aov }))]
    } else {
      return [[]]
    }
  }, [data])

  return (
    <div className="p-3 bg-white rounded shadow">
      <div className="text-base font-normal text-gray-800 uppercase">Average Order Value</div>
      {loading ? (
        <div className="">Loading...</div>
      ) : (
        <div>
          <div className="mt-1 text-2xl font-bold text-gray-800">
            <CurrencyFormat
              value={averageOrderValue}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </div>
          <div
            className="mt-2"
            style={{
              width: '100%',
              height: '350px',
            }}>
            {data ? (
              <LineChart data={chartData} yValueType="money" trunc={data.averageOrderValue.averageOrderValue.trunc} />
            ) : (
              <div className="text-sm text-gray-500">Loading Data...</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AverageOrderValue
