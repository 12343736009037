import React from 'react'
import { Steps } from 'antd'

const ProgressBar = ({ steps, current }) => {
  return (
    <Steps size="small" progressDot current={current}>
      {steps.map(step => (
        <Steps.Step title={step.title} key={step.position} />
      ))}
    </Steps>
  )
}

export default ProgressBar
