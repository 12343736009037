import gql from 'graphql-tag'

export const CREATE_SHOP_PAYMENT_GATEWAY_FROM_OAUTH = gql`
  mutation createShopPaymentGatewayFromOAuth($slug: String!, $data: JSON!) {
    createShopPaymentGatewayFromOAuth(slug: $slug, data: $data) {
      id

      shop {
        id
        shopifyHostname
      }
    }
  }
`
