import React from 'react'
import { useQuery } from '@apollo/client'
import { TRACKING_PIXELS } from './cards/GraphQL'
import PixelCard from './cards/PixelCard'

const ShopPixels = () => {
  const { data, loading } = useQuery(TRACKING_PIXELS)

  return (
    <div className="flex items-start justify-between flex-wrap">
      {loading ? (
        <div className="text-gray-400">Loading...</div>
      ) : (
        data.trackingPixels.map(pixel => (
          <div className="w-1/2 pr-2 mb-4" key={pixel.id}>
            <PixelCard pixel={pixel} />
          </div>
        ))
      )}
    </div>
  )
}

export default ShopPixels
