import React from 'react'

import 'styles/iphone.css'

const IPhoneXWindow = ({ children }) => {
  return (
    <div className="phone">
      <div className="toggleButton Button"></div>
      <div className="volumeButtonUp Button"></div>
      <div className="volumeButtonDown Button"></div>
      <div className="powerButton Button"></div>

      <div className="screen">
        <div className="strip" />
        {children}
      </div>
    </div>
  )
}

export default IPhoneXWindow
