import React, { useEffect } from 'react'
import { withRouter, Redirect as RouterRedirect } from 'react-router-dom'
import queryString from 'query-string'
import uuidv1 from 'uuid/v1'
import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { setCurrentShop } from 'utils/Auth'

const CREATE_OAUTH_SESSION = gql`
  mutation createOAuthSession($hostname: String!, $nonce: String!) {
    createOAuthSession(hostname: $hostname, nonce: $nonce) {
      id
      hostname
      nonce
    }
  }
`
const nonce = uuidv1()

const OAuthPage = ({ location }) => {
  const { shop } = queryString.parse(location.search)
  const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY
  const redirectUri = `${window.location.protocol}//${window.location.hostname}/callback`
  const permissionUrl = `/oauth/authorize?client_id=${apiKey}&scope=${process.env.REACT_APP_SHOPIFY_SCOPES}&redirect_uri=${redirectUri}&state=${nonce}`

  const [createOAuthSession] = useMutation(CREATE_OAUTH_SESSION, {
    update() {
      if (window.top === window.self) {
        window.location.assign(`https://${shop}/admin${permissionUrl}`)
      } else {
        const app = createApp({
          apiKey: apiKey,
          shopOrigin: shop,
        })
        Redirect.create(app).dispatch(Redirect.Action.ADMIN_PATH, permissionUrl)
      }
    },
  })

  useEffect(() => {
    if (shop) {
      setCurrentShop(shop)
      //	if (getAuthToken(shop)) {
      //		if (window.top === window.self) {
      //			window.location.assign(`https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_SLUG}/dashboard`)
      //		} else {
      //			const app = createApp({
      //				apiKey: apiKey,
      //				shopOrigin: shop,
      //			})
      //			Redirect.create(app).dispatch(Redirect.Action.APP, '/dashboard')
      //		}
      //	} else {
      createOAuthSession({
        variables: { hostname: shop, nonce },
      })
      // }
    }
  }, [createOAuthSession, shop, apiKey])

  if (!shop) {
    return <RouterRedirect to="/shop-hostname" />
  } else {
    return <div>Connecting...</div>
  }
}

export default withRouter(OAuthPage)
