import gql from 'graphql-tag'

export const SHOP_PAYMENT_GATEWAYS = gql`
  query shopPaymentGateways {
    shopPaymentGateways {
      id
      nickname
      manuallyCapture

      paymentGateway {
        id
        name
        gatewayType
      }
    }
  }
`

export const UPDATE_SHOP_PAYMENT_GATEWAY = gql`
  mutation updateShopPaymentGateway($id: ID!, $nickname: String!, $manuallyCapture: Boolean) {
    updateShopPaymentGateway(id: $id, nickname: $nickname, manuallyCapture: $manuallyCapture) {
      id
      nickname
      manuallyCapture

      paymentGateway {
        id
        name
        gatewayType
      }
    }
  }
`

export const DESTROY_SHOP_PAYMENT_GATEWAY = gql`
  mutation destroyShopPaymentGateway($id: ID!) {
    destroyShopPaymentGateway(id: $id) {
      id
    }
  }
`
