import React, { useCallback, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useMutation } from '@apollo/client'
import { CREATE_SHOP_PAYMENT_GATEWAY_FROM_OAUTH } from './GraphQL'

const PaymentGatewayOAuthCallback = ({ match, location }) => {
  const slug = match.params.slug
  const data = queryString.parse(location.search)

  const [createShopPaymentGateway, { loading, error }] = useMutation(CREATE_SHOP_PAYMENT_GATEWAY_FROM_OAUTH, {
    update(cache, mutationResult) {
      const shopPaymentGatewayId = mutationResult.data.createShopPaymentGatewayFromOAuth.id
      const shop = mutationResult.data.createShopPaymentGatewayFromOAuth.shop

      if (window.localStorage.getItem('onboarding-gateway-oauth-slug')) {
        window.localStorage.removeItem('onboarding-gateway-oauth-slug')
        window.location.assign(
          process.env.NODE_ENV === 'production'
            ? `https://${shop.shopifyHostname}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_SLUG}/dashboard/onboarding/gateway?callbackShopGatewayId=${shopPaymentGatewayId}`
            : `http://localhost:4000/dashboard/onboarding/gateway?callbackShopGatewayId=${shopPaymentGatewayId}`
        )
      } else {
        window.location.assign(
          process.env.NODE_ENV === 'production'
            ? `https://${shop.shopifyHostname}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_SLUG}/dashboard/settings`
            : 'http://localhost:4000/dashboard/settings'
        )
      }
    },
  })

  const create = useCallback(() => {
    createShopPaymentGateway({
      variables: {
        slug,
        data,
      },
    })
  }, [slug, data, createShopPaymentGateway])

  useEffect(create, [])

  if (error) {
    if (error.graphQLErrors) {
      return (
        <div>
          Whoops!{' '}
          {error.graphQLErrors.map(({ message }, index) => (
            <div className="text-red-400" key={index}>
              {message}
            </div>
          ))}
        </div>
      )
    } else {
      return <div>Whoops! There was an unexpected error! Please close this tab and try again.</div>
    }
  }

  if (loading) {
    return <div>Authenticating...</div>
  }

  return <div>Authenticating...</div>
}

export default withRouter(PaymentGatewayOAuthCallback)
