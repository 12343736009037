import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { FUNNELS, SMS_AUTOMATION, TWILIO_NUMBERS, SMS_SHORT_LINK_DOMAINS, UPDATE_SMS_AUTOMATION } from './GraphQL'
import { Switch, Select, message } from 'antd'
import { FaChevronLeft, FaChevronDown, FaRobot, FaCog } from 'react-icons/fa'
import { IoIosFunnel } from 'react-icons/io'
import MessageCard from './MessageCard'
import NewMessageCard from './NewMessageCard'

const { Option } = Select

const SmsAutomationPage = ({ match }) => {
  const { smsAutomationId } = match.params

  const { data, loading } = useQuery(SMS_AUTOMATION, {
    variables: { id: smsAutomationId },
  })

  const { data: funnelsData, loading: funnelsLoading } = useQuery(FUNNELS)
  const { data: twilioNumbersData, loading: twilioNumbersLoading } = useQuery(TWILIO_NUMBERS)
  const { data: smsShortLinkDomainsData, loading: smsShortLinkDomainsLoading } = useQuery(SMS_SHORT_LINK_DOMAINS)

  const [updateSmsAutomation] = useMutation(UPDATE_SMS_AUTOMATION, {
    refetchQueries: [
      {
        query: SMS_AUTOMATION,
        variables: { id: smsAutomationId },
      },
    ],
    onCompleted: () => {},
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleStatusChange = value => {
    updateSmsAutomation({
      variables: { id: smsAutomationId, smsAutomation: { enabled: value } },
    })
  }

  const handleTriggerChange = value => {}

  const handleFunnelsChange = value => {
    updateSmsAutomation({
      variables: { id: smsAutomationId, funnelIds: value },
    })
  }

  const handleTwilioNumberChange = value => {
    updateSmsAutomation({
      variables: { id: smsAutomationId, smsAutomation: { twilioNumberId: value } },
    })
  }

  const handleSmsShortLinkDomainChange = value => {
    updateSmsAutomation({
      variables: { id: smsAutomationId, smsAutomation: { smsShortLinkDomainId: value } },
    })
  }

  if (loading) return <>Loading...</>

  const { smsAutomation } = data

  return (
    <>
      <div className="sticky top-0 w-full p-4 bg-white shadow-md z-50">
        <div className="flex">
          <div className="flex-1 self-center">
            <Link
              to="/dashboard/sms-automations"
              className="flex text-gray-500 text-base font-light cursor-pointer hover:text-gray-600 hover:underline">
              <FaChevronLeft className="flex-shrink self-center mr-1 group-hover:text-gray-600" />
              <div className="flex-shrink self-center group-hover:text-gray-600">All Automations</div>
            </Link>
          </div>
        </div>
      </div>

      <div className="m-4 mt-2">
        <div className="flex items-center mb-4">
          <div className="flex-shrink mr-4">
            <div className="text-2xl font-light text-gray-800">{smsAutomation.name}</div>
          </div>
          <div className="flex-shrink">
            <Switch checked={smsAutomation.enabled} onChange={value => handleStatusChange(value)} />
          </div>
        </div>

        <div className="w-full max-w-6xl">
          <div className="w-full rounded shadow-xl border-2 border-gray-200 overflow-hidden bg-white">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="flex-1 self-center">
                    <FaRobot className="text-white mx-auto text-4xl" />
                  </div>
                </div>
              </div>
              <div className="w-1/2 pl-4">
                <div className="flex content-center h-full">
                  <div className="flex-1 self-center my-2 max-w-md">
                    <div className="text-lg text-gray-800 font-medium">Automation Trigger</div>
                    <div className="text-sm text-gray-600 font-light">
                      When a customer has fired the following event, the automation will begin and the customer will
                      begin to receive the scheduled messages.
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-6 py-2">
                <div className="text-lg text-gray-800 font-medium">Trigger</div>
                <Select
                  className="mt-1 mb-2"
                  size="large"
                  style={{ width: '100%' }}
                  value={smsAutomation.trigger}
                  onChange={handleTriggerChange}>
                  <Option value="ABANDONED_CART">Abandoned Cart</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full text-center mt-6 mb-4">
          <FaChevronDown className="m-auto text-xl text-gray-500" />
        </div>

        <div className="w-full max-w-6xl">
          <div className="w-full rounded shadow-xl border-2 border-gray-200 overflow-hidden bg-white">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="flex-1 self-center">
                    <IoIosFunnel className="text-white mx-auto text-4xl" />
                  </div>
                </div>
              </div>
              <div className="w-1/2 pl-4">
                <div className="flex content-center h-full">
                  <div className="flex-1 self-center my-2 max-w-md">
                    <div className="text-lg text-gray-800 font-medium">Checkout Funnels</div>
                    <div className="text-sm text-gray-600 font-light">
                      Checkouts that belongs to these funnels will receive the following messages.
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-6 py-2">
                <div className="text-lg text-gray-800 font-medium">Funnels</div>
                <Select
                  className="mt-1 mb-2"
                  size="large"
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Select Funnels"
                  defaultValue={smsAutomation.funnels.map(f => f.id)}
                  disabled={funnelsLoading}
                  onChange={handleFunnelsChange}>
                  {funnelsLoading
                    ? null
                    : funnelsData.funnels.map(funnel => (
                        <Option key={funnel.id} value={funnel.id}>
                          {funnel.name} - {funnel.tag}
                        </Option>
                      ))}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full text-center mt-6 mb-4">
          <FaChevronDown className="m-auto text-xl text-gray-500" />
        </div>

        <div className="w-full max-w-6xl">
          <div className="w-full rounded shadow-xl border-2 border-gray-200 overflow-hidden bg-white">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="flex-1 self-center">
                    <FaCog className="text-white mx-auto text-4xl" />
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-4 py-2">
                <div className="flex content-center h-full">
                  <div className="flex-1 self-center my-2 max-w-md">
                    <div className="text-lg text-gray-800 font-medium">Sending Number</div>
                    <div className="text-sm text-gray-600 font-light">
                      This is the number customers will receive SMS from.
                    </div>
                    <Select
                      className="mt-2"
                      size="large"
                      style={{ width: '100%' }}
                      value={smsAutomation.twilioNumber.id}
                      onChange={handleTwilioNumberChange}>
                      {!twilioNumbersLoading
                        ? twilioNumbersData.shop.twilioNumbers.map(number => (
                            <Option key={number.id} value={number.id}>
                              {number.phoneNumber}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-4 py-2">
                <div className="flex content-center h-full">
                  <div className="flex-1 self-center my-2 max-w-md">
                    <div className="text-lg text-gray-800 font-medium">Short Link Domain</div>
                    <div className="text-sm text-gray-600 font-light">
                      The domain that will be used in short links within your messages.
                    </div>
                    <Select
                      className="mt-2"
                      size="large"
                      style={{ width: '100%' }}
                      value={smsAutomation.smsShortLinkDomain.id}
                      onChange={handleSmsShortLinkDomainChange}>
                      {!smsShortLinkDomainsLoading
                        ? smsShortLinkDomainsData.smsShortLinkDomains.map(domain => (
                            <Option key={domain.id} value={domain.id}>
                              {domain.domain}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-8">
          <div className="text-lg font-bold text-gray-800">Text Messages</div>
        </div>

        <div className="mt-4 w-full max-w-6xl">
          {smsAutomation.smsMessages.map((message, index) => {
            return (
              <div key={message.id}>
                {index > 0 ? (
                  <div className="w-full text-center mt-6 mb-4">
                    <FaChevronDown className="m-auto text-xl text-gray-500" />
                  </div>
                ) : null}

                <MessageCard smsAutomation={smsAutomation} smsMessage={message} />
              </div>
            )
          })}
        </div>

        <div className="w-full text-center mt-6 mb-4 max-w-6xl">
          <FaChevronDown className="m-auto text-xl text-gray-500" />
        </div>

        <div className="w-full max-w-6xl">
          <NewMessageCard automation={smsAutomation} />
        </div>
      </div>
    </>
  )
}

export default withRouter(SmsAutomationPage)
