import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { NavLink, withRouter } from 'react-router-dom'
import StatusAlertBar from 'components/StatusAlertBar'
import { FaTachometerAlt, FaFunnelDollar, FaCog, FaCreditCard, FaQuestionCircle, FaTruck } from 'react-icons/fa'
import { AiFillMessage } from 'react-icons/ai'
import { setCurrentShop } from 'utils/Auth'

const SHOP = gql`
  query Shop {
    shop {
      id
      name
      shopifyHostname
      billingPlanType

      user {
        id
        name
        email
        phone
      }
    }
  }
`

const DashboardLayout = ({ children, match, location }) => {
  const isActive = path => path === location.pathname

  const { data } = useQuery(SHOP)

  useEffect(() => {
    if (data && data.shop) {
      setCurrentShop(data.shop.shopifyHostname)
    }
  }, [data])

  // Hubspot Identity
  useEffect(() => {
    // const _hsq = window._hsq = window._hsq || [];

    if (data && data.shop) {

      // _hsq.push(["identify",{
      //     email: data.shop.user.email
      // }]);

      window.analytics.identify(data.shop.user.id, {
        name: data.shop.user.name,
        email: data.shop.user.email,
        plan: data.shop.billingPlanType,
      });

      // window.hsConversationsSettings = {
      //   identificationEmail: data.shop.user.email,
      // };

      // window.HubSpotConversations.widget.load();

      // window.Intercom('update', {
      //   email: data.shop.user.email,
      //   name: data.shop.user.name,
      //   company: {
      //     id: data.shop.id,
      //     name: data.shop.name,
      //     plan: data.shop.billingPlanType,
      //     monthly_spend: data.shop.billingPlanType === 'PRO' ? 297 : data.shop.billingPlanType === 'LITE' ? 97 : 0,
      //     website: `https://${data.shop.shopifyHostname}`,
      //   },
      //   user_id: data.shop.user.id,
      //   phone: data.shop.user.phone,
      // })
      
      if (window.Beacon) {
        window.Beacon('identify', {
          name: data.shop.user.name,
          email: data.shop.user.email,
          company: data.shop.name,
          domain: `https://${data.shop.shopifyHostname}`,
          plan: data.shop.billingPlanType
        })
      }
    }
  }, [data])

  return (
    <div className="flex w-full min-h-screen font-sans bg-gray-100">
      <div>
        <div className="sticky top-0 min-h-screen text-center text-white bg-gray-800">
          <NavLink
            to="/dashboard"
            className="block"
            activeClassName="bg-gray-700"
            isActive={() => isActive('/dashboard')}>
            <div className="p-4">
              <FaTachometerAlt size="2em" className="m-auto text-gray-300 fill-current" />
            </div>
          </NavLink>

          <NavLink
            to="/dashboard/funnels"
            className="block"
            activeClassName="bg-gray-700"
            isActive={() => isActive('/dashboard/funnels')}>
            <div className="p-4">
              <FaFunnelDollar size="2em" className="m-auto text-gray-300 fill-current" />
            </div>
          </NavLink>

          <NavLink to="/dashboard/sms-automations" className="block" activeClassName="bg-gray-700">
            <div className="p-4">
              <AiFillMessage size="2em" className="m-auto text-gray-300 fill-current" />
            </div>
          </NavLink>

          <NavLink
            to="/dashboard/settings"
            className="block"
            activeClassName="bg-gray-700"
            isActive={() => isActive('/dashboard/settings')}>
            <div className="p-4">
              <FaCog size="2em" className="m-auto text-gray-300 fill-current" />
            </div>
          </NavLink>

          <NavLink
            to="/dashboard/shipping-zones"
            className="block"
            activeClassName="bg-gray-700"
            isActive={() => isActive('/dashboard/shipping-zones')}>
            <div className="p-4">
              <FaTruck size="2em" className="m-auto text-gray-300 fill-current" />
            </div>
          </NavLink>

          <NavLink
            to="/dashboard/billing"
            className="block"
            activeClassName="bg-gray-700"
            isActive={() => isActive('/dashboard/billing')}>
            <div className="p-4">
              <FaCreditCard size="2em" className="m-auto text-gray-300 fill-current" />
            </div>
          </NavLink>

          <a href="https://help.getbettercart.com" target="_blank" className="block" rel="noopener noreferrer">
            <div className="p-4">
              <FaQuestionCircle size="2em" className="m-auto text-gray-300 fill-current" />
            </div>
          </a>
        </div>
      </div>

      <div className="flex flex-col w-full m-h-full">
        <div className="flex-grow">
          <StatusAlertBar />

          <div>{children}</div>
        </div>
        <div className="flex-shrink w-full my-6 text-center">
          <div>Better Cart ©2019</div>
          <div>
            Made with{' '}
            <span role="img" aria-label="heart" aria-hidden="false">
              ❤️
            </span>{' '}
            in Chicago
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(DashboardLayout)
