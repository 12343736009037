import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const SHOP = gql`
  query shop {
    shop {
      id
      enabled
    }
  }
`

const StatusAlertBar = () => {
  const { data, loading } = useQuery(SHOP)

  if (!loading && data && !data.shop.enabled) {
    return (
      <div className="flex items-center justify-center py-3 m-3 bg-gray-200 rounded">
        <div className="text-gray-800">
          Better Cart is currently not live. You can make it live{' '}
          <Link to="/dashboard/settings" className="underline cursor-pointer hover:text-gray-600 hover:underline">
            here
          </Link>
        </div>
      </div>
    )
  } else return null
}

export default StatusAlertBar
