import gql from 'graphql-tag'

export const SHOP_SETTINGS = gql`
  query shopSettings {
    shop {
      id
      name
      logo
      headerBackground
      primaryBrandColor
    }
  }
`

export const UPDATE_SHOP = gql`
  mutation updateShop($shop: UpdateShopInput!) {
    updateShop(shop: $shop) {
      id
      name
      logo
      headerBackground
      primaryBrandColor
    }
  }
`
