import gql from 'graphql-tag'

export const CUSTOM_SCRIPTS = gql`
  query customScripts {
    customScripts {
      id
      name
      enabled
      body
      async
      location
    }
  }
`

export const UPDATE_CUSTOM_SCRIPT = gql`
  mutation updateCustomScript($id: ID!, $customScript: UpdateCustomScriptInput!) {
    updateCustomScript(id: $id, customScript: $customScript) {
      id
      name
      enabled
      body
      async
      location
    }
  }
`

export const DESTROY_CUSTOM_SCRIPT = gql`
  mutation destroyCustomScript($id: ID!) {
    destroyCustomScript(id: $id) {
      id
    }
  }
`
