import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Table from 'components/Table'
import ContentWrapper from 'components/ContentWrapper'
import CurrencyFormat from 'react-currency-format'
import NumberFormat from 'components/NumberFormat'
import RateFormat from 'components/RateFormat'
import { Switch, message } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { SMS_AUTOMATIONS, UPDATE_SMS_AUTOMATION } from './GraphQL'
import NewSmsAutomationModal from './NewSmsAutomationModal'

const SmsAutomationsPage = ({ match }) => {
  const [showNewAutomationModal, setShowNewAutomationModal] = useState(false)

  const { loading, error, data } = useQuery(SMS_AUTOMATIONS)

  const [updateSmsAutomation] = useMutation(UPDATE_SMS_AUTOMATION, {
    refetchQueries: [
      {
        query: SMS_AUTOMATIONS,
      },
    ],
    onCompleted: () => {
      message.success('Automation Successfully Updated')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleStatusChange = (id, value) => {
    updateSmsAutomation({
      variables: { id, smsAutomation: { enabled: value } },
    })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link to={`${match.url}/${record.id}`} className="hover:underline hover:text-gray-800">
          {text}
        </Link>
      ),
    },
    {
      title: 'Sends',
      dataIndex: 'sendCount',
      key: 'sendCount',
      render: text => <NumberFormat value={text} />,
    },
    {
      title: 'Clicks',
      dataIndex: 'clickCount',
      key: 'clickCount',
      render: text => <NumberFormat value={text} />,
    },
    {
      title: 'Cost',
      dataIndex: 'price',
      key: 'price',
      render: text => (
        <CurrencyFormat
          value={text > 0 ? text : 0}
          displayType={'text'}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          prefix={'$'}
        />
      ),
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      render: text => (
        <CurrencyFormat
          value={text > 0 ? text : 0}
          displayType={'text'}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          prefix={'$'}
        />
      ),
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      render: (text, record) => <RateFormat value={Math.floor(record.revenue / record.price)} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <Switch checked={record.enabled} onChange={value => handleStatusChange(record.id, value)} />
      ),
    },
  ]

  return (
    <div className="w-full">
      {data && data.shop.billingPlanType !== 'PRO' ? (
        <div className="flex items-center justify-center bg-gray-200 rounded m-3">
          <div className="text-gray-800 text-center p-4">
            SMS Automations is a Better Cart PRO feature. You can upgrade your account{' '}
            <Link to="/dashboard/billing" className="underline cursor-pointer hover:text-gray-600 hover:underline">
              here
            </Link>
          </div>
        </div>
      ) : null}

      {data && data.shop.billingPlanType === 'PRO' && data.shop.billingPaymentMethods.length < 1 ? (
        <div className="flex items-center justify-center bg-red-200 rounded m-3">
          <div className="text-gray-800 p-4 text-center">
            We have recently updated our internal payment processing for SMS Automations. We ask that you please add or
            re-add a new card. If a new card is not attached, SMS Automations will begin to fail. You can add your card{' '}
            <Link to="/dashboard/billing" className="underline cursor-pointer hover:text-gray-600 hover:underline">
              here.
            </Link>
            <div>Thank you for being a valued PRO Better Cart customer and sorry for any inconvenience.</div>
          </div>
        </div>
      ) : null}

      <div className="m-4">
        <div className="flex items-center">
          <div className="flex-1 text-2xl font-light text-gray-800 align-top mb-2">SMS Automations</div>
          <div className="flex-shrink">
            <div
              onClick={() => setShowNewAutomationModal(true)}
              className="w-auto text-center text-gray-300 bg-gray-800 p-2 rounded mb-2 cursor-pointer hover:bg-gray-700">
              New SMS Automation
            </div>
          </div>
        </div>

        {loading ? (
          <div className="">Loading...</div>
        ) : (
          <>
            {!data || !data.smsAutomations || data.smsAutomations.length < 1 ? (
              <>
                <div className="flex items-center justify-center w-full">
                  <img className="w-1/3 mt-16" src="/images/sms_automations.svg" alt="automations" />
                </div>
                <div className="w-full">
                  <div className="text-center text-lg mt-6 w-3/5 mx-auto text-gray-800">
                    Start automating your sales team. Send SMS with high click-through rate short links based on your
                    customer behavior.
                  </div>
                  <div
                    onClick={() => setShowNewAutomationModal(true)}
                    className="bg-gray-800 py-4 px-6 rounded cursor-pointer select-none text-white text-lg font-light text-center w-1/3 mt-6 mx-auto hover:bg-gray-700">
                    Start Reacting To Your Customers
                  </div>
                </div>
              </>
            ) : (
              <ContentWrapper>
                <Table columns={columns} dataSource={data ? data.smsAutomations : []} loading={loading} error={error} />
              </ContentWrapper>
            )}
          </>
        )}
      </div>

      <NewSmsAutomationModal showing={showNewAutomationModal} setShowing={setShowNewAutomationModal} />
    </div>
  )
}

export default withRouter(SmsAutomationsPage)
