import React, { useState } from 'react'
import { Select, Switch } from 'antd'
import { useMutation } from '@apollo/client'
import { CREATE_CUSTOM_SCRIPT, CUSTOM_SCRIPTS } from './GraphQL'
import CodeEditor from 'components/CodeEditor'
import FloatingLabelInput from 'components/FloatingLabelInput'

const { Option } = Select

const NewCustomScriptModal = ({ onClose }) => {
  const [script, setScript] = useState({
    name: '',
    body: '',
    async: true,
    location: 'ALL',
  })

  const [createCustomScript, { loading, error }] = useMutation(CREATE_CUSTOM_SCRIPT, {
    refetchQueries: [
      {
        query: CUSTOM_SCRIPTS,
      },
    ],
    onCompleted: () => {
      if (!error) {
        onClose()
        setScript({
          name: '',
          body: '',
          async: true,
          location: 'ALL',
        })
      }
    },
  })

  const handleCreate = () => {
    createCustomScript({
      variables: {
        customScript: script,
      },
    })
  }

  return (
    <>
      <div className="text-sm text-gray-600 pb-4 border-b border-gray-200">
        Custom scripts can be added throughout the Better Cart Checkout. First select if you would like the script to be
        included on every page of the chekcout, or if you would like the script to only be included on one of the pages
        including the Checkout Page, Offer Pages (upsell / downsell), or the Thank You Page (order confirmation). Next
        name it so you can keep track of the scripts internally. The body is the content that will be included,
        everything in this should be wrapped in &lt;script&gt;&lt;/script&gt; tags.
      </div>
      <div className="mt-4">
        <div className="">
          <Select
            size="large"
            style={{ width: '100%' }}
            placeholder="Select Platform"
            value={script.location}
            onChange={v => setScript({ ...script, location: v })}>
            <Option value="ALL">All Pages</Option>
            <Option value="CHECKOUT">Checkout Page</Option>
            <Option value="OFFER">Offer Pages</Option>
            <Option value="FIRST_OFFER">First Offer Page</Option>
            <Option value="THANK_YOU">Thank You Page</Option>
          </Select>
        </div>
        <div className="mt-4">
          <FloatingLabelInput
            label="Name"
            value={script.name}
            onChange={e => setScript({ ...script, name: e.target.value })}
          />

          <div className="mt-4 flex items-center">
            <div className="mr-4">Load Asyncronously</div>
            <Switch checked={script.async} onChange={value => setScript({ ...script, async: value })} />
          </div>

          <div className="mt-4">
            <CodeEditor code={script.body} setCode={code => setScript({ ...script, body: code })} />
          </div>

          {error ? (
            <div className="mt-4">
              {error.graphqlErrors.map(({ message }, index) => (
                <div className="text-sm text-red-400">{message}</div>
              ))}
            </div>
          ) : null}

          {loading ? (
            <div className="mt-4 p-4 text-sm text-white text-center bg-gray-300 cursor-pointer rounded hover:bg-gray-400">
              Adding Script...
            </div>
          ) : (
            <div
              className="mt-4 p-4 text-sm text-white text-center bg-gray-800 cursor-pointer rounded hover:bg-gray-700"
              onClick={handleCreate}>
              Create
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default NewCustomScriptModal
