import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Button, message } from 'antd'
import ShippingZoneCountries from 'components/ShippingZoneCountries/ShippingZoneCountries'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { useMutation } from '@apollo/client'
import { CREATE_SHIPPING_ZONE, SHIPPING_ZONES } from './GraphQL'

const NewShippingZoneModal = ({ history, showing, setShowing }) => {
  const [selected, setSelected] = useState({})
  const [name, setName] = useState('')

  const [createZone, { loading }] = useMutation(CREATE_SHIPPING_ZONE, {
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
    onCompleted: data => {
      message.success('Successfully created new shipping zone!')
      setShowing(false)
      history.push(`/dashboard/shipping-zones/${data.createShippingZone.id}/rates`)
    },
    refetchQueries: [
      {
        query: SHIPPING_ZONES,
      },
    ],
  })

  const handleCreateShippingZone = () => {
    createZone({
      variables: {
        shippingZone: {
          name,
          countries: selected,
        },
      },
    })
  }

  return (
    <Modal
      title="Create New Zone"
      okText="Create"
      onOk={handleCreateShippingZone}
      onCancel={() => {
        setShowing(false)
      }}
      visible={showing}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setShowing(false)
          }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleCreateShippingZone}>
          Create
        </Button>,
      ]}>
      <div>
        <FloatingLabelInput label="Zone Name" value={name} onChange={e => setName(e.target.value)} />
        <div className="mt-1 ml-1 text-sm text-gray-400">Customers will not see this</div>
        <div className="mt-4">
          <ShippingZoneCountries selected={selected} setSelected={setSelected} />
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(NewShippingZoneModal)
