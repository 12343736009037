import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import PaymentGatewayForm from 'components/PaymentGateways/forms/PaymentGatewayForm'

import { useQuery } from '@apollo/client'
import { PAYMENT_GATEWAYS } from './GraphQL'

const { Option } = Select

const NewPaymentGatewayModal = ({ onClose }) => {
  const [newGatewaySlug, setNewGatewaySlug] = useState()
  const [selectedGateway, setSelectedGateway] = useState({})

  const { loading, error, data } = useQuery(PAYMENT_GATEWAYS)

  useEffect(() => {
    if (data && data.paymentGateways && newGatewaySlug) {
      setSelectedGateway(data.paymentGateways.find(g => g.slug === newGatewaySlug))
    }
  }, [data, newGatewaySlug])

  return (
    <>
      <div className="text-sm text-gray-600 pb-4 mb-4 border-b border-gray-200">
        Select a supported payment gateway below that you wish to connect. Some payment gateways can be connected with
        OAuth and some are as simple as inputing a few fields that you can aquire from their systems.
      </div>
      <div>
        {error ? (
          error.graphQLErrors.map(({ message }, index) => (
            <div className="text-sm text-red-400" key={index}>
              {message}
            </div>
          ))
        ) : loading ? (
          <div className="text-sm text-gray-500">Loading Gateways...</div>
        ) : (
          <>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Gateway"
              value={newGatewaySlug}
              onChange={v => setNewGatewaySlug(v)}>
              {data.paymentGateways.map(gateway => {
                return (
                  <Option value={gateway.slug} key={gateway.slug}>
                    {gateway.name}
                  </Option>
                )
              })}
            </Select>
            <div className="mt-6">
              <PaymentGatewayForm gateway={selectedGateway} shopId={data.shop.id} onClose={onClose} />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default NewPaymentGatewayModal
