import React from 'react'
import { withRouter } from 'react-router-dom'
import { OnboardingContextProvider } from 'contexts/OnboardingContext'
import OnboardingLayout from 'layouts/OnboardingLayout'

import WelcomeStep from 'pages/dashboard/onboarding/WelcomeStep/WelcomeStep'
import ShopStep from 'pages/dashboard/onboarding/ShopStep/ShopStep'
import FunnelStep from 'pages/dashboard/onboarding/FunnelStep/FunnelStep'
import GatewayStep from 'pages/dashboard/onboarding/GatewayStep/GatewayStep'
import BillingStep from 'pages/dashboard/onboarding/BillingStep/BillingStep'
import CompletedStep from 'pages/dashboard/onboarding/CompletedStep/CompletedStep'

const OnboardingContent = ({ match }) => {
  return (
    <OnboardingContextProvider>
      <OnboardingLayout>
        {(() => {
          switch (match.params.step) {
            case 'welcome':
              return <WelcomeStep />
            case 'shop':
              return <ShopStep />
            case 'funnel':
              return <FunnelStep />
            case 'gateway':
              return <GatewayStep />
            case 'billing':
              return <BillingStep />
            case 'completed':
              return <CompletedStep />
            default:
              return null
          }
        })()}
      </OnboardingLayout>
    </OnboardingContextProvider>
  )
}

export default withRouter(OnboardingContent)
