import React, { useState } from 'react'
import { Upload } from 'antd'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import { fetch } from 'whatwg-fetch'

const SHOP_ASSET_URL = gql`
  query shopAssetPresignUrl($fileName: String!, $fileType: String!) {
    shopAssetPresignUrl(fileName: $fileName, fileType: $fileType) {
      url
      publicUrl
    }
  }
`

const ShopAssetUploaderDragable = ({ onPublicUrl, children }) => {
  const [file, setFile] = useState()
  const [uploading, setUploading] = useState(false)
  const [info, setInfo] = useState({})

  const [shopAssetUrl, { loading }] = useLazyQuery(SHOP_ASSET_URL, {
    onCompleted: async data => {
      try {
        setUploading(true)
        const fileBlob = new Blob([file.file], { type: file.file.type })

        await fetch(data.shopAssetPresignUrl.url, {
          method: 'PUT',
          headers: { 'Content-Type': fileBlob.type },
          body: fileBlob,
        })

        setUploading(false)
        onPublicUrl(data.shopAssetPresignUrl.publicUrl)
      } catch (e) {
        console.log(e)
      }
    },
  })

  const onFile = file => {
    setFile(file)

    shopAssetUrl({
      variables: {
        fileName: file.file.name,
        fileType: file.file.type,
      },
    })
  }

  const handleChange = i => setInfo(i)

  return (
    <Upload.Dragger onChange={handleChange} customRequest={onFile} listType="picture-card" fileList={[]}>
      {React.Children.map(children, child => React.cloneElement(child, { loading: loading || uploading, info }))}
    </Upload.Dragger>
  )
}

export default ShopAssetUploaderDragable
