import React from 'react'
import { MdDragHandle } from 'react-icons/md'

export default () => {
  return (
    <tr className="animate-pulse">
      <td className="px-4 py-2">
        <MdDragHandle size="2em" className="m-auto text-gray-400 fill-current" />
      </td>
      <td className="px-4 py-2">
        <div className="w-16 h-2 mx-auto bg-gray-400"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-16 h-10 mx-auto bg-gray-400 rounded-full"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-5 h-2 mx-auto bg-gray-400"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-5 h-2 mx-auto bg-gray-400"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-6 h-2 mx-auto bg-gray-400"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-6 h-2 mx-auto bg-gray-400"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-10 h-2 mx-auto bg-gray-400"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-10 h-2 mx-auto bg-gray-400"></div>
      </td>
      <td className="px-4 py-2">
        <div className="w-12 h-5 mx-auto bg-gray-400 rounded-full"></div>
      </td>
      <td className="px-4 py-2">
        <div className="flex items-center">
          <div className="w-5 h-5 mx-auto bg-gray-400 rounded"></div>
          <div className="w-5 h-5 mx-1 bg-gray-400 rounded"></div>
          <div className="w-5 h-5 mx-auto bg-gray-400 rounded"></div>
        </div>
      </td>
    </tr>
  )
}
