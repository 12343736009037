import React, { Fragment } from 'react'
import TextareaAutosize from 'react-autosize-textarea'

const CustomTextarea = ({ label, value, ...props }) => {
  return (
    <Fragment>
      {label ? <div className="text-xs text-gray-800 font-semibold uppercase">{label}</div> : null}
      <TextareaAutosize
        {...props}
        value={value ? value : ''}
        className="border-b border-gray-700 text-xs pb-2 w-full resize-none mt-2"
      />
    </Fragment>
  )
}

export default CustomTextarea
