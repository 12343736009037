import React, { useState, useEffect, useMemo, useCallback } from 'react'
import ContentWrapper from 'components/ContentWrapper'
import { useQuery, useMutation } from '@apollo/client'
import { SHOP_BILLING, CREATE_PAYMENT_METHOD, BILLING_SUBSCRIBE, BILLING_CANCEL } from './GraphQL'
import { Modal, Tooltip, message } from 'antd'
import Script from 'react-load-script'
import PaymentMethod from 'components/billing/PaymentMethod'
import {
  FaBolt,
  FaFunnelDollar,
  FaCreditCard,
  FaCode,
  FaPercentage,
  FaArrowRight,
  FaChartLine,
  FaRobot,
  FaPlus,
  FaMoneyBill,
  FaCheckCircle,
  FaShoppingCart,
} from 'react-icons/fa'
import { GiArrowCursor, GiDrop } from 'react-icons/gi'
import { FiInfo, FiLoader } from 'react-icons/fi'

const BillingPage = () => {
  const [creditCardModalShowing, setCreditCardModalShowing] = useState(false)
  const [stripeLoaded, setStripeLoaded] = useState(false)
  const [stripeCallbacks, setStripeCallbacks] = useState({ create: () => {}, clear: () => {} })
  const [stripeTokenLoading, setStripeTokenLoading] = useState(false)
  const [stripeErrors, setStripeErrors] = useState(false)

  const { data, loading, error } = useQuery(SHOP_BILLING)

  const hasPaymentMethod = useMemo(() => {
    return data && data.shop.billingPaymentMethods && data.shop.billingPaymentMethods.length > 0
  }, [data])

  const isPlanActive = useCallback(
    planType => {
      return data && data.shop.billingPlanType === planType && data.shop.billingPlanStatus === 'ACTIVE'
    },
    [data]
  )

  const [createPaymentMethod, { loading: createLoading, error: createError }] = useMutation(CREATE_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: SHOP_BILLING,
      },
    ],
    onCompleted: data => {
      window.analytics.track("AddPaymentInfo", {
        shop: data.billingCreatePaymentMethod.shop.shopifyHostname,
      })

      setCreditCardModalShowing(false)
      stripeCallbacks.clear()
    },
    onError: () => {},
  })

  const createCreditCard = async () => {
    setStripeTokenLoading(true)
    const result = await stripeCallbacks.create()
    setStripeTokenLoading(false)

    if (result.error) {
      setStripeErrors(result.error.message)
    } else {
      createPaymentMethod({
        variables: {
          token: result.token.id,
        },
      })
      setStripeErrors(false)
    }
  }

  const [billingSubscribe, { loading: subscribeLoading }] = useMutation(BILLING_SUBSCRIBE, {
    onCompleted: data => {
      window.analytics.track("TrialStarted", {
        shop: data.billingSubscribe.name,
        plan: data.billingSubscribe.billingPlanType,
      })

      message.success('Successfully subscribed!')
    },
    onError: error => {
      message.error('There was an error activating your subscription, please contact support.')
    },
  })

  const subscribe = planId => {
    if (hasPaymentMethod) {
      billingSubscribe({
        variables: {
          planId,
        },
      })
    } else {
      setCreditCardModalShowing(true)
    }
  }

  const [billingCancel, { loading: cancelLoading }] = useMutation(BILLING_CANCEL, {
    onCompleted: data => {
      window.analytics.track("PlanCancelled", {
        shop: data.billingCancel.shopifyHostname,
      })

      message.success('Successfully canceled')
    },
  })

  const cancel = () => {
    billingCancel()
  }

  useEffect(() => {
    if (stripeLoaded) {
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
      const cardElement = stripe.elements().create('card')
      cardElement.mount('#stripe-card')

      setStripeCallbacks({
        create: () => stripe.createToken(cardElement),
        clear: () => cardElement.clear(),
      })
    }
  }, [stripeLoaded, setStripeCallbacks])

  if (loading) return 'Loading...'
  if (error) return 'Uh Oh! An error occurred'

  return (
    <>
      <div className="w-full">
        <div className="m-4">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Choose Your Plan</div>
          </div>
          <div className="m-4">
            <div className="flex items-start justify-around">
              <div className="flex-shrink w-5/12 border-gray-200 rounded shadow-xl">
                <div className="flex p-3 border-b border-gray-300">
                  <div className="w-full text-xl font-light text-center text-green-400 uppercase">Lite</div>
                </div>
                <div className="px-16 py-4">
                  <div className="w-full text-base font-light text-center text-gray-800">
                    Access a complete checkout platform enabling one click upsells, external payment gateways and the
                    fastest loading checkout on Shopify.
                  </div>
                  <div className="w-full mt-2">
                    <div className="flex items-center justify-center">
                      <div className="text-5xl font-light text-gray-800">$97</div>
                      <div className="ml-2 text-base font-light text-gray-400"> / Month</div>
                    </div>
                  </div>
                  <div className="w-full text-center text-gray-600">14 Day Free Trial</div>
                  <div className="mt-4">
                    <div className="flex">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaBolt className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-800">Lightning Fast Checkout</div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <GiArrowCursor className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-800">Everything you need to process 1 click upsells</div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaFunnelDollar className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-800">3 Upsell Funnels</div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaCode className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-800">Multiple Pixel Trackers</div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaPercentage className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-800">Flat Rate Pricing: 0% RevShare</div>
                    </div>
                  </div>
                </div>

                {isPlanActive('LITE') ? (
                  <div className="flex flex-col p-5 mt-4 bg-gray-200 group hover:bg-gray-300">
                    <div className="flex items-center justify-center w-full text-lg font-semibold text-center text-gray-800 uppercase">
                      <div className="text-green-400">Your Current Plan</div>
                      <div className="ml-3">
                        <FaCheckCircle className="text-green-500" />
                      </div>
                    </div>
                    {cancelLoading ? (
                      <div className="flex items-center justify-center mt-2">
                        <div className="text-center text-red-400">Canceleling Plan</div>
                        <div className="ml-3">
                          <FiLoader className="text-xl text-red-400 icon-spin" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="hidden text-center text-red-400 cursor-pointer group-hover:block hover:underline"
                        onClick={cancel}>
                        Cancel Plan
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="flex p-5 mt-4 bg-gray-200 cursor-pointer hover:bg-gray-300"
                    onClick={() => subscribe('lite2')}>
                    <div className="flex items-center justify-center w-full text-lg font-semibold text-center text-gray-800 uppercase">
                      {subscribeLoading ? (
                        <>
                          <div>Activating Your Plan</div>
                          <div className="ml-3">
                            <FiLoader className="icon-spin" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>Choose Plan</div>
                          <div className="ml-3">
                            <FaArrowRight />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="w-6/12 overflow-hidden bg-gray-700 border-gray-200 rounded shadow-xl">
                <div className="flex p-3 border-b border-gray-800">
                  <div className="w-full text-3xl font-light text-center text-green-400 uppercase">Pro</div>
                </div>
                <div className="py-4 pl-12 pr-4">
                  <div className="w-full text-base font-light text-center text-gray-200">
                    This plan is for the serious merchant that wants to take both their store and it's numbers to the
                    next level. Increase revenue and customer LTV with a full suite of advanced features.
                  </div>
                  <div className="w-full mt-2">
                    <div className="flex items-center justify-center">
                      <div className="text-5xl font-light text-green-400">$297</div>
                      <div className="ml-2 text-base font-light text-gray-200"> / Month</div>
                    </div>
                    <div className="w-full text-center text-green-400">14 Day Free Trial</div>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaPlus className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-200">Everything LITE</div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaCreditCard className="text-sm" />
                      </div>
                      <div className="flex items-center ml-3 text-sm text-gray-200">
                        <div>Advanced Payment Gateway Support</div>
                      </div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaFunnelDollar className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-200">Unlimited Upsell Funnels</div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaChartLine className="text-sm" />
                      </div>
                      <div className="ml-3 text-sm text-gray-200">Advanced Events and Reporting Dashboards</div>
                      <div className="px-2 py-1 ml-2 text-xs text-center text-green-500 bg-gray-800 rounded-full">
                        Coming Soon
                      </div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaRobot className="text-sm" />
                      </div>
                      <div className="flex items-center ml-3 text-sm text-gray-200">
                        <div>Event Automations (Abandoned Cart Recovery)</div>
                        <div className="ml-2 cursor-pointer hover:text-green-400">
                          <Tooltip title="Advanced event collection allowing for customized triggers on both internal and 3rd party services. Including SMS Cart Abandonments.">
                            <FiInfo />
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <GiDrop className="text-sm" />
                      </div>
                      <div className="flex items-center ml-3 text-sm text-gray-200">
                        <div>Offer Decline Recovery w/ 1 Click Accept</div>
                        <div className="ml-2 cursor-pointer hover:text-green-400">
                          <Tooltip title="Recover declined upsell or downsell offers presented to customer with delayed automation triggers that allow customers to experience the same one click offer accept at a later time via email or SMS.">
                            <FiInfo />
                          </Tooltip>
                        </div>
                        <div className="px-2 py-1 ml-2 text-xs text-center text-green-500 bg-gray-800 rounded-full">
                          Coming Soon
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaMoneyBill className="text-sm" />
                      </div>
                      <div className="flex items-center ml-3 text-sm text-gray-200">
                        <div>Subscription Billing Plans</div>
                        <div className="ml-2 cursor-pointer hover:text-green-400">
                          <Tooltip title="Integration free subscription billing plans built right in. Offer subscriptions during checkout or offer a subscription to customers as a 1 click upsell.">
                            <FiInfo />
                          </Tooltip>
                        </div>
                        <div className="px-2 py-1 ml-2 text-xs text-center text-green-500 bg-gray-800 rounded-full">
                          Coming Soon
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaShoppingCart className="text-sm" />
                      </div>
                      <div className="flex items-center ml-3 text-sm text-gray-200">
                        <div>Custom Checkout Templates</div>
                        <div className="ml-2 cursor-pointer hover:text-green-400">
                          <Tooltip title="Customize your checkout page without the need for expensive Shopify Plus plans or commitments. Customize your checkout with a single dropdown, no code, no developers.">
                            <FiInfo />
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-3">
                      <div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full">
                        <FaCheckCircle className="text-sm" />
                      </div>
                      <div className="flex items-center ml-3 text-sm text-gray-200">
                        <div>In Checkout Product Bumps</div>
                        <div className="ml-2 cursor-pointer hover:text-green-400">
                          <Tooltip title="Add bump products to your checkout that allow customers to add products to their cart by simply checking a box!">
                            <FiInfo />
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex mt-3"> */}
                    {/* 	<div className="flex items-center justify-center w-6 h-6 text-green-500 bg-green-200 rounded-full"> */}
                    {/* 		<FaDownload className="text-sm" /> */}
                    {/* 	</div> */}
                    {/* 	<div className="flex items-center ml-3 text-sm text-gray-200"> */}
                    {/* 		<div>Digital Products</div> */}
                    {/* 		<div className="ml-2 cursor-pointer hover:text-green-400"> */}
                    {/* 			<Tooltip title="Integration free digital products that can be sold in bundles or individual downloads. Track and limit the number of downloads per customer."> */}
                    {/* 				<FiInfo /> */}
                    {/* 			</Tooltip> */}
                    {/* 		</div> */}
                    {/* 	</div> */}
                    {/* </div> */}
                  </div>
                </div>

                {isPlanActive('PRO') ? (
                  <div className="flex flex-col p-5 mt-4 bg-gray-200 group hover:bg-gray-300">
                    <div className="flex items-center justify-center w-full text-lg font-semibold text-center text-gray-800 uppercase">
                      <div className="text-green-400">Your Current Plan</div>
                      <div className="ml-3">
                        <FaCheckCircle className="text-green-500" />
                      </div>
                    </div>
                    {cancelLoading ? (
                      <div className="flex items-center justify-center mt-2">
                        <div className="text-center text-red-400">Canceleling Plan</div>
                        <div className="ml-3">
                          <FiLoader className="text-xl text-red-400 icon-spin" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="hidden text-center text-red-400 cursor-pointer group-hover:block hover:underline"
                        onClick={cancel}>
                        Cancel Plan
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="flex p-5 mt-4 bg-green-500 cursor-pointer hover:bg-green-400"
                    onClick={() => subscribe('pro')}>
                    <div className="flex items-center justify-center w-full text-lg font-semibold text-center text-gray-800 uppercase">
                      {subscribeLoading ? (
                        <>
                          <div>Activating Your Plan</div>
                          <div className="ml-3">
                            <FiLoader className="icon-spin" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>Choose Plan</div>
                          <div className="ml-3">
                            <FaArrowRight />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mx-4 my-8">
          <div className="flex mb-2">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Payment Methods</div>
            {hasPaymentMethod ? (
              <div>
                <div
                  className="p-3 text-sm text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-500"
                  onClick={() => setCreditCardModalShowing(true)}>
                  New Payment Method
                </div>
              </div>
            ) : null}
          </div>
          <ContentWrapper>
            <div className="p-2">
              {hasPaymentMethod ? (
                data.shop.billingPaymentMethods.map(paymentMethod => (
                  <PaymentMethod key={paymentMethod.id} method={paymentMethod} />
                ))
              ) : (
                <div className="flex">
                  <div
                    className="flex-shrink p-3 text-sm text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                    onClick={() => setCreditCardModalShowing(true)}>
                    Add A Payment Method
                  </div>
                </div>
              )}
            </div>
          </ContentWrapper>
        </div>
      </div>

      <Modal
        title="Add New Credit Card"
        visible={creditCardModalShowing}
        okText="Add Card"
        onOk={createCreditCard}
        onCancel={() => setCreditCardModalShowing(false)}
        confirmLoading={stripeTokenLoading || createLoading}>
        <Script url={process.env.REACT_APP_STRIPE_URL} onLoad={() => setStripeLoaded(true)} />
        <div id="stripe-card" />
        {stripeErrors ? <div className="mt-2 text-sm text-red-400">{stripeErrors}</div> : null}
        {createError
          ? createError.graphQLErrors.map(({ message }) => {
              return <div className="mt-2 text-sm text-red-400">{message}</div>
            })
          : null}
      </Modal>
    </>
  )
}

export default BillingPage
