import React, { useState, useEffect, useMemo } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { FUNNEL, UPDATE_FUNNEL } from './GraphQL'
import { Select, Switch, Input, message } from 'antd'
import { FaChevronLeft, FaCog, FaCreditCard, FaPaypal } from 'react-icons/fa'
import { AiFillLayout } from 'react-icons/ai'
import { GoSettings } from 'react-icons/go'
import FloatingLabelInput from 'components/FloatingLabelInput'
import InputColorPicker from 'components/InputColorPicker'
import useDebounce from 'components/useDebounce'

const { Option } = Select

const FunnelSettings = ({ match }) => {
  const [tempFunnel, setTempFunnel] = useState({})
  const { loading, error, data } = useQuery(FUNNEL, {
    variables: {
      id: match.params.funnelId,
    },
  })

  useEffect(() => {
    if (data && data.funnel) {
      setTempFunnel(data.funnel)
    }
  }, [data])

  const basicDirty = useMemo(() => {
    if (!data || !data.funnel) return false
    return data.funnel.name !== tempFunnel.name || data.funnel.tag !== tempFunnel.tag
  }, [tempFunnel, data])

  const [updateFunnel, { loading: updateLoading }] = useMutation(UPDATE_FUNNEL, {
    refetchQueries: [
      {
        query: FUNNEL,
        variables: {
          id: match.params.funnelId,
        },
      },
    ],
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
    onCompleted: () => {
      message.success('Funnel Successfully Updated')
    },
  })

  const handleStatusChange = checked => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          enabled: checked,
        },
      },
    })
  }

  const handleSaveBasic = () => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          name: tempFunnel.name,
          tag: tempFunnel.tag,
        },
      },
    })
  }

  const handleGatewayChange = id => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          shopPaymentGatewayId: id,
        },
      },
    })
  }

  const handlePaypalChange = id => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          paypalShopPaymentGatewayId: id,
        },
      },
    })
  }

  const handleTemplateChange = id => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          checkoutTemplate: id,
        },
      },
    })
  }

  const handlePhoneChange = checked => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          phoneOptional: checked,
        },
      },
    })
  }

  const handleSummaryChange = checked => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          showSummary: checked,
        },
      },
    })
  }

  const handleExpressChange = checked => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          showExpressCheckouts: checked,
        },
      },
    })
  }

  const handleShowTimerChange = checked => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          showTimer: checked,
        },
      },
    })
  }

  const handleSaveTimerBgColorChange = color => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          timerBgColor: color,
        },
      },
    })
  }

  const handleSaveTimerTextColorChange = color => {
    updateFunnel({
      variables: {
        id: match.params.funnelId,
        funnel: {
          timerTextColor: color,
        },
      },
    })
  }

  const debouncedTimerDescription = useDebounce(tempFunnel.timerDescription, 2000)
  const debouncedTimerText = useDebounce(tempFunnel.timerText, 2000)

  useEffect(() => {
    if (debouncedTimerDescription && debouncedTimerDescription !== data.funnel.timerDescription) {
      updateFunnel({
        variables: {
          id: match.params.funnelId,
          funnel: {
            timerDescription: debouncedTimerDescription,
          },
        },
      })
    }
  }, [debouncedTimerDescription, updateFunnel, match.params.funnelId, data])

  useEffect(() => {
    if (debouncedTimerText && debouncedTimerText !== data.funnel.timerText) {
      updateFunnel({
        variables: {
          id: match.params.funnelId,
          funnel: {
            timerText: debouncedTimerText,
          },
        },
      })
    }
  }, [debouncedTimerText, updateFunnel, match.params.funnelId, data])

  if (loading) {
    return <div className="m-6 text-lg text-center text-gray-400">Loading Funnel...</div>
  }

  if (error) {
    return (
      <div className="m-6 text-lg text-center text-red-400">
        whoops, there was an error that occurred, please refresh the page.
      </div>
    )
  }

  return (
    <>
      <div className="sticky top-0 z-50 w-full p-4 bg-white shadow-md">
        <div className="flex">
          <div className="self-center flex-1">
            <Link
              to={`/dashboard/funnels/${match.params.funnelId}`}
              className="flex text-base font-light text-gray-500 cursor-pointer hover:text-gray-600 hover:underline">
              <FaChevronLeft className="self-center flex-shrink mr-1 group-hover:text-gray-600" />
              <div className="self-center flex-shrink group-hover:text-gray-600">{data.funnel.name}</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="m-4 mt-2">
        <div className="flex items-center w-full max-w-6xl mb-4">
          <div className="flex-shrink mr-4">
            <div className="text-2xl font-light text-gray-800">{data.funnel.name}</div>
          </div>
          <div className="flex-shrink">
            <Switch checked={data.funnel.enabled} onChange={value => handleStatusChange(value)} />
          </div>
          <div className="flex-grow" />
          {updateLoading ? <div className="text-sm text-gray-400">Saving Funnel...</div> : null}
        </div>

        <div className="w-full max-w-6xl">
          <div className="w-full overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="self-center flex-1">
                    <FaCog className="mx-auto text-4xl text-white" />
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="flex items-center justify-between">
                  <div className="my-2 text-lg font-medium text-gray-800">Funnel Settings</div>
                  {basicDirty ? (
                    <div
                      className="px-4 py-2 text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                      onClick={handleSaveBasic}>
                      Save
                    </div>
                  ) : null}
                </div>
                <div className="flex mt-2">
                  <div className="w-1/2 pr-1">
                    <FloatingLabelInput
                      label="Funnel Name"
                      value={tempFunnel.name}
                      onChange={e => setTempFunnel({ ...tempFunnel, name: e.target.value })}
                    />
                  </div>
                  <div className="w-1/2 pl-1">
                    <FloatingLabelInput
                      label="Funnel Tag"
                      value={tempFunnel.tag}
                      onChange={e => setTempFunnel({ ...tempFunnel, tag: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-6 overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="self-center flex-1">
                    <FaCreditCard className="mx-auto text-4xl text-white" />
                  </div>
                </div>
              </div>
              <div className="w-1/2 pl-4">
                <div className="flex content-center h-full">
                  <div className="self-center flex-1 max-w-md my-2">
                    <div className="text-lg font-medium text-gray-800">Credit Card Payment Gateway</div>
                    <div className="text-sm font-light text-gray-600">
                      Every funnel can individually specify which credit card payment gateway you would like to use.
                      This allows merchants to use different gateways for the same store simultaneously.
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-6 py-2">
                <div className="text-lg font-medium text-gray-800">Payment Gateway</div>
                <Select
                  className="mt-1 mb-2"
                  size="large"
                  style={{ width: '100%' }}
                  value={data.funnel.shopPaymentGateway.id}
                  onChange={handleGatewayChange}>
                  {data.funnel.shop.shopPaymentGateways
                    .filter(g => g.paymentGateway.gatewayType !== 'PAYPAL_CHECKOUT')
                    .map(shopPaymentGateway => (
                      <Option key={shopPaymentGateway.id} value={shopPaymentGateway.id}>
                        {shopPaymentGateway.paymentGateway.name}
                        {shopPaymentGateway.nickname ? ` - ${shopPaymentGateway.nickname}` : ''}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          </div>

          <div className="w-full mt-6 overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="self-center flex-1">
                    <FaPaypal className="mx-auto text-4xl text-white" />
                  </div>
                </div>
              </div>
              <div className="w-1/2 pl-4">
                <div className="flex content-center h-full">
                  <div className="self-center flex-1 max-w-md my-2">
                    <div className="text-lg font-medium text-gray-800">PayPal Checkout</div>
                    <div className="text-sm font-light text-gray-600">
                      Specify if you would like to use PayPal Checkout for this funnel. This allows merchants to specify
                      which funnels have PayPal enabled.
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-6 py-2">
                <div className="text-lg font-medium text-gray-800">PayPal Account</div>
                <Select
                  className="mt-1 mb-2"
                  size="large"
                  style={{ width: '100%' }}
                  value={data.funnel.paypalShopPaymentGateway ? data.funnel.paypalShopPaymentGateway.id : null}
                  onChange={handlePaypalChange}>
                  {data.funnel.shop.shopPaymentGateways
                    .filter(g => g.paymentGateway.gatewayType === 'PAYPAL_CHECKOUT')
                    .map(shopPaymentGateway => (
                      <Option key={shopPaymentGateway.id} value={shopPaymentGateway.id}>
                        {shopPaymentGateway.paymentGateway.name}
                        {shopPaymentGateway.nickname ? ` - ${shopPaymentGateway.nickname}` : ''}
                      </Option>
                    ))}
                  <Option value={null} key={'none'}>
                    No PayPal Payments
                  </Option>
                </Select>
              </div>
            </div>
          </div>

          <div className="w-full mt-6 overflow-hidden bg-white border-2 border-gray-200 rounded shadow-xl">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="self-center flex-1">
                    <AiFillLayout className="mx-auto text-4xl text-white" />
                  </div>
                </div>
              </div>
              <div className="w-1/2 pl-4">
                <div className="flex content-center h-full">
                  <div className="self-center flex-1 max-w-md my-2">
                    <div className="text-lg font-medium text-gray-800">Custom Checkout Template</div>
                    <div className="text-sm font-light text-gray-600">
                      Every funnel can select a custom checkout template. A checkout template will allow you to change
                      the entire look and feel of the checkout page with a simply dropdown, we know, it's awesome.
                    </div>
                  </div>
                </div>
              </div>
              {data.funnel.shop.billingPlanType !== 'PRO' ? (
                <div className="w-1/2 p-2 m-2 border border-pink-300 rounded">
                  <div className="flex content-center">
                    <div className="flex items-center justify-center w-1/4">
                      <img src="/images/wireframe.svg" alt="checkboxes" />
                    </div>
                    <div className="w-3/4 ml-4">
                      <div className="text-lg font-semibold text-gray-800">Custom Checkout Templates</div>
                      <div className="text-sm font-light text-gray-800">
                        Customize your checkout page with out the need of expensive Shopify Plus plans. Simply change
                        the template with a dropdown enabling a single page checkout.
                      </div>
                      <div className="mt-2">
                        <Link to="/dashboard/billing">
                          <div className="w-auto p-1 mt-1 font-semibold text-center text-gray-800 bg-gray-200 border border-pink-300 rounded cursor-pointer hover:bg-gray-300">
                            Upgrade to PRO
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-1/2 px-6 py-2">
                  <div className="text-lg font-medium text-gray-800">Template</div>
                  <Select
                    className="mt-1 mb-2"
                    size="large"
                    style={{ width: '100%' }}
                    value={data.funnel.checkoutTemplate}
                    onChange={handleTemplateChange}>
                    <Option value="SHOPIFY">Shopify Clone</Option>
                    <Option value="SHOPIFY_SINGLE_PAGE">Single Page Shopify Clone</Option>
                  </Select>
                </div>
              )}
            </div>
          </div>

          <div className="w-full mt-6 overflow-hidden overflow-visible bg-white border-2 border-gray-200 rounded shadow-xl">
            <div className="flex">
              <div className="flex-shrink w-20 bg-gray-800">
                <div className="flex h-full">
                  <div className="self-center flex-1">
                    <GoSettings className="mx-auto text-4xl text-white" />
                  </div>
                </div>
              </div>
              <div className="w-full p-4">
                <div className="text-xl font-medium text-gray-800">Checkout Settings</div>
                <div className="text-sm font-light text-gray-600">
                  Customize what shows and how it is shown on your checkout page.
                </div>

                <div className="mt-2">
                  <div className="flex items-center justify-between">
                    <div className="text-base font-semibold text-gray-800">Customer Phone Optional</div>
                    <div className="ml-4">
                      <Switch checked={data.funnel.phoneOptional} onChange={checked => handlePhoneChange(checked)} />
                    </div>
                  </div>
                  <div className="mt-1 mr-10 text-sm text-gray-600">
                    Enabling this will make the phone number field in the shipping and billing form optional to the customer.
                  </div>
                </div>
                <div className="mt-2">
                  <div className="flex items-center justify-between">
                    <div className="text-base font-semibold text-gray-800">Default Order Summary Open</div>
                    <div className="ml-4">
                      <Switch checked={data.funnel.showSummary} onChange={checked => handleSummaryChange(checked)} />
                    </div>
                  </div>
                  <div className="mt-1 mr-10 text-sm text-gray-600">
                    Enabling this will show the order summary and line items expanded by default on mobile.
                  </div>
                </div>
                <div className="mt-2">
                  <div className="flex items-center justify-between">
                    <div className="text-base font-semibold text-gray-800">Show Express Checkouts</div>
                    <div className="ml-4">
                      <Switch
                        checked={data.funnel.showExpressCheckouts}
                        onChange={checked => handleExpressChange(checked)}
                      />
                    </div>
                  </div>
                  <div className="mt-1 mr-10 text-sm text-gray-600">
                    Show or hide the express checkout buttons at the top of the checkout (PayPal). These will still
                    appear as a payment option in the payment step of the checkout.
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center justify-between mt-2">
                    <div className="text-base font-semibold text-gray-800">Show Checkout Timer</div>
                    <div className="ml-4">
                      <Switch checked={data.funnel.showTimer} onChange={checked => handleShowTimerChange(checked)} />
                    </div>
                  </div>
                  {data.funnel.showTimer ? (
                    <>
                      <div className="flex mt-2">
                        <div className="w-1/2 pr-1">
                          <div className="text-sm text-gray-800">Timer Description</div>
                          <Input.TextArea
                            placeholder="Timer Description"
                            className="mt-1 border-gray-400"
                            value={
                              tempFunnel.timerDescription ||
                              'An item you ordered is in high demand. No worries, we have reserved your order.'
                            }
                            onChange={e => setTempFunnel({ ...tempFunnel, timerDescription: e.target.value })}
                            autoSize={{ minRows: 2, maxRows: 3 }}
                          />
                        </div>
                        <div className="w-1/2 pl-1">
                          <div className="flex items-center justify-between w-full">
                            <div className="text-sm text-gray-800">Timer Text</div>
                            <div className="text-sm text-gray-500">
                              Use the {'{{time}}'} tag where you want the time left shown.
                            </div>
                          </div>
                          <Input.TextArea
                            placeholder="Timer Text"
                            className="mt-1 border-gray-400"
                            value={tempFunnel.timerText || 'Your order is reserved for {{time}}'}
                            onChange={e => setTempFunnel({ ...tempFunnel, timerText: e.target.value })}
                            autoSize={{ minRows: 2, maxRows: 3 }}
                          />
                        </div>
                      </div>

                      <div className="flex mt-2">
                        <div className="w-1/2 pr-1">
                          <InputColorPicker
                            className="mt-2"
                            label="Timer Background Color"
                            colors={[
                              '#FF6900',
                              '#FCB900',
                              '#7BDCB5',
                              '#00D084',
                              '#8ED1FC',
                              '#0693E3',
                              '#ABB8C3',
                              '#EB144C',
                              '#F78DA7',
                              '#9900EF',
                              '#000000',
                              '#FFFFFF',
                              '#FFF5D2',
                            ]}
                            hex={tempFunnel.timerBgColor || '#FFF5D2'}
                            onChange={color => handleSaveTimerBgColorChange(color.hex)}
                          />
                        </div>
                        <div className="w-1/2 pl-1">
                          <InputColorPicker
                            className="mt-2"
                            label="Timer Text Color"
                            colors={[
                              '#FF6900',
                              '#FCB900',
                              '#7BDCB5',
                              '#00D084',
                              '#8ED1FC',
                              '#0693E3',
                              '#ABB8C3',
                              '#EB144C',
                              '#F78DA7',
                              '#9900EF',
                              '#000000',
                              '#FFFFFF',
                            ]}
                            hex={tempFunnel.timerTextColor || '#000000'}
                            onChange={color => handleSaveTimerTextColorChange(color.hex)}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(FunnelSettings)
