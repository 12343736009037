import React, { useState } from 'react'
import { TwitterPicker } from 'react-color'

const CustomColorPicker = ({ label, value, onChange }) => {
  const [showPicker, setShowPicker] = useState(false)

  return (
    <div>
      <div className="flex items-center mb-4">
        <div className=" flex-grow text-xs text-gray-800 font-semibold uppercase">{label}</div>
        <div className="flex-shrink ml-2">
          <div
            className="w-8 h-8 rounded cursor-pointer border border-gray-400"
            style={{ backgroundColor: value }}
            onClick={() => setShowPicker(true)}
          />
        </div>
      </div>
      {showPicker ? (
        <div>
          <TwitterPicker
            triangle="hide"
            width="190px"
            colors={[
              '#FF6900',
              '#FCB900',
              '#7BDCB5',
              '#00D084',
              '#8ED1FC',
              '#0693E3',
              '#ABB8C3',
              '#EB144C',
              '#F78DA7',
              '#9900EF',
              '#000000',
              '#FFFFFF',
            ]}
            color={value}
            onChange={color => onChange(color.hex)}
          />
          <div
            className="p-2 rounded text-base text-white bg-gray-800 text-center cursor-pointer mt-2 hover:bg-gray-600"
            onClick={() => setShowPicker(false)}>
            Done
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CustomColorPicker
