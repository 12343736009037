import React from 'react'

import TotalSales from 'components/charts/TotalSales'
import TotalOrders from 'components/charts/TotalOrders'
import TotalVisitors from 'components/charts/TotalVisitors'
import TotalUpsellSales from 'components/charts/TotalUpsellSales'
import AverageOrderValue from 'components/charts/AverageOrderValue'
// import ConversionRates from 'components/charts/ConversionRates'

const ChartGrid = ({ startDate, endDate }) => {
  return (
    <>
      <div className="flex items-start flex-wrap lg:flex-no-wrap justify-between mt-4">
        <div className="w-full lg:w-1/3">
          <TotalSales startDate={startDate} endDate={endDate} />
        </div>
        <div className="w-full mt-6 lg:w-1/3 lg:mx-3 lg:mt-0">
          <TotalUpsellSales startDate={startDate} endDate={endDate} />
        </div>
        <div className="w-full mt-6 lg:w-1/3 lg:mt-0">
          <AverageOrderValue startDate={startDate} endDate={endDate} />
        </div>
      </div>
      <div className="flex items-start flex-wrap lg:flex-no-wrap justify-between mt-4">
        <div className="w-full lg:w-1/2">
          <TotalVisitors startDate={startDate} endDate={endDate} />
        </div>
        <div className="w-full mt-6 lg:w-1/2 lg:mx-3 lg:mt-0">
          <TotalOrders startDate={startDate} endDate={endDate} />
        </div>
        {/* <div className="w-full mt-6 lg:w-1/3 lg:mt-0"> */}
        {/* 	<ConversionRates startDate={startDate} endDate={endDate} /> */}
        {/* </div> */}
      </div>
    </>
  )
}

export default ChartGrid
