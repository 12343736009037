import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import SmsAutomationsPage from 'pages/dashboard/sms-automations/SmsAutomationsPage'
import SmsAutomationPage from 'pages/dashboard/sms-automations/SmsAutomationPage'

const SmsAutomationsRouter = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={SmsAutomationsPage} />
      <Route path={`${match.url}/:smsAutomationId`} component={SmsAutomationPage} />
    </Switch>
  )
}

export default withRouter(SmsAutomationsRouter)
