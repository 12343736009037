import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Switch, message } from 'antd'

const SHOP = gql`
  query shop {
    shop {
      id
      enabled
    }
  }
`

const ENABLE_SHOP = gql`
  mutation enableShop($enabled: Boolean!) {
    enableShop(enabled: $enabled) {
      id
      enabled
    }
  }
`

const EnableShopForm = () => {
  const { loading, data } = useQuery(SHOP)

  const [enableShop] = useMutation(ENABLE_SHOP, {
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleUpdate = enabled => {
    enableShop({
      variables: {
        enabled,
      },
    })
  }

  if (loading) {
    return <div className="text-sm text-gray-600">Loading...</div>
  }

  return (
    <div className="flex items-center">
      <div className="">
        <Switch checked={data.shop.enabled} onChange={checked => handleUpdate(checked)} />
      </div>
      <div className="text-sm text-gray-500 ml-3">
        By disabling Better Cart, all checkouts will immediately start using the default Shopify Checkout
      </div>
    </div>
  )
}

export default EnableShopForm
