import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { SHOP_SETTINGS, UPDATE_SHOP } from './GraphQL'
import CheckoutStylePreview from 'components/CheckoutStylePreview'
import FloatingLabelInput from 'components/FloatingLabelInput'
import InputColorPicker from 'components/InputColorPicker'
import ShopAssetUploaderDragable from 'components/ShopAssetUploaderDragable'
import { Icon } from 'antd'
import useDebounce from 'components/useDebounce'
import OnboardingNavigation from 'components/OnboardingNavigation'

const Uploader = ({ title, src, loading }) => {
  if (src) {
    return (
      <div className="relative group bg-gray-300">
        <div className="absolute absolute top-0 right-0 bottom-0 left-0 items-center justify-center hidden group-hover:flex">
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-50 z-10" />
          <div className="relative text-white z-20 hover:underline">Change Image</div>
        </div>
        <div className="h-24 bg-cover bg-center w-full" style={{ backgroundImage: `url(${src})` }} />
      </div>
    )
  } else {
    return (
      <div className="p-4 text-center">
        {loading ? (
          <Icon className="text-4xl text-pink-500" type="loading" />
        ) : (
          <Icon className="text-4xl text-pink-500" type="inbox" />
        )}
        <div className="text-sm text-gray-800 mt-1">{title}</div>
        <div className="text-xs text-gray-500 mt-1">Click or drag file to this area to upload</div>
      </div>
    )
  }
}

const ShopStep = () => {
  const [initForm, setInitForm] = useState(false)
  const [localShop, setLocalShop] = useState({})

  const debouncedLocalShop = useDebounce(localShop, 2000)

  const { data } = useQuery(SHOP_SETTINGS)

  const [updateShop] = useMutation(UPDATE_SHOP, {
    onCompleted: data => {
      setLocalShop({
        name: data.shop.name,
        primaryBrandColor: data.shop.primaryBrandColor,
        headerBackground: data.shop.headerBackground,
        logoImage: data.shop.logo,
      })
    },
    onError: () => {},
  })

  useEffect(() => {
    if (data && data.shop && !initForm) {
      setInitForm(true)
      setLocalShop({
        name: data.shop.name,
        primaryBrandColor: data.shop.primaryBrandColor,
        headerBackground: data.shop.headerBackground,
        logo: data.shop.logo,
      })
    }
  }, [data, setLocalShop, initForm])

  useEffect(() => {
    if (JSON.stringify(debouncedLocalShop) !== JSON.stringify({})) {
      updateShop({
        update: () => {},

        variables: {
          shop: debouncedLocalShop,
        },
      })
    }
  }, [debouncedLocalShop, updateShop])

  const handleCheckoutBgSrcChange = src => {
    setLocalShop({ ...localShop, headerBackground: src })
  }

  const handleCheckoutLogoSrcChange = src => {
    setLocalShop({ ...localShop, logo: src })
  }

  return (
    <div className="">
      <div className="">
        <div className="text-2xl text-gray-800 font-semibold text-center">Customize Your Checkout</div>
        <div className="text-base text-gray-500 text-center mt-2 max-w-xl mx-auto">
          Customize your checkout to match your shops branding.
        </div>
      </div>

      <div className="flex items-center mt-6">
        <div className="w-1/2">
          <div className="w-3/5 mx-auto">
            <div>
              <FloatingLabelInput
                value={localShop.name}
                label="Shop Title"
                onChange={e => setLocalShop({ ...localShop, name: e.target.value })}
              />
            </div>
            <div className="relative mt-4 z-50">
              <InputColorPicker
                className="mt-2"
                label="Brand Color"
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                  '#000000',
                  '#FFFFFF',
                ]}
                hex={localShop.primaryBrandColor}
                onChange={color => setLocalShop({ ...localShop, primaryBrandColor: color.hex })}
              />
            </div>

            <div className="mt-4">
              <ShopAssetUploaderDragable onPublicUrl={handleCheckoutBgSrcChange}>
                <Uploader title="Checkout Background" src={localShop.headerBackground} />
              </ShopAssetUploaderDragable>
            </div>

            <div className="mt-4">
              <ShopAssetUploaderDragable onPublicUrl={handleCheckoutLogoSrcChange}>
                <Uploader title="Checkout Logo" src={localShop.logo} />
              </ShopAssetUploaderDragable>
            </div>
          </div>
        </div>

        <div className="w-1/2 flex justify-center">
          <CheckoutStylePreview
            shopTitle={localShop.name}
            brandColor={localShop.primaryBrandColor}
            backgroundImage={localShop.headerBackground}
            logoImage={localShop.logo}
          />
        </div>
      </div>

      <div className="mt-12">
        <OnboardingNavigation />
      </div>
    </div>
  )
}

export default ShopStep
