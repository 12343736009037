import React, { useState } from 'react'
import { Modal, Button, message } from 'antd'
import ShippingZoneCountries from 'components/ShippingZoneCountries/ShippingZoneCountries'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { useMutation } from '@apollo/client'
import { UPDATE_SHIPPING_ZONE, SHIPPING_ZONES } from './GraphQL'

const cleanKeys = obj => {
  var key,
    keys = Object.keys(obj)
  var n = keys.length
  var newobj = {}
  while (n--) {
    key = keys[n]
    newobj[key.toUpperCase()] = obj[key]
  }
  return newobj
}

const UpdateShippingZoneModal = ({ showing, setShowing, zone }) => {
  const [selected, setSelected] = useState(cleanKeys(zone.countries))
  const [name, setName] = useState(zone.name)

  const [updateZone, { loading }] = useMutation(UPDATE_SHIPPING_ZONE, {
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
    onCompleted: () => {
      message.success('Successfully updated shipping zone!')
      setShowing(false)
    },
    refetchQueries: [
      {
        query: SHIPPING_ZONES,
      },
    ],
  })

  const handleUpdateShippingZone = () => {
    updateZone({
      variables: {
        id: zone.id,
        shippingZone: {
          name,
          countries: selected,
        },
      },
    })
  }

  return (
    <Modal
      title="Update Zone"
      visible={showing}
      onCancel={() => setShowing(false)}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setShowing(false)
          }}>
          Cancel
        </Button>,
        <Button key="create" type="primary" loading={loading} onClick={handleUpdateShippingZone}>
          Update
        </Button>,
      ]}>
      <div>
        <FloatingLabelInput label="Zone Name" value={name} onChange={e => setName(e.target.value)} />
        <div className="mt-1 ml-1 text-sm text-gray-400">Customers will not see this</div>
        <div className="mt-4">
          <ShippingZoneCountries selected={selected} setSelected={setSelected} />
        </div>
      </div>
    </Modal>
  )
}

export default UpdateShippingZoneModal
