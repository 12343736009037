import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import Script from 'react-load-script'

export const CREATE_PAYMENT_METHOD = gql`
  mutation BillingCreatePaymentMethod($token: String!) {
    billingCreatePaymentMethod(token: $token) {
      id
      brand
      last4
      expMonth
      expYear
      primary
    }
  }
`

const CreditCardForm = ({ createTitle, loadingTitle, onCardCreated }) => {
  const [stripeLoaded, setStripeLoaded] = useState(false)
  const [stripeCallbacks, setStripeCallbacks] = useState({ create: () => {}, clear: () => {} })
  const [stripeTokenLoading, setStripeTokenLoading] = useState(false)
  const [stripeErrors, setStripeErrors] = useState(false)

  const [createPaymentMethod, { loading: createLoading, error: createError }] = useMutation(CREATE_PAYMENT_METHOD, {
    onCompleted: data => {
      stripeCallbacks.clear()
      onCardCreated(data)
    },
  })

  const createCreditCard = async () => {
    setStripeTokenLoading(true)
    const result = await stripeCallbacks.create()
    setStripeTokenLoading(false)

    if (result.error) {
      setStripeErrors(result.error.message)
    } else {
      createPaymentMethod({
        variables: {
          token: result.token.id,
        },
      })
      setStripeErrors(false)
    }
  }

  useEffect(() => {
    if (stripeLoaded) {
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
      const cardElement = stripe.elements().create('card', {
        style: {
          base: {
            fontSize: '13pt',
          },
        },
      })
      cardElement.mount('#stripe-card')

      setStripeCallbacks({
        create: () => stripe.createToken(cardElement),
        clear: () => cardElement.clear(),
      })
    }
  }, [stripeLoaded, setStripeCallbacks])

  return (
    <div className="">
      <Script url={process.env.REACT_APP_STRIPE_URL} onLoad={() => setStripeLoaded(true)} />

      <div id="stripe-card" className={`${stripeTokenLoading ? 'hidden' : ''}`} />
      {stripeErrors ? <div className="text-sm text-red-400 mt-2">{stripeErrors}</div> : null}
      {createError
        ? createError.graphQLErrors.map(({ message }) => {
            return <div className="text-sm text-red-400 mt-2">{message}</div>
          })
        : null}

      {!stripeTokenLoading ? (
        createLoading ? (
          <div className="text-white uppercase text-center p-2 bg-gray-400 rounded select-none mt-2 hover:bg-gray-300">
            {loadingTitle || 'Adding Card...'}
          </div>
        ) : (
          <div
            onClick={createCreditCard}
            className="text-white uppercase text-center p-2 bg-gray-800 cursor-pointer rounded select-none mt-2 hover:bg-gray-700">
            {createTitle || 'Add Card'}
          </div>
        )
      ) : null}
    </div>
  )
}

export default CreditCardForm
