import React from 'react'
import SquareForm from 'components/PaymentGateways/forms/SquareForm'
import NMIForm from 'components/PaymentGateways/forms/NMIForm'
import StripeForm from 'components/PaymentGateways/forms/StripeForm'
import PaypalCheckoutForm from 'components/PaymentGateways/forms/PaypalCheckoutForm'
import AuthNetForm from 'components/PaymentGateways/forms/AuthNetForm'
import BraintreeForm from 'components/PaymentGateways/forms/BraintreeForm'

const PaymentGatewayForm = ({ gateway, shopId, onClose }) => {
  switch (gateway.gatewayType) {
    case 'SQUARE_PAYMENTS':
      return <SquareForm gateway={gateway} shopId={shopId} />
    case 'NMI':
      return <NMIForm gateway={gateway} shopId={shopId} onClose={onClose} />
    case 'STRIPE':
      return <StripeForm gateway={gateway} shopId={shopId} />
    case 'PAYPAL_CHECKOUT':
      return <PaypalCheckoutForm gateway={gateway} shopId={shopId} onClose={onClose} />
    case 'AUTHNET':
      return <AuthNetForm gateway={gateway} shopId={shopId} onClose={onClose} />
    case 'BRAINTREE':
      return <BraintreeForm gateway={gateway} shopId={shopId} onClose={onClose} />
    default:
      return null
  }
}

export default PaymentGatewayForm
