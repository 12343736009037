import gql from 'graphql-tag'

export const TRACKING_PIXELS = gql`
  query trackingPixels {
    trackingPixels {
      id
      name
      platform
      enabled
      data
    }
  }
`

export const CREATE_TRACKING_PIXEL = gql`
  mutation createTrackingPixel($trackingPixel: CreateTrackingPixelInput!) {
    createTrackingPixel(trackingPixel: $trackingPixel) {
      id
      name
      platform
      enabled
      data
    }
  }
`
