import React from 'react'
import FunnelStepCard from './FunnelStepCard'

const DownsellCard = ({ index, funnel, parentFunnelStep, funnelStep, isUpsell, startDate, endDate }) => {
  return (
    <div className="clearfix">
      {!parentFunnelStep.enabled || !funnelStep.enabled ? (
        <div className="float-right w-11/12 text-base text-yellow-700 uppercase">Offer Disabled</div>
      ) : null}
      <div
        className={`w-11/12 rounded shadow-xl border-2 overflow-hidden float-right ${
          !parentFunnelStep.enabled || !funnelStep.enabled ? 'border-yellow-400 bg-yellow-100' : 'border-gray-200'
        }`}>
        <FunnelStepCard
          index={index}
          funnel={funnel}
          funnelStep={funnelStep}
          isUpsell={isUpsell}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  )
}

export default DownsellCard
