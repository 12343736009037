import React from 'react'
import { Switch, Route, withRouter, useLocation } from 'react-router-dom'

import DashboardLayout from 'layouts/DashboardLayout'

import DashboardPage from 'pages/dashboard/DashboardPage/DashboardPage'
import FunnelsRouter from 'routers/FunnelsRouter'
import SmsAutomationsRouter from 'routers/SmsAutomationsRouter'
import SettingsPage from 'pages/dashboard/SettingsPage/SettingsPage'
import ShippingZonesRouter from 'routers/ShippingZonesRouter'
import BillingPage from 'pages/dashboard/BillingPage/BillingPage'
import OnboardingContent from 'pages/dashboard/onboarding/OnboardingContent'

const usePageViews = () => {
  let location = useLocation()

  React.useEffect(() => {
    window.analytics.page(location.pathname)
  }, [location])
}

const DashboardRouter = ({ match }) => {
  usePageViews()

  return (
    <DashboardLayout>
      <Switch>
        <Route exact path={`${match.url}`} component={DashboardPage} />
        <Route path={`${match.url}/funnels`} component={FunnelsRouter} />
        <Route path={`${match.url}/sms-automations`} component={SmsAutomationsRouter} />
        <Route path={`${match.url}/settings`} component={SettingsPage} />
        <Route path={`${match.url}/shipping-zones`} component={ShippingZonesRouter} />
        <Route path={`${match.url}/billing`} component={BillingPage} />

        <Route path={`${match.url}/onboarding/:step`} component={OnboardingContent} />
      </Switch>
    </DashboardLayout>
  )
}

export default withRouter(DashboardRouter)
