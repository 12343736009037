import React, { useEffect, useState } from 'react'
import { CREATE_SHOP_OAUTH_SESSION } from '../GraphQL'
import { useMutation } from '@apollo/client'

const SquareForm = ({ gateway, shopId }) => {
  const [nonce, setNonce] = useState()
  const [createShopOAuthSession] = useMutation(CREATE_SHOP_OAUTH_SESSION, {
    onCompleted: data => {
      setNonce(data.createShopOAuthSession.nonce)
    },
  })

  useEffect(() => {
    createShopOAuthSession()
  }, [createShopOAuthSession])

  return (
    <div className="flex justify-center flex-col">
      <div className="flex-1">
        <img className="m-auto h-12 w-auto" src={gateway.logo} alt={gateway.slug} />
      </div>
      <div className="flex-1 my-4">
        <div className="text-sm text-gray-500 text-center">
          Square Payments is a simple one click integration. Click the connect button below to login to your Square
          account.
        </div>
      </div>

      {nonce ? (
        <div className="flex-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_SQUARE_PAYMENTS_OAUTH_URL}?client_id=${gateway.appPublicCreds.appId}&scope=${gateway.appPublicCreds.scope}&state=${nonce}`}>
            <div className="p-2 text-sm text-white text-center bg-gray-800 cursor-pointer rounded hover:bg-gray-700">
              Connect
            </div>
          </a>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
}

export default SquareForm
