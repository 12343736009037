import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import FloatingLabelInput from 'components/FloatingLabelInput'
import InputColorPicker from 'components/InputColorPicker'
import ShopAssetUploaderDragable from 'components/ShopAssetUploaderDragable'
import { Icon, Switch } from 'antd'
import CheckoutStylePreview from 'components/CheckoutStylePreview'
import { useDebounce } from 'use-debounce'
import FontPicker from 'font-picker-react'

const SHOP_SETTINGS = gql`
  query shop {
    shop {
      id
      name
      logo
      headerBackground
      trustBadge
      primaryBrandColor
      skipCart
      autoRefund
      fontFamily
    }
  }
`

const UPDATE_SHOP = gql`
  mutation updateShop($shop: UpdateShopInput!) {
    updateShop(shop: $shop) {
      id
      name
      logo
      headerBackground
      trustBadge
      primaryBrandColor
      skipCart
      autoRefund
      fontFamily
    }
  }
`

const Uploader = ({ title, src, loading, handleRemove }) => {
  if (src) {
    return (
      <div className="relative group bg-gray-300">
        <div className="absolute absolute top-0 right-0 bottom-0 left-0 items-center justify-center hidden group-hover:flex flex-wrap">
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-50 z-10" />
          <div className="relative text-white z-20 hover:underline w-full">Change Image</div>
          {handleRemove ? (
            <div
              onClick={e => {
                handleRemove(e)
                e.stopPropagation()
              }}
              className="relative text-white z-50 hover:underline w-full">
              Remove Image
            </div>
          ) : null}
        </div>
        <div className="h-24 bg-cover bg-center w-full" style={{ backgroundImage: `url(${src})` }} />
      </div>
    )
  } else {
    return (
      <div className="p-4 text-center">
        {loading ? (
          <Icon className="text-4xl text-pink-500" type="loading" />
        ) : (
          <Icon className="text-4xl text-pink-500" type="inbox" />
        )}
        <div className="text-sm text-gray-800 mt-1">{title}</div>
        <div className="text-xs text-gray-500 mt-1">Click or drag file to this area to upload</div>
      </div>
    )
  }
}

const ShopSettingsForm = () => {
  const [name, setName] = useState('')
  const [customFont, setCustomFont] = useState(false)
  const [debouncedName] = useDebounce(name, 1000)

  const { loading, data } = useQuery(SHOP_SETTINGS)

  const [updateShop] = useMutation(UPDATE_SHOP, {
    refetchQueries: [
      {
        query: SHOP_SETTINGS,
      },
    ],
    onError: () => {},
  })

  const handleCheckoutBgSrcChange = src => {
    updateShop({
      variables: {
        shop: {
          headerBackground: src,
        },
      },
    })
  }

  const handleCheckoutBgSrcRemove = e => {
    e.stopPropagation()

    updateShop({
      variables: {
        shop: {
          headerBackground: null,
        },
      },
    })
  }

  const handleCheckoutLogoSrcChange = src => {
    updateShop({
      variables: {
        shop: {
          logo: src,
        },
      },
    })
  }

  const handleCheckoutLogoSrcRemove = e => {
    e.stopPropagation()

    updateShop({
      variables: {
        shop: {
          logo: null,
        },
      },
    })
  }

  const handleCheckoutTrustBadgeSrcChange = src => {
    updateShop({
      variables: {
        shop: {
          trustBadge: src,
        },
      },
    })
  }

  const handleCheckoutTrustBadgeSrcRemove = e => {
    e.stopPropagation()

    updateShop({
      variables: {
        shop: {
          trustBadge: null,
        },
      },
    })
  }

  const handlePrimaryColorChange = primaryBrandColor => {
    updateShop({
      variables: {
        shop: {
          primaryBrandColor,
        },
      },
    })
  }

  const handleSkipCartChange = skipCart => {
    updateShop({
      variables: {
        shop: {
          skipCart,
        },
      },
    })
  }

  const handleAutoRefundChange = autoRefund => {
    updateShop({
      variables: {
        shop: {
          autoRefund,
        },
      },
    })
  }

  const handleNewFontFamily = fontFamily => {
    updateShop({
      variables: {
        shop: {
          fontFamily,
        },
      },
    })
  }

  useEffect(() => {
    if (data && data.shop) {
      setName(data.shop.name)
      setCustomFont(data.shop.fontFamily ? true : false)
    }
  }, [data, setName])

  useEffect(() => {
    if (debouncedName && debouncedName.length > 1 && debouncedName !== data.shop.name) {
      updateShop({
        variables: {
          shop: {
            name: debouncedName,
          },
        },
      })
    }
  }, [debouncedName, updateShop, data])

  if (loading) {
    return <div className="text-sm text-gray-600">Loading...</div>
  }

  return (
    <div className="flex w-full p-4">
      <div className="w-1/5 mt-6">
        <FloatingLabelInput value={name} label="Shop Title" onChange={e => setName(e.target.value)} />

        <div className="mt-8">
          <ShopAssetUploaderDragable onPublicUrl={handleCheckoutBgSrcChange}>
            <Uploader
              title="Checkout Background"
              src={data.shop.headerBackground}
              handleRemove={e => handleCheckoutBgSrcRemove(e)}
            />
          </ShopAssetUploaderDragable>
        </div>

        <div className="mt-8">
          <ShopAssetUploaderDragable onPublicUrl={handleCheckoutLogoSrcChange}>
            <Uploader title="Checkout Logo" src={data.shop.logo} handleRemove={e => handleCheckoutLogoSrcRemove(e)} />
          </ShopAssetUploaderDragable>
        </div>

        <div className="mt-8">
          <ShopAssetUploaderDragable onPublicUrl={handleCheckoutTrustBadgeSrcChange}>
            <Uploader
              title="Checkout Trust Badge"
              src={data.shop.trustBadge}
              handleRemove={handleCheckoutTrustBadgeSrcRemove}
            />
          </ShopAssetUploaderDragable>
        </div>

        <div className="relative mt-8 z-50">
          <InputColorPicker
            className="mt-2"
            label="Brand Color"
            colors={[
              '#FF6900',
              '#FCB900',
              '#7BDCB5',
              '#00D084',
              '#8ED1FC',
              '#0693E3',
              '#ABB8C3',
              '#EB144C',
              '#F78DA7',
              '#9900EF',
              '#000000',
              '#FFFFFF',
            ]}
            hex={data.shop.primaryBrandColor}
            onChange={color => handlePrimaryColorChange(color.hex)}
          />
        </div>

        <div className="flex items-center justify-between mt-8">
          <div className="text-base text-gray-700">Skip Cart</div>
          <Switch className="ml-2" checked={data.shop.skipCart} onChange={checked => handleSkipCartChange(checked)} />
        </div>

        <div className="flex items-center justify-between mt-8">
          <div className="text-base text-gray-700">Auto Refund Shopify Order Transactions</div>
          <Switch
            className="ml-2"
            checked={data.shop.autoRefund}
            onChange={checked => handleAutoRefundChange(checked)}
          />
        </div>

        <div className="mt-8">
          <div className="flex items-center justify-between">
            <div className="text-base text-gray-700">Custom Font Family</div>
            <Switch
              className="ml-2"
              checked={customFont}
              onChange={checked => {
                if (!checked) {
                  handleNewFontFamily(null)
                } else {
                  handleNewFontFamily('Roboto')
                }
                setCustomFont(checked)
              }}
            />
          </div>
          {customFont ? (
            <div className="mt-3">
              <FontPicker
                apiKey={process.env.REACT_APP_GOOGLE_FONTS_API_KEY}
                activeFontFamily={data.shop.fontFamily ? data.shop.fontFamily : 'Roboto'}
                onChange={font => handleNewFontFamily(font.family)}
                limit={500}
              />
              <div className="text-xs text-gray-500 mt-1 text-right">powered by Google Fonts</div>
            </div>
          ) : null}
        </div>
      </div>

      <div className={`w-4/5 ml-8 lg:ml-24 ${customFont ? 'apply-font' : ''}`}>
        <CheckoutStylePreview
          shopTitle={data.shop.name}
          brandColor={data.shop.primaryBrandColor}
          backgroundImage={data.shop.headerBackground}
          logoImage={data.shop.logo}
          trustBadge={data.shop.trustBadge}
        />
      </div>
    </div>
  )
}

export default ShopSettingsForm
