import React, { useState, useEffect, useCallback } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ContentWrapper from 'components/ContentWrapper'
import FunnelActions from './FunnelActions'
import CurrencyFormat from 'react-currency-format'
import RateFormat from 'components/RateFormat'
import NumberFormat from 'components/NumberFormat'
import { Switch, Modal, Input, Select, message, Tooltip } from 'antd'
import { useQuery, useMutation, gql } from '@apollo/client'
import { SHOP, FUNNELS, FUNNEL_STATS, CREATE_FUNNEL, UPDATE_FUNNEL } from './GraphQL'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DateRangeButton from 'components/DateRangeButton'
import moment from 'moment'
import { MdDragHandle } from 'react-icons/md'
import { Provider, OrderableItem } from 'components/orderable-list/OrderableList'
import update from 'immutability-helper'
import LoadingRow from './LoadingRow'

const { Option } = Select

const FunnelRow = withRouter(({ funnel, updateFunnel, startDate, endDate, match }) => {
  const { data } = useQuery(FUNNEL_STATS, {
    fetchPolicy: 'network-only',
    variables: {
      id: funnel.id,
      startDate,
      endDate,
    },
  })

  const handleStatusChange = value => {
    updateFunnel({
      variables: { id: funnel.id, funnel: { enabled: value } },
    })
  }

  return (
    <>
      <td className="px-4 py-2 text-center text-gray-800">
        <MdDragHandle size="2em" className="m-auto text-gray-400 cursor-move fill-current hover:text-gray-500" />
      </td>
      <td className="px-4 py-2 text-center text-gray-800">
        <Link to={`${match.url}/${funnel.id}`} className="hover:underline hover:text-gray-800">
          {funnel.name}
        </Link>
      </td>
      <td className="px-4 py-2 text-center text-gray-800">
        <CopyToClipboard text={funnel.tag} onCopy={() => message.success('Tag copied to your clipboard!')}>
          <Tooltip title={`Copy: ${funnel.tag}`}>
            <div
              style={{ maxWidth: '125px' }}
              className="p-2 overflow-hidden text-xs leading-none text-center text-gray-800 truncate bg-gray-400 rounded-full cursor-pointer ">
              {funnel.tag}
            </div>
          </Tooltip>
        </CopyToClipboard>
      </td>
      <td className="px-4 py-2 text-center text-gray-800">
        {data && data.funnelStats ? (
          <NumberFormat value={data.funnelStats.views} />
        ) : (
          <div className="w-5 h-2 mx-auto bg-gray-400 animate-pulse"></div>
        )}
      </td>

      <td className="px-4 py-2 text-center text-gray-800">
        {data && data.funnelStats ? (
          <NumberFormat value={data.funnelStats.orders} />
        ) : (
          <div className="w-5 h-2 mx-auto bg-gray-400 animate-pulse"></div>
        )}
      </td>

      <td className="px-4 py-2 text-center text-gray-800">
        {data && data.funnelStats ? (
          <RateFormat value={data.funnelStats.conversionRate} />
        ) : (
          <div className="w-6 h-2 mx-auto bg-gray-400 animate-pulse"></div>
        )}
      </td>

      <td className="px-4 py-2 text-center text-gray-800">
        {data && data.funnelStats ? (
          <RateFormat value={data.funnelStats.offerConversionRate} />
        ) : (
          <div className="w-6 h-2 mx-auto bg-gray-400 animate-pulse"></div>
        )}
      </td>

      <td className="px-4 py-2 text-center text-gray-800">
        {data && data.funnelStats ? (
          <CurrencyFormat
            value={data.funnelStats.bumpRevenue > 0 ? data.funnelStats.bumpRevenue : 0}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        ) : (
          <div className="w-10 h-2 mx-auto bg-gray-400 animate-pulse"></div>
        )}
      </td>

      <td className="px-4 py-2 text-center text-gray-800">
        {data && data.funnelStats ? (
          <CurrencyFormat
            value={data.funnelStats.revenue > 0 ? data.funnelStats.revenue : 0}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        ) : (
          <div className="w-10 h-2 mx-auto bg-gray-400 animate-pulse"></div>
        )}
      </td>

      <td className="px-4 py-2 text-center text-gray-800">
        <Switch checked={funnel.enabled} onChange={value => handleStatusChange(value)} />
      </td>

      <td className="px-4 py-2 text-center text-gray-800">
        <FunnelActions funnel={funnel} />
      </td>
    </>
  )
})

const FunnelsPage = ({ match }) => {
  const [tableRows, setTableRows] = useState([])
  const [showNewFunnelModal, setShowNewFunnelModal] = useState(false)
  const [newFunnel, setNewFunnel] = useState({
    name: '',
    tag: '',
    phoneOptional: false,
    shopPaymentGatewayId: undefined,
    paypalShopPaymentGatewayId: undefined,
    checkoutTemplate: 'SHOPIFY',
    showSummary: false,
  })

  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  })

  const { loading, error, data } = useQuery(FUNNELS)

  const { loading: shopLoading, error: shopError, data: shopData } = useQuery(SHOP)

  const [createFunnel] = useMutation(CREATE_FUNNEL, {
    refetchQueries: [
      {
        query: FUNNELS,
      },
    ],
    onCompleted: () => {
      message.success('Funnel Successfully Created')
      setShowNewFunnelModal(false)
      setNewFunnel({
        name: '',
        tag: '',
        phoneOptional: false,
        shopPaymentGatewayId: undefined,
        paypalShopPaymentGatewayId: undefined,
        checkoutTemplate: 'SHOPIFY',
        showSummary: false,
      })
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const [updateFunnel] = useMutation(UPDATE_FUNNEL, {
    update: (cache, { data: { updateFunnel } }) => {
      cache.modify({
        fields: {
          funnels: () => {
            return cache.writeFragment({
              id: updateFunnel.id,
              data: {
                priority: updateFunnel.priority,
              },
              fragment: gql`
                fragment UpdateFunnel on Funnel {
                  priority
                }
              `,
            })
          },
        },
      })
    },
    onCompleted: () => {
      message.success('Funnel Successfully Updated')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  useEffect(() => {
    if (data && data.funnels) {
      setTableRows(data.funnels)
    }
  }, [data])

  const handleDateRangeChange = dateRange => {
    setDateRange(dateRange)
  }

  const handleNewFunnel = () => {
    createFunnel({
      variables: {
        funnel: {
          name: newFunnel.name,
          tag: newFunnel.tag,
          phoneOptional: newFunnel.phoneOptional,
          shopPaymentGatewayId: newFunnel.shopPaymentGatewayId,
          paypalShopPaymentGatewayId: newFunnel.paypalShopPaymentGatewayId,
          checkoutTemplate: newFunnel.checkoutTemplate,
          showSummary: newFunnel.showSummary,
        },
      },
    })
  }

  const handleReOrder = (index, data) => {
    updateFunnel({
      variables: { id: data.id, funnel: { priority: index + 1 } },
    })
  }

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = tableRows[dragIndex]
      setTableRows(
        update(tableRows, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        })
      )
    },
    [tableRows]
  )

  return (
    <div className="w-full">
      <div className="m-4">
        <div className="flex">
          <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Funnels</div>
          <div className="flex-shrink mr-4">
            <DateRangeButton dateRange={dateRange} onChange={handleDateRangeChange} />
          </div>
          <div className="flex-shrink">
            <div
              className="w-auto p-2 mb-2 text-center text-gray-300 bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
              onClick={() => setShowNewFunnelModal(true)}>
              New Funnel
            </div>
          </div>
        </div>
        <ContentWrapper>
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-800">
                <td className="px-4 py-2 text-center text-gray-300">Priority</td>
                <td className="px-4 py-2 text-center text-gray-300">Name</td>
                <td className="px-4 py-2 text-center text-gray-300">Tag</td>
                <td className="px-4 py-2 text-center text-gray-300">Views</td>
                <td className="px-4 py-2 text-center text-gray-300">Orders</td>
                <td className="px-4 py-2 text-center text-gray-300">Conversion Rate</td>
                <td className="px-4 py-2 text-center text-gray-300">Offer Conversion Rate</td>
                <td className="px-4 py-2 text-center text-gray-300">Bump Revenue</td>
                <td className="px-4 py-2 text-center text-gray-300">Revenue</td>
                <td className="px-4 py-2 text-center text-gray-300">Enabled</td>
                <td className="px-4 py-2 text-center text-gray-300">Actions</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <>
                  <LoadingRow />
                  <LoadingRow />
                  <LoadingRow />
                </>
              ) : null}
              <Provider>
                {tableRows.length > 0
                  ? tableRows.map((funnel, index) => {
                      return (
                        <OrderableItem
                          key={funnel.id}
                          itemType="tr"
                          index={index}
                          moveItem={moveItem}
                          dropItem={handleReOrder}
                          data={funnel}>
                          <FunnelRow
                            funnel={funnel}
                            updateFunnel={updateFunnel}
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                          />
                        </OrderableItem>
                      )
                    })
                  : null}
              </Provider>
            </tbody>
          </table>
        </ContentWrapper>

        <Modal
          title="Add New Funnel"
          okText="Create"
          onOk={handleNewFunnel}
          onCancel={() => {
            setShowNewFunnelModal(false)
            setNewFunnel({
              name: '',
              tag: '',
              phoneOptional: false,
              shopPaymentGatewayId: undefined,
              paypalShopPaymentGatewayId: undefined,
              checkoutTemplate: 'SHOPIFY',
              showSummary: false,
            })
          }}
          visible={showNewFunnelModal}>
          <Input
            className="mb-4"
            placeholder="Funnel Name"
            value={newFunnel.name}
            onChange={e => setNewFunnel({ ...newFunnel, name: e.target.value })}
          />
          <Input
            placeholder="Funnel Product Tag"
            value={newFunnel.tag}
            onChange={e => setNewFunnel({ ...newFunnel, tag: e.target.value })}
          />

          <div className="mt-4">
            {error || shopError ? (
              <div>Whoops, errors loading your gateways</div>
            ) : loading || shopLoading ? (
              <div className="text-sm text-gray-500">Loading Gateways...</div>
            ) : (
              <Select
                style={{ width: '100%' }}
                placeholder="Select A Gateway"
                value={newFunnel.shopPaymentGatewayId}
                onChange={val => setNewFunnel({ ...newFunnel, shopPaymentGatewayId: val })}>
                {shopData.shop.shopPaymentGateways
                  .filter(g => g.paymentGateway.slug !== 'paypal-checkout')
                  .map(gateway => {
                    return (
                      <Option value={gateway.id} key={gateway.id}>
                        {gateway.paymentGateway.name} {gateway.nickname ? `- ${gateway.nickname}` : null}
                      </Option>
                    )
                  })}
              </Select>
            )}
          </div>
          <div className="mt-4">
            {error ? (
              <div>Whoops, errors loading your gateways</div>
            ) : loading || shopLoading ? (
              <div className="text-sm text-gray-500">Loading Gateways...</div>
            ) : (
              <Select
                style={{ width: '100%' }}
                placeholder="Select A Paypal Gateway"
                value={newFunnel.paypalShopPaymentGatewayId}
                onChange={val => setNewFunnel({ ...newFunnel, paypalShopPaymentGatewayId: val })}>
                {shopData.shop.shopPaymentGateways
                  .filter(g => g.paymentGateway.slug === 'paypal-checkout')
                  .map(gateway => {
                    return (
                      <Option value={gateway.id} key={gateway.id}>
                        {gateway.paymentGateway.name} {gateway.nickname ? `- ${gateway.nickname}` : null}
                      </Option>
                    )
                  })}
                <Option value={null} key={'none'}>
                  No PayPal Payments
                </Option>
              </Select>
            )}
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default withRouter(FunnelsPage)
