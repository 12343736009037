import React, { useState } from 'react'
import { Modal, Button, message } from 'antd'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { useMutation } from '@apollo/client'
import { UPDATE_SHIPPING_RATE, SHIPPING_RATES } from './GraphQL'

const UpdateShippingRateModal = ({ showing, setShowing, zoneId, rate }) => {
  const [tempRate, setTempRate] = useState(rate)

  const [hasCondition, setHasCondition] = useState(rate.conditionType !== 'NONE')

  const [updateRate, { loading }] = useMutation(UPDATE_SHIPPING_RATE, {
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
    onCompleted: () => {
      message.success('Successfully created new shipping rate!')
      setShowing(false)
    },
    refetchQueries: [
      {
        query: SHIPPING_RATES,
        variables: {
          shippingZoneId: zoneId,
        },
      },
    ],
  })

  const handleUpdateShippingRate = () => {
    updateRate({
      variables: {
        shippingZoneId: zoneId,
        id: rate.id,
        shippingRate: {
          name: tempRate.name,
          price: tempRate.price === '' ? 0 : parseFloat(tempRate.price),
          conditionType: tempRate.conditionMin === '' && tempRate.conditionMax === '' ? 'NONE' : 'PRICE',
          conditionMin: tempRate.conditionMin === '' ? null : parseFloat(tempRate.conditionMin),
          conditionMax: tempRate.conditionMax === '' ? null : parseFloat(tempRate.conditionMax),
        },
      },
    })
  }

  return (
    <Modal
      title="Update Rate"
      okText="Create"
      onOk={handleUpdateShippingRate}
      onCancel={() => {
        setShowing(false)
      }}
      visible={showing}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setShowing(false)
          }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleUpdateShippingRate}>
          Update
        </Button>,
      ]}>
      <div>
        <div className="flex tems-center">
          <div className="w-1/2 pr-2">
            <FloatingLabelInput
              label="Rate Name"
              value={tempRate.name}
              onChange={e => setTempRate({ ...tempRate, name: e.target.value })}
            />
            <div className="mt-1 ml-1 text-xs text-gray-500">Customers will see this at checkout</div>
          </div>
          <div className="w-1/2 pl-2">
            <FloatingLabelInput
              label="Rate Price"
              value={tempRate.price}
              onChange={e => setTempRate({ ...tempRate, price: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4">
          <div
            className="text-gray-800 underline cursor-pointer select-none hover:text-gray-700"
            onClick={() => {
              if (hasCondition) {
                setTempRate({ ...tempRate, conditionMin: '', conditionMax: '' })
              }
              setHasCondition(c => !c)
            }}>
            {hasCondition ? 'Remove Condition' : 'Add Condition'}
          </div>
        </div>
        {hasCondition ? (
          <div className="mt-4">
            <div className="flex tems-center">
              <div className="w-1/2 pr-2">
                <FloatingLabelInput
                  label="Mininum price"
                  value={tempRate.conditionMin}
                  onChange={e => setTempRate({ ...tempRate, conditionMin: e.target.value })}
                />
              </div>
              <div className="w-1/2 pl-2">
                <FloatingLabelInput
                  label="Maximum price"
                  value={tempRate.conditionMax}
                  onChange={e => setTempRate({ ...tempRate, conditionMax: e.target.value })}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default UpdateShippingRateModal
