import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_SHOP_PAYMENT_GATEWAY, SHOP_PAYMENT_GATEWAYS, DESTROY_SHOP_PAYMENT_GATEWAY } from './GraphQL'
import { FiCheckCircle } from 'react-icons/fi'
import { ReactComponent as SquareLogo } from 'images/square.svg'
import { ReactComponent as StripeLogo } from 'images/stripe.svg'
import { ReactComponent as PayPalLogo } from 'images/paypal.svg'
import { ReactComponent as BraintreeLogo } from 'images/braintree.svg'
import AuthNetLogo from 'images/authnet.png'
import NmiLogo from 'images/nmi.png'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { Switch } from "antd"

const Icon = ({ gatewayType }) => {
  switch (gatewayType) {
    case 'SQUARE_PAYMENTS':
      return <SquareLogo className="h-8" />
    case 'NMI':
      return <img src={NmiLogo} className="h-8" alt="NMI" />
    case 'STRIPE':
      return <StripeLogo className="h-8" />
    case 'PAYPAL_CHECKOUT':
      return <PayPalLogo className="h-8" />
    case 'AUTHNET':
      return <img src={AuthNetLogo} className="h-8" alt="authnet" />
    case 'BRAINTREE':
      return <BraintreeLogo className="h-8" />
    default:
      return null
  }
}

const Form = ({ gateway, setGateway }) => {
  switch (gateway.paymentGateway.gatewayType) {
    case 'AUTHNET':
    return (
      <>
        <FloatingLabelInput
          label="Nickname"
          value={gateway.nickname}
          onChange={e => setGateway({ ...gateway, nickname: e.target.value })}
        />
        <div className="flex items-center justify-between mt-8">
          <div className="text-base text-gray-700">Manually Capture</div>
          <Switch
            className="ml-2"
            checked={gateway.manuallyCapture}
            onChange={checked => setGateway({...gateway, manuallyCapture: checked})}
          />
        </div>
      </>
    )
    default:
    return (
      <FloatingLabelInput
        label="Nickname"
        value={gateway.nickname}
        onChange={e => setGateway({ ...gateway, nickname: e.target.value })}
      />
    )
  }
}

const ShopPaymentGatewayCard = ({ gateway }) => {
  const [localGateway, setLocalGateway] = useState(gateway)
  const [formShowing, setFormShowing] = useState(false)

  const [updateGateway, { loading }] = useMutation(UPDATE_SHOP_PAYMENT_GATEWAY, {
    refetchQueries: [
      {
        query: SHOP_PAYMENT_GATEWAYS,
      },
    ],
    onError: () => {},
    onCompleted: () => {
      setFormShowing(false)
    },
  })

  const [destroyShopPaymentGateway] = useMutation(DESTROY_SHOP_PAYMENT_GATEWAY, {
    refetchQueries: [
      {
        query: SHOP_PAYMENT_GATEWAYS,
      },
    ],
    onError: error => {
      if (error.graphQLErrors) {
        alert(error.graphQLErrors.map(e => e.message).join(', '))
      } else {
        alert('There was an error removing your gateway')
      }
    },
    onCompleted: () => {
      setFormShowing(false)
    },
  })

  const update = () => {
    if (loading) return false

    updateGateway({
      variables: {
        id: gateway.id,
        nickname: localGateway.nickname || "",
        manuallyCapture: localGateway.manuallyCapture,
      },
    })
  }

  const remove = () => {
    destroyShopPaymentGateway({
      variables: { id: gateway.id },
    })
  }

  return (
    <div className="p-4 bg-white border-2 border-gray-200 rounded shadow-xl">
      <div className="flex items-center justify-between">
        <Icon gatewayType={gateway.paymentGateway.gatewayType} />
        <div className="ml-6 font-semibold text-green-400">
          <div className="flex items-center">
            <FiCheckCircle className="text-xl" />
            <div className="ml-2">Connected</div>
          </div>
        </div>
      </div>
      <div className="mt-4 text-sm text-gray-800">
        <div className="flex items-center justify-between w-full">
          <div>{gateway.nickname ? gateway.nickname : 'No Nickname'}</div>
          <div className="text-gray-500"></div>
        </div>
      </div>
      {formShowing ? (
        <div className="mt-4">
          <div>
            <Form gateway={localGateway} setGateway={setLocalGateway} />
          </div>
          <div className="flex justify-between mt-4">
            <div
              className="px-4 py-2 text-center text-gray-800 border border-gray-400 rounded cursor-pointer select-none hover:bg-gray-200"
              onClick={() => setFormShowing(false)}>
              Cancel
            </div>

            <div
              className="px-4 py-2 text-center text-gray-800 border border-gray-400 rounded cursor-pointer select-none hover:bg-gray-200"
              onClick={update}>
              {loading ? 'Loading' : 'Update'}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between mt-4">
          <div
            className="px-4 py-2 text-center text-gray-800 border border-gray-400 rounded cursor-pointer select-none hover:bg-gray-200"
            onClick={() => setFormShowing(true)}>
            Edit
          </div>
          <div
            className="px-4 py-2 ml-4 text-center text-gray-800 border border-gray-400 rounded cursor-pointer select-none hover:bg-gray-200"
            onClick={remove}>
            Remove
          </div>
        </div>
      )}
    </div>
  )
}

export default ShopPaymentGatewayCard
