import React from 'react'
import { Switch, Route } from 'react-router-dom'

import OAuthPage from 'pages/oauth/OAuthPage/OAuthPage'
import OAuthCallbackPage from 'pages/oauth/OAuthCallbackPage/OAuthCallbackPage'
import OAuthHostname from 'pages/oauth/OAuthHostname/OAuthHostname'

import PaymentGatewayOAuthCallback from 'pages/oauth/PaymentGatewayOAuthCallback/PaymentGatewayOAuthCallback'

const OAuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={OAuthPage} />
      <Route path="/callback" component={OAuthCallbackPage} />
      <Route path="/shop-hostname" component={OAuthHostname} />

      <Route path="/oauth/gateways/:slug" component={PaymentGatewayOAuthCallback} />
    </Switch>
  )
}

export default OAuthRouter
