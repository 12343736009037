import React from 'react'
import NumberFormat from 'components/NumberFormat'
import CurrencyFormat from 'react-currency-format'

const OfferFormat = ({ offerType, offerValue }) => {
  switch (offerType) {
    case 'NONE':
      return 'None'
    case 'PERCENTAGE':
      return <NumberFormat value={offerValue ? offerValue : 0} suffix={'%'} />
    case 'FIXED_AMOUNT':
      return (
        <CurrencyFormat
          value={offerValue ? offerValue : 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      )
    default:
      return ''
  }
}

export default OfferFormat
