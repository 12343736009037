import gql from 'graphql-tag'

export const SOLD_OUT_VARIANTS = gql`
  query soldOutVariants {
    soldOutVariants {
      id
    }
  }
`

export const CREATE_SOLD_OUT_VARIANT = gql`
  mutation createSoldOutVariant($shopifyVariantId: String!) {
    createSoldOutVariant(shopifyVariantId: $shopifyVariantId) {
      id
    }
  }
`
