import React, { useState } from 'react'
import IPhoneXWindow from 'components/IPhoneXWindow'
import { FiShoppingCart, FiChevronDown } from 'react-icons/fi'

const FakeInput = ({ label, value, color, ...props }) => {
  return (
    <div className="relative w-full">
      <label className={`absolute my-2 px-3 text-xs w-full text-gray-600 ${value ? 'inline-block' : 'hidden'}`}>
        {label}
      </label>
      <input
        {...props}
        value={value || ''}
        placeholder={label}
        className={`border rounded border-gray-400 w-full p-3 outline-none shadow-none placeholder-gray-800 focus:shadow-inputoutline focus:border-gray-800 ${
          value ? 'pt-6 pb-1' : ''
        }`}
        style={{ borderColor: color }}
      />
    </div>
  )
}

const CheckoutStylePreview = ({ shopTitle, brandColor, logoImage, backgroundImage, trustBadge }) => {
  const [fakeData, setFakeData] = useState({ email: 'john.doe@gmail.com' })

  return (
    <IPhoneXWindow>
      <div className="flex items-center h-20 bg-center bg-cover" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="">
          {logoImage ? (
            <img className="h-8 mt-5 ml-6" src={logoImage} alt="logo" />
          ) : (
            <div className={`ml-4 text-lg font-bold mt-5 ${backgroundImage ? 'text-white' : 'text-gray-800'}`}>
              {shopTitle ? shopTitle : 'Shop Title'}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center border-b border-gray-300">
        <div className="flex-1 p-4 text-sm font-thin" style={{ color: brandColor }}>
          <div className="flex items-center">
            <div>
              <FiShoppingCart className="text-base" />
            </div>
            <div className="ml-2">Show order summary</div>
            <div>
              <FiChevronDown className="mt-1 ml-1 text-sm" />
            </div>
          </div>
        </div>
        <div className="pr-4">$59.99</div>
      </div>
      <div className="px-2 mt-2">
        {trustBadge ? (
          <div className="mx-2 mt-3 mb-2">
            <img src={trustBadge} alt="trust" />
          </div>
        ) : null}

        <FakeInput
          label="Email"
          value={fakeData.email}
          color={brandColor}
          onChange={e => setFakeData({ ...fakeData, email: e.target.value })}
        />

        <div className="flex mt-2">
          <div className="relative w-4 h-4 mt-1 flex-0">
            <div
              className="w-full h-full border border-gray-300 rounded cursor-pointer"
              style={{ backgroundColor: brandColor }}>
              <img
                src="https://s3.amazonaws.com/bettercart.assets.shops.prod/assets/checkbox-tick.svg"
                alt="Tick"
                className="w-8/12 h-full m-auto cover"
              />
            </div>
          </div>
          <div className="flex-1 ml-1 text-xs text-gray-800 cursor-pointer select-none">
            Keep me up to date on news and exclusive offers
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0 mx-2 mb-10">
        <div className="p-3 mx-2 text-center text-white rounded" style={{ backgroundColor: brandColor }}>
          Continue To Shipping
        </div>
      </div>
    </IPhoneXWindow>
  )
}

export default CheckoutStylePreview
