import React, { useMemo } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import CurrencyFormat from 'react-currency-format'
import moment from 'moment'
import LineChart from 'components/charts/LineChart'

const TOTAL_SALES = gql`
  query totalSales($startDate: DateTime!, $endDate: DateTime!) {
    totalSales(startDate: $startDate, endDate: $endDate)
  }
`

const TotalSales = ({ startDate, endDate }) => {
  const { data, loading } = useQuery(TOTAL_SALES, { fetchPolicy: 'network-only', variables: { startDate, endDate } })

  const totalSales = useMemo(() => {
    if (!data || !data.totalSales) return 0

    return data.totalSales.totalSales.truncs.reduce((sum, t) => sum + t.revenue, 0)
  }, [data])

  const chartData = useMemo(() => {
    if (data && data.totalSales) {
      return [data.totalSales.totalSales.truncs.map(t => ({ x: moment(t.trunc).unix(), y: t.revenue }))]
    } else {
      return [[]]
    }
  }, [data])

  return (
    <div className="p-3 bg-white rounded shadow">
      <div className="text-base font-normal text-gray-800 uppercase">Total Sales</div>
      {loading ? (
        <div className="">Loading...</div>
      ) : (
        <div>
          <div className="mt-1 text-2xl font-bold text-gray-800">
            <CurrencyFormat
              value={totalSales}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </div>
          <div
            className="mt-2"
            style={{
              width: '100%',
              height: '350px',
            }}>
            {data ? (
              <LineChart data={chartData} yValueType="money" trunc={data.totalSales.totalSales.trunc} />
            ) : (
              <div className="text-sm text-gray-500">Loading Data...</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default TotalSales
