import React, { useState, useMemo, useEffect } from 'react'
import { INTEGRATIONS, CREATE_SHOP_INTEGRATION, UPDATE_SHOP_INTEGRATION, DESTROY_SHOP_INTEGRATION } from './GraphQL'
import { useMutation } from '@apollo/client'
import { message } from 'antd'
import { FiCheckCircle } from 'react-icons/fi'

import { ReactComponent as KlaviyoLogo } from 'images/klaviyo.svg'
import { ReactComponent as GoogleAutocompleteLogo } from 'images/google-autocomplete.svg'
import { ReactComponent as GoogleTagManagerLogo } from 'images/google-tag-manager.svg'

import { KlaviyoDescription, KlaviyoForm } from './Klaviyo'
import { GoogleAutocompleteDescription, GoogleAutocompleteForm } from './GoogleAutocomplete'
import { GoogleTagManagerDescription, GoogleTagManagerForm } from './GoogleTagManager'

const Icon = ({ service }) => {
  switch (service) {
    case 'KLAVIYO':
      return <KlaviyoLogo className="h-12" />
    case 'TWILIO':
      return null
    case 'GOOGLE_AUTOCOMPLETE':
      return <GoogleAutocompleteLogo className="h-12" />
    case 'GOOGLE_TAG_MANAGER':
      return <GoogleTagManagerLogo className="h-12" />
    default:
      return null
  }
}

const Description = ({ service }) => {
  switch (service) {
    case 'KLAVIYO':
      return <KlaviyoDescription />
    case 'TWILIO':
      return null
    case 'GOOGLE_AUTOCOMPLETE':
      return <GoogleAutocompleteDescription />
    case 'GOOGLE_TAG_MANAGER':
      return <GoogleTagManagerDescription />
    default:
      return null
  }
}

const Form = ({ service, shopIntegration, setShopIntegration }) => {
  switch (service) {
    case 'KLAVIYO':
      return <KlaviyoForm shopIntegration={shopIntegration} setShopIntegration={setShopIntegration} />
    case 'TWILIO':
      return null
    case 'GOOGLE_AUTOCOMPLETE':
      return <GoogleAutocompleteForm shopIntegration={shopIntegration} setShopIntegration={setShopIntegration} />
    case 'GOOGLE_TAG_MANAGER':
      return <GoogleTagManagerForm shopIntegration={shopIntegration} setShopIntegration={setShopIntegration} />
    default:
      return null
  }
}

const IntegrationCard = ({ service, description, loading, data }) => {
  const [formShowing, setFormShowing] = useState(false)
  const [shopIntegration, setShopIntegration] = useState({ apiData: {}, service })

  const serviceName = useMemo(() => {
    switch (service) {
      case 'KLAVIYO':
        return 'Klaviyo'
      case 'TWILIO':
        return 'Twilio'
      case 'GOOGLE_AUTOCOMPLETE':
        return 'Google Autocomplete'
      case 'GOOGLE_TAG_MANAGER':
        return 'Google Tag Manager'
      default:
        return 'Unknown'
    }
  }, [service])

  const [createShopIntegration, { loading: createLoading }] = useMutation(CREATE_SHOP_INTEGRATION, {
    onCompleted: data => {
      setFormShowing(false)
    },
    onError: error => {
      message.error(`There was an error activating ${serviceName}`)
    },
    refetchQueries: [
      {
        query: INTEGRATIONS,
      },
    ],
  })

  const [updateShopIntegration, { loading: updateLoading }] = useMutation(UPDATE_SHOP_INTEGRATION, {
    onCompleted: data => {
      setFormShowing(false)
    },
    onError: error => {
      message.error(`There was an error updating ${serviceName}`)
    },
    refetchQueries: [
      {
        query: INTEGRATIONS,
      },
    ],
  })

  const [destroyShopIntegration] = useMutation(DESTROY_SHOP_INTEGRATION, {
    onError: error => {
      message.error(`There was an error deactivating ${serviceName}`)
    },
    refetchQueries: [
      {
        query: INTEGRATIONS,
      },
    ],
  })

  const activatedIntegration = useMemo(() => {
    if (data && data.shopIntegrations) return data.shopIntegrations.find(i => i.service === service)
  }, [data, service])

  useEffect(() => {
    if (activatedIntegration) {
      setShopIntegration(i => ({
        ...i,
        apiData: activatedIntegration.apiData,
      }))
    }
  }, [activatedIntegration, setShopIntegration])

  const activate = () => {
    if (createLoading) return false

    if (activatedIntegration) {
      updateShopIntegration({
        variables: {
          id: activatedIntegration.id,
          shopIntegration: {
            apiData: shopIntegration.apiData,
          },
        },
      })
    } else {
      createShopIntegration({
        variables: {
          shopIntegration,
        },
      })
    }
  }

  const deactivate = () => {
    destroyShopIntegration({
      variables: {
        id: activatedIntegration.id,
      },
    })
    setShopIntegration({ apiData: {}, service })
  }

  return (
    <div className="p-4 rounded shadow-xl bg-white border-2 border-gray-200">
      <div className="flex items-center justify-between">
        <Icon service={service} />
        {loading ? (
          <div className="ml-6 text-gray-300 font-semibold">
            <div className="flex items-center">
              <div className="ml-2">Loading...</div>
            </div>
          </div>
        ) : activatedIntegration ? (
          <div className="ml-6 text-green-400 font-semibold">
            <div className="flex items-center">
              <FiCheckCircle className="text-xl" />
              <div className="ml-2">Connected</div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-4 text-sm text-gray-800">
        <Description service={service} />
      </div>
      {formShowing ? (
        <div className="mt-4">
          <div>
            <Form service={service} shopIntegration={shopIntegration} setShopIntegration={setShopIntegration} />
          </div>
          <div className="flex justify-between mt-4">
            <div
              className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
              onClick={() => setFormShowing(false)}>
              Cancel
            </div>
            <div
              className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
              onClick={activate}>
              {createLoading || updateLoading ? 'Loading...' : activatedIntegration ? 'Update' : 'Activate'}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-end mt-4">
          {activatedIntegration ? (
            <>
              <div
                className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
                onClick={() => setFormShowing(true)}>
                Edit {serviceName}
              </div>
              <div
                className="ml-4 py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
                onClick={deactivate}>
                Deactivate {serviceName}
              </div>
            </>
          ) : (
            <div
              className="py-2 px-4 text-gray-800 border border-gray-400 rounded select-none cursor-pointer text-center hover:bg-gray-200"
              onClick={() => setFormShowing(true)}>
              Activate {serviceName}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default IntegrationCard
