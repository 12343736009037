import gql from 'graphql-tag'

export const SOLD_OUT_VARIANTS = gql`
  query soldOutVariants {
    soldOutVariants {
      id
      productVariant {
        id
        title
        sku
        product {
          id
          title
        }
      }
    }
  }
`

export const DESTROY_SOLD_OUT_VARIANT = gql`
  mutation destroySoldOutVariant($id: String!) {
    destroySoldOutVariant(id: $id) {
      id
    }
  }
`
