import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { OnboardingContext } from 'contexts/OnboardingContext'
import ProgressBar from 'components/ProgressBar'
import { FiX } from 'react-icons/fi'
import { setCurrentShop } from 'utils/Auth'

const SHOP = gql`
  query Shop {
    shop {
      id
      name
      shopifyHostname
      billingPlanType

      user {
        id
        name
        email
        phone
      }
    }
  }
`

const OnboardingLayout = ({ children, match, history, location }) => {
  const { onboardingContext, onboardingDispatch } = useContext(OnboardingContext)

  const { data } = useQuery(SHOP)

  const handleClose = () => {
    window.localStorage.setItem('closed-wizard', true)
    history.push('/dashboard')
  }

  useEffect(() => {
    if (data && data.shop) {
      setCurrentShop(data.shop.shopifyHostname)
    }
  }, [data])

  useEffect(() => {
    if (onboardingContext.currentStep === null) {
      onboardingDispatch({ type: 'SET_STEP_BY_ID', id: match.params.step })
    }
  }, [onboardingDispatch, match.params, onboardingContext.currentStep])

  useEffect(() => {
    if (onboardingContext.currentStep) {
      history.push(`/dashboard/onboarding/${onboardingContext.currentStep.id}`)
    }
  }, [history, onboardingContext.currentStep])

  useEffect(() => {
    // const _hsq = window._hsq = window._hsq || [];

    if (data && data.shop) {

      // _hsq.push(["identify",{
      //     email: data.shop.user.email
      // }]);

      // window.hsConversationsSettings = {
      //   identificationEmail: data.shop.user.email,
      // };

      // window.HubSpotConversations.widget.load();

      // window.Intercom('update', {
      //   email: data.shop.user.email,
      //   name: data.shop.user.name,
      //   company: {
      //     id: data.shop.id,
      //     name: data.shop.name,
      //     plan: data.shop.billingPlanType,
      //     monthly_spend: data.shop.billingPlanType === 'PRO' ? 297 : data.shop.billingPlanType === 'LITE' ? 97 : 0,
      //     website: `https://${data.shop.shopifyHostname}`,
      //   },
      //   user_id: data.shop.user.id,
      //   phone: data.shop.user.phone,
      // })

      if (window.Beacon) {
        window.Beacon('identify', {
          name: data.shop.user.name,
          email: data.shop.user.email,
          company: data.shop.name,
          domain: `https://${data.shop.shopifyHostname}`,
          plan: data.shop.billingPlanType
        })
      }
    }
  }, [data])

  if (!onboardingContext.currentStep) return null

  return (
    <>
      <div className="relative mt-4">
        <div className="relative z-20 w-11/12 p-4 mx-auto bg-white rounded lg:w-10/12">
          <div className="absolute left-2 top-2">
            <FiX onClick={handleClose} className="text-xl text-gray-500 cursor-pointer" />
          </div>
          <div className="mt-4">
            <ProgressBar steps={onboardingContext.steps} current={onboardingContext.currentStep.position} />
          </div>
          <div className="mt-6">{children}</div>
        </div>
      </div>

      <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-black opacity-50" />
    </>
  )
}

export default withRouter(OnboardingLayout)
