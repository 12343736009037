import React from 'react'
import FloatingLabelInput from 'components/FloatingLabelInput'

export const FacebookDescription = ({ pixel }) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div>{pixel.data.trackingId}</div>
      <div className="text-gray-500">{pixel.name}</div>
    </div>
  )
}

export const FacebookForm = ({ pixel, setPixel }) => {
  return (
    <>
      <FloatingLabelInput
        label="Pixel ID"
        value={pixel.data.trackingId}
        onChange={e => setPixel({ ...pixel, data: { trackingId: e.target.value } })}
      />
      <div className="mt-2">
        <FloatingLabelInput
          label="Nickname"
          value={pixel.name}
          onChange={e => setPixel({ ...pixel, name: e.target.value })}
        />
      </div>
    </>
  )
}
