import React, { useState } from 'react'
import { Input } from 'antd'
import { useMutation } from '@apollo/client'
import { CREATE_SOLD_OUT_VARIANT, SOLD_OUT_VARIANTS } from './GraphQL'

const NewSoldOutVariantModal = ({ onClose }) => {
  const [shopifyVariantId, setShopifyVariantId] = useState('')

  const [createSoldOutVariant, { loading, error }] = useMutation(CREATE_SOLD_OUT_VARIANT, {
    refetchQueries: [
      {
        query: SOLD_OUT_VARIANTS,
      },
    ],
    onCompleted: () => {
      if (!error) {
        setShopifyVariantId('')
        onClose()
      }
    },
  })

  const handleCreate = () => {
    createSoldOutVariant({
      variables: {
        shopifyVariantId
      },
    })
  }

  return (
    <>
      <div className="pb-4 text-sm text-gray-600 border-b border-gray-200">
        To mark a variant sold out, supply the variant id from shopify below. When marking a variant as sold out, it will no longer be available to customers when choosing variants on all upsell pages.
        
        <br />
        <br />
        <a className="mt-4 underline" href="https://help.shopify.com/en/manual/products/variants/find-variant-id" target="_blank" rel="noopener noreferrer">
          Learn how to find variant id 
        </a>
      </div>
      <div className="mt-4">
        <div className="">
          <Input
            placeholder="Shopify Variant ID"
            value={shopifyVariantId}
            onChange={e => setShopifyVariantId(e.target.value)}
          />

          {error ? (
            <div className="mt-4">
              {error.graphqlErrors.map(({ message }, index) => (
                <div className="text-sm text-red-400">{message}</div>
              ))}
            </div>
          ) : null}

          {loading ? (
            <div className="p-4 mt-4 text-sm text-center text-white bg-gray-300 rounded cursor-pointer hover:bg-gray-400">
              Loading...
            </div>
          ) : (
            <div
              className="p-4 mt-4 text-sm text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
              onClick={handleCreate}>
              Submit
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default NewSoldOutVariantModal
