import React, { Fragment } from 'react'

const CustomInput = ({ label, ...props }) => {
  return (
    <Fragment>
      {label ? <div className="text-xs text-gray-800 font-semibold uppercase">{label}</div> : null}
      <input {...props} className="border-b border-gray-700 text-xs pb-2 w-full" />
    </Fragment>
  )
}

export default CustomInput
