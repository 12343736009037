import { AUTH_TOKEN, CURRENT_SHOP } from 'config/Constants'

export const setAuthToken = (hostname, token) => {
  const tokens = JSON.parse(localStorage.getItem(AUTH_TOKEN) || '{}')
  return localStorage.setItem(AUTH_TOKEN, JSON.stringify({ ...tokens, [hostname]: token }))
}

export const getAuthToken = hostname => {
  const tokens = JSON.parse(localStorage.getItem(AUTH_TOKEN) || '{}')
  return tokens[hostname]
}

export const setCurrentShop = shop => {
  return localStorage.setItem(CURRENT_SHOP, shop)
}

export const getCurrentShop = () => {
  return localStorage.getItem(CURRENT_SHOP)
}
