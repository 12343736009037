import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react'
import { OnboardingContext } from 'contexts/OnboardingContext'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import CreditCardForm from 'components/billing/CreditCardForm'
import { message } from 'antd'
import { FaCheckCircle } from 'react-icons/fa'
import OnboardingNavigation from 'components/OnboardingNavigation'

export const SHOP_BILLING = gql`
  query shopBilling {
    shop {
      id
      billingPlanStatus
      billingPlanType

      billingPaymentMethods {
        id
        last4
        brand
        expMonth
        expYear
        primary
      }
    }
  }
`

export const BILLING_SUBSCRIBE = gql`
  mutation BillingSubscribe($planId: String!) {
    billingSubscribe(planId: $planId) {
      id
      name
      shopifyHostname
      billingPlanStatus
      billingPlanType
      billingCustomerExternalId

      billingPaymentMethods {
        id
        last4
        brand
        expMonth
        expYear
        primary
      }
    }
  }
`

const BillingStep = () => {
  const { onboardingDispatch } = useContext(OnboardingContext)

  const [showForm, setShowForm] = useState(true)

  const { data, loading } = useQuery(SHOP_BILLING)

  const [billingSubscribe] = useMutation(BILLING_SUBSCRIBE, {
    onCompleted: data => {
      window.tap('trial', data.billingSubscribe.billingCustomerExternalId, {
        shop: data.billingSubscribe.name,
        url: data.billingSubscribe.shopifyHostname,
      })

      onboardingDispatch({ type: 'UPDATE_PLAN', planActive: true })
      onboardingDispatch({ type: 'NEXT' })
    },
    onError: error => {
      message.error('There was an error activating your subscription, please contact support.')
    },
  })

  const subscribe = planId => {
    billingSubscribe({
      variables: {
        planId,
      },
    })
  }

  const hasPaymentMethod = useMemo(() => {
    return data && data.shop.billingPaymentMethods && data.shop.billingPaymentMethods.length > 0 ? true : false
  }, [data])

  const isPlanActive = useCallback(
    planType => {
      return data && data.shop.billingPlanType === planType && data.shop.billingPlanStatus === 'ACTIVE' ? true : false
    },
    [data]
  )

  const handleCardCreated = data => {
    subscribe('lite2')
  }

  useEffect(() => {
    if (isPlanActive('LITE') || isPlanActive('PRO')) {
      onboardingDispatch({ type: 'UPDATE_PLAN', planActive: true })
    } else {
      onboardingDispatch({ type: 'UPDATE_PLAN', planActive: false })
    }
  }, [isPlanActive, onboardingDispatch])

  useEffect(() => {
    setShowForm(!hasPaymentMethod)
  }, [hasPaymentMethod, setShowForm])

  return (
    <div className="">
      <div className="text-2xl text-gray-800 font-semibold text-center">Start A Free Trial</div>
      <div className="text-base text-gray-500 text-center mt-2 max-w-xl mx-auto">
        <div>
          Better Cart will never take a % of your revenue, we believe in a simple and fair flat rate pricing plan. Only
          pay for what you use and cancel at any time!
        </div>
      </div>

      {isPlanActive('LITE') || isPlanActive('PRO') ? (
        <div className="flex flex-col items-center justify-center mt-6">
          <div>
            <FaCheckCircle className="text-5xl text-green-400" />
          </div>
          <div className="text-xl text-gray-800 text-center mt-2">Plan Activated</div>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="text-center text-gray-300">Loading...</div>
          ) : (
            <div className="mt-10 max-w-3xl mx-auto">
              <div className="max-w-sm mx-auto">
                {showForm ? (
                  <>
                    <CreditCardForm createTitle="Add Card - $0.00" onCardCreated={handleCardCreated} />

                    <div className="text-xs text-gray-500 mt-1 text-center">
                      You will <span className="underline font-bold">not</span> be charged today. 14 Day Free Trial
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => subscribe('lite2')}
                      className="text-white uppercase text-center p-2 bg-gray-800 cursor-pointer rounded select-none mt-2 hover:bg-gray-700">
                      Start Free Trial
                    </div>
                    <div className="text-xs text-gray-500 mt-1 text-center">
                      You will <span className="underline font-bold">not</span> be charged today. 14 Day Free Trial
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}

      <div className="mt-12">
        <OnboardingNavigation
          nextTitle={isPlanActive('LITE') || isPlanActive('PRO') ? 'Continue' : 'Start My Free Trial'}
        />
      </div>
    </div>
  )
}

export default BillingStep
