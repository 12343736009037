import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Provider, ResourcePicker } from '@shopify/app-bridge-react'
import { CURRENT_SHOP } from 'config/Constants'
import { Modal, Table } from 'antd'
import { FiImage } from 'react-icons/fi'

const SHOPIFY_PRODUCTS = gql`
  query ShopifyProducts {
    shopifyProducts {
      id
      title
      handle

      variants {
        id
        title
        price
        compareAtPrice
      }

      images {
        id
        src
      }
    }
  }
`

const ShopifyProductPicker = ({ open, setOpen, onSelect }) => {
  const shopifyConfig = {
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    shopOrigin: localStorage.getItem(CURRENT_SHOP),
  }

  const [selectedProductId, setSelectedProductId] = useState()

  const { data, loading, error } = useQuery(SHOPIFY_PRODUCTS)

  const handleModalSelect = product => {
    onSelect(selectedProductId)
    setOpen(false)
  }

  const handleResouceSelect = selection => {
    const product = selection.selection[0]
    const elems = product.id.split('/')
    const id = elems[elems.length - 1]

    onSelect(id)
    setOpen(false)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedProductId(selectedRows[0].id)
    },
    type: 'radio',
    columnWidth: '40px',
  }

  const columns = [
    {
      title: '',
      key: 'images[0].id',
      render: record =>
        record.images[0] ? (
          <img className="w-10 h-10 rounded" src={record.images[0].src} alt="product" />
        ) : (
          <FiImage className="text-4xl text-gray-300" />
        ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
  ]

  if (error) return <div>Whoops, there was an error</div>

  if (window.top !== window.self) {
    return (
      <Provider config={shopifyConfig}>
        <ResourcePicker
          allowMultiple={false}
          showVariants={false}
          resourceType="Product"
          open={open}
          onSelection={handleResouceSelect}
          onCancel={() => setOpen(false)}
        />
      </Provider>
    )
  } else {
    return (
      <Modal
        title="Select Shopify Product"
        okText="Select"
        visible={open}
        onCancel={() => setOpen(false)}
        okButtonProps={{ disabled: !selectedProductId }}
        onOk={() => handleModalSelect()}>
        <Table
          scroll={{ y: 500 }}
          showHeader={false}
          loading={loading}
          rowSelection={rowSelection}
          dataSource={data ? data.shopifyProducts : []}
          columns={columns}
          rowKey="id"
          pagination={false}
          size="middle"
        />
      </Modal>
    )
  }
}

export default ShopifyProductPicker
