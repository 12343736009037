import React, { useState, useEffect } from 'react'
import { Collapse, Divider, Modal, Switch } from 'antd'
import CustomInput from 'components/CustomInput'
import CustomTextarea from 'components/CustomTextarea'
import CustomColorPicker from 'components/CustomColorPicker'
import RichTextEditor from 'components/RichTextEditor'

const { Panel } = Collapse

const ToolBar = ({ funnelStep, onFunnelStepUpdate, design, onDesignChange }) => {
  const [tempTitle, setTempTitle] = useState(design.productTitleHtml)
  const [titleModalShowing, setTitleModalShowing] = useState(false)

  const [tempDescription, setTempDescription] = useState(design.descriptionHtml)
  const [descriptionModalShowing, setDescriptionModalShowing] = useState(false)

  useEffect(() => {
    if (design.productTitleHtml) setTempTitle(design.productTitleHtml)
  }, [setTempTitle, design])

  const saveTitle = () => {
    onDesignChange({ ...design, productTitleHtml: tempTitle })
    setTitleModalShowing(false)
  }

  useEffect(() => {
    if (design.descriptionHtml) setTempDescription(design.descriptionHtml)
  }, [setTempDescription, design])

  const saveDescription = () => {
    onDesignChange({ ...design, descriptionHtml: tempDescription })
    setDescriptionModalShowing(false)
  }

  return (
    <div className="">
      <Collapse accordion={true} bordered={false} defaultActiveKey={['2']} className="select-none">
        <Panel header="Content" key="2" className="text-base font-medium">
          <div>
            <div
              onClick={() => setTitleModalShowing(true)}
              className="px-4 py-2 text-sm font-light text-center text-white bg-gray-800 rounded cursor-pointer select-none hover:bg-gray-700">
              Edit Product Title
            </div>
            <Modal
              title="Edit Product Title"
              visible={titleModalShowing}
              onCancel={() => setTitleModalShowing(false)}
              onOk={saveTitle}
              okText="Save"
              cancelText="Cancel">
              <RichTextEditor htmlState={tempTitle} onHtmlChange={setTempTitle} />
            </Modal>
          </div>

          <div className="mt-4">
            <CustomTextarea
              placeholder="Page Headline"
              label="Page Headline"
              rows={1}
              maxRows={4}
              value={design.headline || ''}
              onChange={e => onDesignChange({ ...design, headline: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomTextarea
              placeholder="Offer Headline"
              label="Offer Headline"
              rows={1}
              maxRows={4}
              value={design.offerHeadline || ''}
              onChange={e => onDesignChange({ ...design, offerHeadline: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomTextarea
              placeholder="Offer Sub Headline"
              label="Offer Sub Headline"
              rows={1}
              maxRows={4}
              value={design.offerSubHeadline || ''}
              onChange={e => onDesignChange({ ...design, offerSubHeadline: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomTextarea
              placeholder="Offer Description"
              label="Offer Description"
              rows={1}
              maxRows={4}
              value={design.offerDescription || ''}
              onChange={e => onDesignChange({ ...design, offerDescription: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomTextarea
              placeholder="Offer Expires In:"
              label="Timer Text"
              rows={1}
              maxRows={4}
              value={design.timerText || ''}
              onChange={e => onDesignChange({ ...design, timerText: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomInput
              placeholder="Was"
              label="Was Label"
              value={design.wasLabel || ''}
              onChange={e => onDesignChange({ ...design, wasLabel: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomInput
              placeholder="Now"
              label="Now Label"
              value={design.nowLabel || ''}
              onChange={e => onDesignChange({ ...design, nowLabel: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomInput
              placeholder="You Save"
              label="Save Label"
              value={design.saveLabel || ''}
              onChange={e => onDesignChange({ ...design, saveLabel: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomInput
              placeholder="Please Select"
              label="Variant Placeholder"
              value={design.variantPlaceholder || ''}
              onChange={e => onDesignChange({ ...design, variantPlaceholder: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomInput
              placeholder="Yes, Add To Order"
              label="Confirm Text"
              value={design.confirmText || ''}
              onChange={e => onDesignChange({ ...design, confirmText: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomInput
              placeholder="No Thanks"
              label="Decline Text"
              value={design.declineText || ''}
              onChange={e => onDesignChange({ ...design, declineText: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <CustomTextarea
              placeholder="Don't Miss This One Time Offer"
              label="Highlight Banner Text"
              rows={1}
              maxRows={4}
              value={design.highlightBannerText || ''}
              onChange={e => onDesignChange({ ...design, highlightBannerText: e.target.value })}
            />
          </div>

          <div className="mt-4">
            <div
              onClick={() => setDescriptionModalShowing(true)}
              className="px-4 py-2 text-sm font-light text-center text-white bg-gray-800 rounded cursor-pointer select-none hover:bg-gray-700">
              Edit Description
            </div>
            <Modal
              title="Edit Description"
              visible={descriptionModalShowing}
              onCancel={() => setDescriptionModalShowing(false)}
              onOk={saveDescription}
              okText="Save"
              cancelText="Cancel">
              <RichTextEditor htmlState={tempDescription} onHtmlChange={setTempDescription} />
            </Modal>
          </div>
        </Panel>

        <Panel header="Appearance" key="3" className="text-base font-medium">
          <div className="mt-4">
            <CustomColorPicker
              label="Headline Background"
              rows={1}
              maxRows={4}
              value={design.headlineBgColor}
              onChange={value => onDesignChange({ ...design, headlineBgColor: value })}
            />
          </div>

          <div className="mt-4">
            <CustomColorPicker
              label="Headline Text"
              value={design.headlineColor}
              onChange={value => onDesignChange({ ...design, headlineColor: value })}
            />
          </div>

          <Divider />

          <div className="mt-4">
            <CustomColorPicker
              label="Timer Background"
              value={design.timerBgColor}
              onChange={value => onDesignChange({ ...design, timerBgColor: value })}
            />
          </div>

          <div className="mt-4">
            <CustomColorPicker
              label="Timer Text"
              value={design.timerTextColor}
              onChange={value => onDesignChange({ ...design, timerTextColor: value })}
            />
          </div>

          <div className="mt-4">
            <CustomColorPicker
              label="Timer Time"
              value={design.timerColor}
              onChange={value => onDesignChange({ ...design, timerColor: value })}
            />
          </div>

          <Divider />

          <div className="mt-4">
            <CustomColorPicker
              label="Now Label"
              value={design.nowColor}
              onChange={value => onDesignChange({ ...design, nowColor: value })}
            />
          </div>

          <div className="mt-4">
            <CustomColorPicker
              label="Savings Label"
              value={design.saveColor}
              onChange={value => onDesignChange({ ...design, saveColor: value })}
            />
          </div>

          <Divider />

          <div className="mt-4">
            <CustomColorPicker
              label="Accept Button Background"
              value={design.confirmBgColor}
              onChange={value => onDesignChange({ ...design, confirmBgColor: value })}
            />
          </div>

          <div className="mt-4">
            <CustomColorPicker
              label="Accept Button Text"
              value={design.confirmTextColor}
              onChange={value => onDesignChange({ ...design, confirmTextColor: value })}
            />
          </div>

          <div className="mt-4">
            <CustomColorPicker
              label="Decline Text"
              value={design.declineColor}
              onChange={value => onDesignChange({ ...design, declineColor: value })}
            />
          </div>

          <Divider />

          <div className="mt-4">
            <CustomColorPicker
              label="Highlight Banner Background"
              value={design.highlightBannerBgColor}
              onChange={value => onDesignChange({ ...design, highlightBannerBgColor: value })}
            />
          </div>

          <div className="mt-4">
            <CustomColorPicker
              label="Highlight Banner Text"
              value={design.highlightBannerColor}
              onChange={value => onDesignChange({ ...design, highlightBannerColor: value })}
            />
          </div>
        </Panel>

        <Panel header="Settings" key="4" className="text-base font-medium">
          <div className="flex items-center justify-between">
            <div className="text-xs font-semibold text-gray-800 uppercase">Enable Quantity</div>
            <Switch
              checked={funnelStep.enableQuantity}
              onChange={checked => onFunnelStepUpdate({ enableQuantity: checked })}
            />
          </div>

          <div className="flex items-center justify-between mt-4">
            <div className="text-xs font-semibold text-gray-800 uppercase">Enable Confetti</div>
            <Switch
              checked={design.enableConfetti}
              onChange={checked => onDesignChange({ ...design, enableConfetti: checked })}
            />
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default ToolBar
