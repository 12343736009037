import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input, Button } from 'antd'

const OAuthHostname = ({ history }) => {
  const [shop, setShop] = useState('')

  const onSubmit = () => {
    history.push(`/?shop=${shop}`)
  }

  return (
    <div className="content">
      <Modal
        title="Shopify Hostname"
        visible={true}
        closable={false}
        maskClosable={false}
        footer={
          <Button type="primary" onClick={onSubmit}>
            Connect
          </Button>
        }>
        <Input
          addonBefore="https://"
          addonAfter=".myshopify.com"
          placeholder="my-shop"
          onChange={e => setShop(`${e.target.value}.myshopify.com`)}
        />
      </Modal>
    </div>
  )
}

export default withRouter(OAuthHostname)
