import React, { useState } from 'react'
import { Icon } from 'antd'
import ShopAssetUploaderDragable from 'components/ShopAssetUploaderDragable'

const Uploader = ({ title, src, loading }) => {
  if (src) {
    return (
      <div className="relative group bg-gray-300">
        <div className="absolute absolute top-0 right-0 bottom-0 left-0 items-center justify-center hidden group-hover:flex">
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-50 z-10" />
          <div className="relative text-white z-20 hover:underline">Change Image</div>
        </div>
        <div className="h-24 bg-cover bg-center w-full" style={{ backgroundImage: `url(${src})` }} />
      </div>
    )
  } else {
    return (
      <div className="p-4 text-center">
        {loading ? (
          <Icon className="text-4xl text-pink-500" type="loading" />
        ) : (
          <Icon className="text-4xl text-pink-500" type="inbox" />
        )}
        <div className="text-sm text-gray-800 mt-1">{title}</div>
        <div className="text-xs text-gray-500 mt-1">Click or drag file to this area to upload</div>
      </div>
    )
  }
}

const ImageUploader = ({ title, src, onSrc }) => {
  const [loading, setLoading] = useState(false)

  return (
    <ShopAssetUploaderDragable onPublicUrl={onSrc} onLoading={setLoading}>
      <Uploader title={title} src={src} loading={loading} />
    </ShopAssetUploaderDragable>
  )
}

export default ImageUploader
