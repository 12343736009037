import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_SMS_MESSAGE, SMS_AUTOMATION } from './GraphQL'
import { Modal, message } from 'antd'
import SmsMessageForm from './forms/SmsMessageForm'

const NewMessageCard = ({ automation }) => {
  const [showModal, setShowModal] = useState(false)
  const [smsMessage, setSmsMessage] = useState({
    body: '',
    delayUnit: 'MINUTE',
    delayValue: 15,
    image: 'NONE',
    imageSrc: '',
  })

  const [createSmsMessage] = useMutation(CREATE_SMS_MESSAGE, {
    refetchQueries: [
      {
        query: SMS_AUTOMATION,
        variables: { id: automation.id },
      },
    ],
    onCompleted: () => {
      setShowModal(false)
      message.success('Message Successfully Created')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleCreateSmsMessage = () => {
    if (smsMessage.body.length < 10) {
      message.error('Please supply a message body at least 10 characters long.')
    } else {
      createSmsMessage({
        variables: {
          smsAutomationId: automation.id,
          smsMessage,
        },
      })
    }
  }

  return (
    <>
      <div className="relative rounded border-2 border-dashed border-gray-400 p-6 mt-4">
        <div className="flex">
          <div className="flex-shrink m-auto">
            <div
              onClick={() => setShowModal(true)}
              className="p-2 rounded border border-gray-300 text-center w-auto text-sm font-medium text-gray-600 hover:border-gray-400 cursor-pointer">
              Add New Message
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showModal}
        title="New Message"
        okText="Create"
        onOk={handleCreateSmsMessage}
        onCancel={() => setShowModal(false)}>
        <SmsMessageForm smsMessage={smsMessage} setSmsMessage={setSmsMessage} />
      </Modal>
    </>
  )
}

export default NewMessageCard
