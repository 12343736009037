import React, { useState, useMemo } from 'react'
import moment from 'moment'
import millify from 'millify'
import CurrencyFormat from 'react-currency-format'
import NumberFormat from 'components/NumberFormat'

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  Crosshair,
  makeWidthFlexible,
} from 'react-vis'

import 'react-vis/dist/style.css'

const FlexibleXYPlot = makeWidthFlexible(XYPlot)

const COLORS = ['#f8598b', '#c4cdd5']

const LineChart = ({ data, yValueType, trunc }) => {
  const [crosshairValues, setCrosshairValues] = useState([])

  const onNearestX = (value, { index }) => setCrosshairValues(data.map(d => d[index]))
  const onMouseLeave = () => setCrosshairValues([])

  const maxY = useMemo(() => {
    const max = Math.max(...data.map(d => d.map(pt => pt.y)).flat())
    return max > 0 ? max : 100
  }, [data])

  return (
    <FlexibleXYPlot height={350} onMouseLeave={onMouseLeave} yDomain={[0, maxY]}>
      <VerticalGridLines tickTotal={4} style={{ strokeDasharray: '5,5' }} />
      <HorizontalGridLines />
      <XAxis
        tickTotal={trunc === 'day' ? 4 : 8}
        hideLine
        tickFormat={v => (trunc === 'hour' ? moment.unix(v).format('hA') : moment.unix(v).format('MMM D'))}
      />
      <YAxis hideLine tickFormat={v => millify(v)} />
      {data.map((d, i) => (
        <LineSeries
          key={i}
          data={d}
          onNearestX={onNearestX}
          curve="curveMonotoneX"
          color={i <= 2 ? COLORS[i] : '#2D3748'}
          strokeWidth={3}
        />
      ))}
      <Crosshair
        values={crosshairValues}
        style={{
          line: {
            width: 3,
            backgroundColor: 'rgba(196,205,213, 0.6)',
          },
        }}>
        <div className="bg-gray-800 rounded p-2 w-32 mx-2 mt-4">
          {crosshairValues.map((v, i) => {
            return (
              <div
                className={`w-full ${i + 1 > crosshairValues.length ? 'border-t border-gray-700 pt-2 mt-2' : ''}`}
                key={i}>
                <div className="text-sm text-white text-center">
                  {trunc === 'hour' ? moment.unix(v.x).format('MMM D, h:mm A') : moment.unix(v.x).format('MMM D, YYYY')}
                </div>
                <div className="flex items-center justify-center mt-1">
                  <div className="w-4 h-4 rounded" style={{ backgroundColor: i <= 2 ? COLORS[i] : '#2D3748' }} />
                  <div className="text-sm text-white ml-2">
                    {yValueType === 'money' ? (
                      <CurrencyFormat
                        value={v.y}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={'$'}
                      />
                    ) : (
                      <NumberFormat value={v.y} />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Crosshair>
    </FlexibleXYPlot>
  )
}

export default LineChart
