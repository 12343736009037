import React, { useEffect, useState, useCallback } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { FaChevronLeft, FaDesktop, FaMobileAlt } from 'react-icons/fa'
import { message, Tooltip } from 'antd'
import ToolBar from './ToolBar'
import { useQuery, useMutation } from '@apollo/client'
import { FUNNEL_STEP, UPDATE_FUNNEL_STEP, UPDATE_FUNNEL_STEP_DESIGN, PUBLISH_FUNNEL_STEP_DESIGN } from './GraphQL'
import moment from 'moment'
import Moment from 'react-moment'
import { debounce } from 'lodash'

const FunnelStepPage = ({ match }) => {
  const { funnelId, funnelStepId } = match.params

  const [viewMode, setViewMode] = useState('DESKTOP')
  const [tempDesign, setTempDesign] = useState({})
  const [previewDesign, setPreviewDesign] = useState({})

  const { loading, error, data } = useQuery(FUNNEL_STEP, {
    variables: {
      funnelId,
      id: funnelStepId,
    },
  })

  const [updateFunnelStep] = useMutation(UPDATE_FUNNEL_STEP, {
    refetchQueries: [
      {
        query: FUNNEL_STEP,
        variables: {
          funnelId,
          id: funnelStepId,
        },
      },
    ],
    onCompleted: () => {
      message.success('Funnel Step Saved')
    },
    onError: error => {
      message.error(error.message)
    },
  })

  const [updateDesign] = useMutation(UPDATE_FUNNEL_STEP_DESIGN, {
    refetchQueries: [
      {
        query: FUNNEL_STEP,
        variables: {
          funnelId,
          id: funnelStepId,
        },
      },
    ],
    onCompleted: () => {
      message.success('Draft Successfully Saved')
    },
    onError: error => {
      message.error(error.message)
    },
  })

  const [publishDesign] = useMutation(PUBLISH_FUNNEL_STEP_DESIGN, {
    refetchQueries: [
      {
        query: FUNNEL_STEP,
        variables: {
          funnelId,
          id: funnelStepId,
        },
      },
    ],
    onCompleted: () => {
      message.success('Design Successfully Published')
    },
    onError: error => {
      message.error(error.message)
    },
  })

  useEffect(() => {
    if (data && data.funnelStep.draftDesign) {
      setTempDesign(data.funnelStep.draftDesign)
      setPreviewDesign(data.funnelStep.draftDesign)
    }
  }, [data])

  const update = funnelStep => {
    updateFunnelStep({
      variables: {
        funnelId,
        id: funnelStepId,
        funnelStep,
      },
    })
  }

  const save = design => {
    setPreviewDesign(design)
    updateDesign({
      variables: {
        funnelId,
        funnelStepId,
        funnelStepDesign: { ...design, id: undefined, status: undefined },
      },
    })
  }

  const debouncedDesignUpdate = useCallback(debounce(save, 1500), [])

  const handleUpdate = design => {
    setTempDesign(design)
    debouncedDesignUpdate(design)
  }

  const publish = () => {
    publishDesign({
      variables: {
        funnelId,
        funnelStepId,
      },
    })
  }

  if (loading) {
    return 'Loading...'
  }

  if (error) {
    return 'Oops! There was an error.'
  }

  return (
    <div className="relative w-full mb-4">
      <div className="fixed top-0 right-0 z-50 pl-2 pr-4 bg-white shadow-md" style={{ height: '55px', left: '60px' }}>
        <div className="flex items-center h-full">
          <div className="self-center flex-shrink">
            <Link
              to={`/dashboard/funnels/${funnelId}`}
              className="flex text-base font-light text-gray-500 cursor-pointer hover:text-gray-600 hover:underline">
              <FaChevronLeft className="self-center flex-shrink mr-1 group-hover:text-gray-600" />
              <div className="self-center flex-shrink group-hover:text-gray-600">Back</div>
            </Link>
          </div>

          <div className="flex-grow">
            <div className="flex justify-center">
              <button className="flex-shrink p-2" onClick={() => setViewMode('DESKTOP')}>
                <FaDesktop className={`text-2xl text-gray-400 ${viewMode === 'DESKTOP' ? 'text-gray-800' : ''}`} />
              </button>
              <button className="flex-shrink p-2" onClick={() => setViewMode('MOBILE')}>
                <FaMobileAlt className={`text-2xl text-gray-400 ${viewMode === 'MOBILE' ? 'text-gray-800' : ''}`} />
              </button>
            </div>
          </div>

          <div className="self-center flex-shrink">
            <div className="flex items-center text-xs uppercase">
              <div
                className="flex-shrink w-auto px-4 py-2 text-center text-gray-300 bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                onClick={publish}>
                Publish
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-full" style={{ marginTop: '55px' }}>
        <div
          className="fixed overflow-y-auto bg-white border-r-4 border-gray--300"
          style={{ width: '225px', top: '55px', bottom: '0px' }}>
          <ToolBar
            funnelStep={data.funnelStep}
            onFunnelStepUpdate={update}
            design={tempDesign}
            onDesignChange={handleUpdate}
          />
        </div>
        <div className="w-full" style={{ marginLeft: '225px' }}>
          <div className="flex items-center justify-end mx-4 mt-2">
            <div className="flex-shrink mr-2 text-xs text-gray-500">Last Published:</div>
            <div className="flex-shrink">
              {data.funnelStep.publishedDesign ? (
                <div className="text-sm font-normal text-gray-800">
                  <Tooltip
                    title={moment(data.funnelStep.publishedDesign.updatedAt).format('MMM Do [at] h:mm a')}
                    placement="bottomRight">
                    <Moment fromNow>{data.funnelStep.publishedDesign.updatedAt}</Moment>
                  </Tooltip>
                </div>
              ) : (
                <div className="text-base text-red-400 uppercase">Never</div>
              )}
            </div>
          </div>
          <div
            className="mx-auto mt-2 bg-white border-gray-300 shadow-md border-1"
            style={
              viewMode === 'MOBILE'
                ? { width: '365px', height: '100%', minHeight: '667px', transition: '0.5s', overflowY: 'auto' }
                : { width: '95%', height: '100%', minHeight: '867px', transition: '0.5s', overflowY: 'auto' }
            }>
            <iframe
              title="Funnel Step Preview"
              className="w-full h-full p-0 m-0"
              src={`${
                process.env.REACT_APP_CHECKOUT_URL
              }/preview/funnel/${funnelId}/step/${funnelStepId}?design=${encodeURIComponent(
                JSON.stringify(previewDesign)
              )}&enableQuantity=${data.funnelStep.enableQuantity}`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(FunnelStepPage)
