import gql from 'graphql-tag'

export const SHOP = gql`
  query shop {
    shop {
      id

      shopPaymentGateways {
        id
        nickname

        paymentGateway {
          id
          name
          logo
          slug
          gatewayType
        }
      }
    }
  }
`

export const FUNNELS = gql`
  query funnels {
    funnels {
      id
      name
      tag
      enabled
      priority
    }
  }
`

export const FUNNEL_STATS = gql`
  query funnelStats($id: ID!, $startDate: DateTime, $endDate: DateTime) {
    funnelStats(id: $id, startDate: $startDate, endDate: $endDate) {
      id
      views
      orders
      conversionRate
      offerConversionRate
      revenue
      bumpRevenue
    }
  }
`

export const CREATE_FUNNEL = gql`
  mutation createFunnel($funnel: CreateFunnelInput!) {
    createFunnel(funnel: $funnel) {
      id
      name
      tag
      phoneOptional
      checkoutTemplate
      showSummary
      priority

      shopPaymentGateway {
        id
      }

      paypalShopPaymentGateway {
        id
      }
    }
  }
`

export const UPDATE_FUNNEL = gql`
  mutation updateFunnel($id: ID!, $funnel: UpdateFunnelInput!) {
    updateFunnel(id: $id, funnel: $funnel) {
      id
      name
      tag
      enabled
      phoneOptional
      checkoutTemplate
      showSummary
      priority

      shopPaymentGateway {
        id
      }

      paypalShopPaymentGateway {
        id
      }
    }
  }
`

export const DUPLICATE_FUNNEL = gql`
  mutation duplicateFunnel($id: ID!) {
    duplicateFunnel(id: $id) {
      id
      name
      tag
      enabled
      phoneOptional
      checkoutTemplate
      showSummary
      priority

      shopPaymentGateway {
        id
      }

      paypalShopPaymentGateway {
        id
      }
    }
  }
`

export const DESTROY_FUNNEL = gql`
  mutation destroyFunnel($id: ID!) {
    destroyFunnel(id: $id) {
      id
      archived
    }
  }
`

export const SHOP_PAYMENT_GATEWAYS = gql`
  query shopPaymentGateways {
    shopPaymentGateways {
      id
      nickname

      paymentGateway {
        id
        name
        logo
        slug
        gatewayType
      }
    }
  }
`
