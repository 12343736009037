import gql from 'graphql-tag'

export const PAYMENT_GATEWAYS = gql`
  query paymentGateways {
    shop {
      id
    }

    paymentGateways {
      id
      name
      slug
      logo
      authType
      gatewayType
      fields
      appPublicCreds
    }
  }
`

export const SHOP_PAYMENT_GATEWAYS = gql`
  query shopPaymentGateways {
    shop {
      id
    }

    shopPaymentGateways {
      id
      nickname

      paymentGateway {
        id
        name
        slug
        logo
        authType
        gatewayType
        fields
        appPublicCreds
      }
    }
  }
`

export const CREATE_SHOP_PAYMENT_GATEWAY = gql`
  mutation createShopPaymentGateway($slug: String!, $fields: JSON!) {
    createShopPaymentGateway(slug: $slug, fields: $fields) {
      id
      nickname

      paymentGateway {
        id
        name
        slug
        logo
        gatewayType
      }
    }
  }
`

export const UPDATE_SHOP_PAYMENT_GATEWAY = gql`
  mutation updateShopPaymentGateway($shopPaymentGatewayId: ID!, $nickname: String!) {
    updateShopPaymentGateway(shopPaymentGatewayId: $shopPaymentGatewayId, nickname: $nickname) {
      id
      nickname

      paymentGateway {
        id
        name
        slug
        logo
        authType
        gatewayType
        fields
        appPublicCreds
      }
    }
  }
`

export const CREATE_SHOP_OAUTH_SESSION = gql`
  mutation createShopOAuthSession {
    createShopOAuthSession {
      id
      nonce
    }
  }
`
