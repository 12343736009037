import { useState, useEffect, useRef } from 'react'

export default (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  const handler = useRef()

  useEffect(() => {
    clearTimeout(handler.current)

    handler.current = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}
