import React, { useContext, useState, useEffect } from 'react'
import { OnboardingContext } from 'contexts/OnboardingContext'
import FloatingLabelInput from 'components/FloatingLabelInput'
import StepCard from './StepCard'
import OnboardingNavigation from 'components/OnboardingNavigation'

const FunnelStep = () => {
  const { onboardingContext, onboardingDispatch } = useContext(OnboardingContext)

  const [funnel, setFunnel] = useState({
    name: onboardingContext.funnel.name,
    tag: onboardingContext.funnel.tag,
    checkoutTemplate: 'SHOPIFY',
  })

  useEffect(() => {
    onboardingDispatch({ type: 'UPDATE_FUNNEL_NAME', name: funnel.name })
  }, [funnel.name, onboardingDispatch])

  useEffect(() => {
    onboardingDispatch({ type: 'UPDATE_FUNNEL_TAG', tag: funnel.tag })
  }, [funnel.tag, onboardingDispatch])

  const setUpsell = step => {
    onboardingDispatch({
      type: 'UPDATE_FUNNEL_STEPS',
      steps: { ...onboardingContext.funnel.steps, upsell: { ...onboardingContext.funnel.steps.upsell, ...step } },
    })
  }

  const setDownsell = step => {
    onboardingDispatch({
      type: 'UPDATE_FUNNEL_STEPS',
      steps: { ...onboardingContext.funnel.steps, downsell: { ...onboardingContext.funnel.steps.upsell, ...step } },
    })
  }

  const removeUpsell = () => {
    onboardingDispatch({ type: 'REMOVE_UPSELL' })
  }

  const removeDownsell = () => {
    onboardingDispatch({ type: 'REMOVE_DOWNSELL' })
  }

  return (
    <div className="">
      <div className="">
        <div className="text-2xl text-gray-800 font-semibold text-center">Build Your First Funnel</div>
        <div className="text-base text-gray-500 text-center mt-2 max-w-xl mx-auto">
          <div>
            Funnels are the routes customers will take if the required product tag is met when navigating to the
            checkout from the Shopify Cart.
          </div>
        </div>
      </div>

      <div className="flex justify-around mt-6 max-w-3xl mx-auto">
        <div className="w-5/12">
          <FloatingLabelInput
            label="Funnel Name"
            value={funnel.name}
            onChange={e => setFunnel({ ...funnel, name: e.target.value })}
          />
          <div className="text-xs text-gray-500 mt-1">
            The funnel name is only used for your internal purposes, name this what ever you would like.
          </div>
        </div>
        <div className="w-5/12">
          <FloatingLabelInput
            label="Funnel Product Tag"
            placeholder="Funnel Tag (better-cart-upsell-tag)"
            value={funnel.tag}
            onChange={e => setFunnel({ ...funnel, tag: e.target.value })}
          />
          <div className="text-xs text-gray-500 mt-1">
            Any cart containing a product with this Shopify product tag will use the Better Cart checkout followed by
            these up-sells.
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="max-w-3xl mx-auto">
          <div>
            <div className="flex items-center">
              <div className="text-lg font-semibold text-gray-800">Upsell</div>
              {onboardingContext.funnel.steps.upsell.cachedProduct ? (
                <div onClick={removeUpsell} className="ml-2 text-xs text-gray-500 cursor-pointer hover:underline">
                  Remove
                </div>
              ) : null}
            </div>
            <div className="p-4 rounded shadow-lg border border-gray-200 bg-white mt-1">
              <StepCard step={onboardingContext.funnel.steps.upsell} setStep={setUpsell} />
            </div>
          </div>

          <div className="mt-4 ml-12">
            <div className="flex items-center">
              <div className="text-lg font-semibold text-gray-800">Downsell</div>
              {onboardingContext.funnel.steps.downsell.cachedProduct ? (
                <div onClick={removeDownsell} className="ml-2 text-xs text-gray-500 cursor-pointer hover:underline">
                  Remove
                </div>
              ) : null}
            </div>
            <div className="p-4 rounded shadow-lg border border-gray-200 bg-white mt-1">
              <StepCard step={onboardingContext.funnel.steps.downsell} setStep={setDownsell} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12">
        <OnboardingNavigation />
      </div>
    </div>
  )
}

export default FunnelStep
