import React, { useState, useEffect, useCallback } from 'react'
import { FaCircleNotch, FaBug } from 'react-icons/fa'
import { MdDragHandle } from 'react-icons/md'
import uuid from 'uuid/v1'
import { Provider, OrderableItem } from 'components/orderable-list/OrderableList'
import update from 'immutability-helper'

const TableRow = ({ orderable, index, record, children, onReOrder, moveItem }) => {
  return orderable ? (
    <OrderableItem itemType="table-row" index={index} moveItem={moveItem} dropItem={onReOrder} data={record}>
      {children}
    </OrderableItem>
  ) : (
    <>{children}</>
  )
}

const Table = ({
  columns,
  dataSource,
  loading,
  error,
  rowKey,
  orderable,
  orderableTitle,
  onReOrder,
  noResultsText,
}) => {
  const [tableRows, setTableRows] = useState([])

  useEffect(() => {
    if (dataSource) {
      setTableRows(dataSource)
    }
  }, [dataSource])

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = tableRows[dragIndex]
      setTableRows(
        update(tableRows, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        })
      )
    },
    [tableRows]
  )

  return (
    <Provider>
      <div className="w-full min-h-">
        <div className="flex text-gray-300 bg-gray-800" key={uuid()}>
          {orderable && orderableTitle ? <div className="self-center flex-shrink p-3">{orderableTitle}</div> : null}
          {columns.map(column => (
            <div className="self-center flex-1 p-3 text-center" key={column.key}>
              {column.title}
            </div>
          ))}
        </div>

        {tableRows.length > 0 ? (
          <>
            {tableRows.map((row, index) => {
              return (
                <TableRow
                  key={row[rowKey ? rowKey : 'id']}
                  orderable={orderable}
                  index={index}
                  record={row}
                  onReOrder={onReOrder}
                  moveItem={moveItem}>
                  <div className="flex text-gray-800 border-b border-gray-200 hover:bg-gray-200">
                    {orderable ? (
                      <div className="self-center flex-shrink p-3 text-center">
                        <MdDragHandle
                          size="2em"
                          className="m-auto text-gray-400 cursor-move fill-current hover:text-gray-500"
                        />
                      </div>
                    ) : null}

                    {columns.map(column => {
                      const cellKey = `${row[rowKey ? rowKey : 'id']}-${column.dataIndex}`

                      if (column.dataIndex) {
                        const cellText = row[column.dataIndex]
                        if (column.render) {
                          return (
                            <div className="self-center flex-1 p-3 text-center" key={cellKey}>
                              {column.render(cellText, row)}
                            </div>
                          )
                        } else {
                          return (
                            <div className="self-center flex-1 p-3 text-center" key={cellKey}>
                              {cellText ? cellText : 'N/A'}
                            </div>
                          )
                        }
                      } else {
                        if (column.render) {
                          return (
                            <div className="self-center flex-1 p-3 text-center" key={cellKey}>
                              {column.render(null, row)}
                            </div>
                          )
                        } else {
                          return (
                            <div className="self-center flex-1 p-3 text-center" key={cellKey}>
                              N/A
                            </div>
                          )
                        }
                      }
                    })}
                  </div>
                </TableRow>
              )
            })}
          </>
        ) : (
          <div className="flex items-center justify-center p-8 text-center text-gray-500">
            <div className="">{noResultsText || 'No Data'}</div>
          </div>
        )}

        {loading ? (
          <div className="w-full p-12 text-2xl text-center text-gray-400">
            <FaCircleNotch className="inline-block fill-current icon-spin hover:text-gray-500" />
            <span className="inline-block ml-2">Loading...</span>
          </div>
        ) : null}

        {error ? (
          <div className="w-full p-12 text-2xl text-center text-gray-400">
            <FaBug className="inline-block fill-current hover:text-gray-500" />
            <span className="inline-block ml-2">We experienced some errors getting your data ;(</span>
          </div>
        ) : null}
      </div>
    </Provider>
  )
}

export default Table
