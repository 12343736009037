import React from 'react'
import { Route, withRouter, Redirect } from 'react-router-dom'
import { AUTH_TOKEN } from 'config/Constants'
import queryString from 'query-string'
import { setAuthToken } from 'utils/Auth'

const ProtectedRoute = ({ location, component: Component, ...rest }) => {
  const { token, shop } = queryString.parse(location.search)

  if (token) {
    setAuthToken(shop, token)
  }

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem(AUTH_TOKEN) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default withRouter(ProtectedRoute)
