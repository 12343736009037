import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { CookiesProvider } from 'react-cookie'

import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import ProtectedRoute from 'components/ProtectedRoute'
import DashboardRouter from 'routers/DashboardRouter'
import OAuthRouter from 'routers/OAuthRouter'
import { getAuthToken, getCurrentShop } from 'utils/Auth'
import camelize2 from 'camelize2'

const App = () => {
  const link = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
  })

  const authLink = setContext((_, { headers }) => {
    const token = getAuthToken(getCurrentShop())

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const omitTypename = (key, value) => {
    return key === '__typename' ? undefined : value
  }

  const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward(operation)
  })

  const camelCaseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      return { ...response, data: camelize2(response.data, { blacklist: ['__typename'] }) }
    })
  })

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, omitTypenameLink, camelCaseLink, link]),
    cache: new InMemoryCache(),
  })

  return (
    <>
      <CookiesProvider>
        <ApolloProvider client={client}>
          <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
            <BrowserRouter>
              <ProtectedRoute path="/dashboard" component={DashboardRouter} />
              <OAuthRouter />
            </BrowserRouter>
          </IconContext.Provider>
        </ApolloProvider>
      </CookiesProvider>
    </>
  )
}

export default App
