import React, { useContext, useState, useEffect } from 'react'
import { OnboardingContext } from 'contexts/OnboardingContext'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useQuery, useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Select } from 'antd'
import PaymentGatewayForm from 'components/PaymentGateways/forms/PaymentGatewayForm'
import PaypalCheckoutForm from 'components/PaymentGateways/forms/PaypalCheckoutForm'
import OnboardingNavigation from 'components/OnboardingNavigation'

const { Option } = Select

const PAYMENT_GATEWAYS = gql`
  query paymentGateways {
    shop {
      id

      shopPaymentGateways {
        id
        nickname

        paymentGateway {
          id
          name
          gatewayType
        }
      }
    }

    paymentGateways {
      id
      name
      slug
      logo
      authType
      gatewayType
      fields
      appPublicCreds
    }
  }
`

const GatewayStep = ({ location }) => {
  const { callbackShopGatewayId } = queryString.parse(location.search)

  const { onboardingContext, onboardingDispatch } = useContext(OnboardingContext)

  const [shopPaymentGateways, setShopPaymentGateways] = useState([])
  const [selectedShopPaymentGateway, setSelectedShopPaymentGateway] = useState(
    callbackShopGatewayId ? callbackShopGatewayId : onboardingContext.funnel.shopPaymentGatewayId || undefined
  )
  const [newGatewaySlug, setNewGatewaySlug] = useState()
  const [selectedGateway, setSelectedGateway] = useState({})
  const [selectedPaypalAccount, setSelectedPaypalAccount] = useState(
    onboardingContext.funnel.paypalShopPaymentGatewayId ? onboardingContext.funnel.paypalShopPaymentGatewayId : 'false'
  )

  const { loading, data } = useQuery(PAYMENT_GATEWAYS)

  const [loadShopGateways, { data: lazyData }] = useLazyQuery(PAYMENT_GATEWAYS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data) {
      setShopPaymentGateways(data.shop.shopPaymentGateways)
    }
  }, [data, setShopPaymentGateways])

  useEffect(() => {
    if (lazyData) {
      setShopPaymentGateways(lazyData.shop.shopPaymentGateways)
    }
  }, [lazyData, setShopPaymentGateways])

  useEffect(() => {
    if (data && data.paymentGateways && newGatewaySlug) {
      setSelectedGateway(data.paymentGateways.find(g => g.slug === newGatewaySlug))
      window.localStorage.setItem('onboarding-gateway-oauth-slug', newGatewaySlug)
    }
  }, [data, newGatewaySlug])

  useEffect(() => {
    onboardingDispatch({ type: 'UPDATE_FUNNEL_SHOP_GATEWAY', id: selectedShopPaymentGateway })
  }, [selectedShopPaymentGateway, onboardingDispatch])

  useEffect(() => {
    onboardingDispatch({ type: 'UPDATE_FUNNEL_PAYPAL_GATEWAY', id: selectedPaypalAccount })
  }, [selectedPaypalAccount, onboardingDispatch])

  const handleAdded = id => {
    loadShopGateways()
    setSelectedShopPaymentGateway(id)
  }

  const handleAddedPaypal = id => {
    loadShopGateways()
    setSelectedPaypalAccount(id)
  }

  return (
    <div className="">
      <div className="">
        <div className="text-2xl text-gray-800 font-semibold text-center">Setup Payment Gateways</div>
        <div className="text-base text-gray-500 text-center mt-2 max-w-xl mx-auto">
          <div>
            You can connect as many payment gateways as you would like for both credit cards and PayPal. Funnels can
            have a Credit Card processor and a PayPal account attatched to it.
          </div>
        </div>
      </div>

      <div className="flex justify-around mt-6 max-w-3xl mx-auto">
        <div className="w-1/2 pr-4">
          <div className="text-lg text-gray-800 w-full text-center">Credit Card Gateway</div>
          {loading ? (
            <div className="text-sm text-gray-300">Loading...</div>
          ) : (
            <div className="mt-4">
              <div className="">
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  placeholder="Select Gateway or Create New Gateway"
                  value={selectedShopPaymentGateway}
                  onChange={v => setSelectedShopPaymentGateway(v)}>
                  {shopPaymentGateways
                    .filter(f => f.paymentGateway.gatewayType !== 'PAYPAL_CHECKOUT')
                    .map(shopPaymentGateway => {
                      return (
                        <Option value={shopPaymentGateway.id} key={shopPaymentGateway.id}>
                          {shopPaymentGateway.nickname || 'No Nickname'} - {shopPaymentGateway.paymentGateway.name}
                        </Option>
                      )
                    })}
                  <Option value={null}>Create New Gateway</Option>
                </Select>
              </div>
              {selectedShopPaymentGateway === null ? (
                <>
                  <div className="mt-4">
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      placeholder="New Gateway Type"
                      value={newGatewaySlug}
                      onChange={v => setNewGatewaySlug(v)}>
                      {data.paymentGateways
                        .filter(g => g.gatewayType !== 'PAYPAL_CHECKOUT')
                        .map(gateway => {
                          return (
                            <Option value={gateway.slug} key={gateway.slug}>
                              {gateway.name}
                            </Option>
                          )
                        })}
                    </Select>
                  </div>
                  <div className="mt-4">
                    <PaymentGatewayForm gateway={selectedGateway} shopId={data.shop.id} onClose={handleAdded} />
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>
        <div className="w-1/2 pl-4">
          <div className="text-lg text-gray-800 w-full text-center">
            PayPal <span className="text-xs text-gray-500">(Optional)</span>
          </div>
          <div className="mt-4">
            {loading ? (
              <div className="text-sm text-gray-300">Loading...</div>
            ) : (
              <div className="mt-4">
                <div className="">
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select or Connect New Account"
                    value={selectedPaypalAccount}
                    onChange={v => setSelectedPaypalAccount(v)}>
                    {shopPaymentGateways
                      .filter(f => f.paymentGateway.gatewayType === 'PAYPAL_CHECKOUT')
                      .map(shopPaymentGateway => {
                        return (
                          <Option value={shopPaymentGateway.id} key={shopPaymentGateway.id}>
                            {shopPaymentGateway.nickname || 'No Nickname'} - {shopPaymentGateway.paymentGateway.name}
                          </Option>
                        )
                      })}

                    <Option value="false">PayPal Disabled</Option>
                    <Option value={null}>Connect New Account</Option>
                  </Select>
                </div>
              </div>
            )}

            {selectedPaypalAccount === null ? (
              <div className="mt-4">
                <PaypalCheckoutForm onClose={handleAddedPaypal} />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="mt-12">
        <OnboardingNavigation />
      </div>
    </div>
  )
}

export default withRouter(GatewayStep)
