import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { SHOP } from './GraphQL'
import { Modal } from 'antd'
import ContentWrapper from 'components/ContentWrapper'
import Loading from './Loading'
import EnableShopForm from 'components/EnableShopForm'
import ShopSettingsForm from 'components/ShopSettingsForm'
import NewTrackingPixelModal from 'components/TrackingPixels/NewTrackingPixelModal'
import NewPaymentGatewayModal from 'components/PaymentGateways/NewPaymentGatewayModal'
import ShopPaymentGateways from './gateways/ShopPaymentGateways'
import ShopPixels from './pixels/ShopPixels'
import ShopIntegrations from './integrations/ShopIntegrations'
import ShopCustomScripts from './scripts/ShopCustomScripts'
import NewCustomScriptModal from 'components/CustomScripts/NewCustomScriptModal'

import SoldOutVariants from "./sold-outs/SoldOutVariants"
import NewSoldOutVariantModal from 'components/SoldOutVariants/NewSoldOutVariantModal'

const SettingsPage = () => {
  const [showNewGatewayModal, setShowNewGatewayModal] = useState(false)
  const [showNewPixelModal, setShowNewPixelModal] = useState(false)
  const [showNewCustomScriptModal, setShowNewCustomScriptModal] = useState(false)
  const [showNewSoldOutVariantModal, setShowNewSoldOutVariantModal] = useState(false)

  const { loading, error, data } = useQuery(SHOP)

  if (error) {
    return (
      <ContentWrapper>
        <div className="m-4 text-sm text-red-400">
          {error.graphqlErrors ? (
            error.graphqlErrors.map(({ message }, index) => <div key={index}>{message}</div>)
          ) : (
            <div>Whoops, there was an unknown error!</div>
          )}
        </div>
      </ContentWrapper>
    )
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="w-full mb-48">
        <div className="m-4">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Better Cart Status</div>
          </div>
          <ContentWrapper>
            <div className="flex">
              <div className="mx-4 my-4">
                <EnableShopForm />
              </div>
            </div>
          </ContentWrapper>
        </div>

        <div className="m-4">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Branding</div>
          </div>
          <ShopSettingsForm />
        </div>

        <div className="m-4">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Payment Gateways</div>
            <div className="flex-shrink">
              <div
                className="w-auto p-2 mb-2 text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                onClick={() => setShowNewGatewayModal(true)}>
                New Gateway
              </div>
            </div>
          </div>
          <ShopPaymentGateways />
        </div>

        <div className="m-4">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Tracking Pixels</div>
            <div className="flex-shrink">
              <div
                className="w-auto p-2 mb-2 text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                onClick={() => setShowNewPixelModal(true)}>
                New Pixel
              </div>
            </div>
          </div>
          <ShopPixels />
        </div>

        <div className="m-4">
          <div className="flex">
            <div className="flex-1 text-2xl font-light text-gray-800 align-top">Integrations</div>
          </div>
          <ShopIntegrations />
        </div>

        <div className="m-4">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Custom Scripts</div>
            <div className="flex-shrink">
              <div
                className="w-auto p-2 mb-2 text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                onClick={() => setShowNewCustomScriptModal(true)}>
                New Custom Script
              </div>
            </div>
          </div>
          <ShopCustomScripts />
        </div>

        <div className="m-4">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Checkout Permalinks</div>
          </div>
          <ContentWrapper>
            <div className="m-4">
              <div className="mt-4 text-lg text-gray-800">
                <span className="font-bold">SID:</span> <span className="">{data.shop.shortId}</span>
              </div>
              <div className="mt-2 text-sm text-gray-800">
                The SID is the shop's unique identifier that is required in all permalinks. Below is an example with
                tokens as placeholders for the line items, the funnel tag, and an automatically applied discount code.
              </div>
              <div className="mt-2 text-base text-gray-800">
                <a
                  className="underline"
                  href="https://help.shopify.com/en/manual/online-store/os/using-themes/change-the-layout/find-variant-id"
                  target="_blank"
                  rel="noopener noreferrer">
                  Learn how to find Shopify Variant IDs
                </a>
              </div>
              <div className="mt-4">
                <span className="p-1 text-base text-red-400 bg-red-100">{`${process.env.REACT_APP_SHOPIFY_APP_PUBLIC_URL}/checkouts/perm?sid=${data.shop.shortId}&variants={variant_id}:{quantity},{variant_id}:{quantity}&tag={funnel_tag}&discount={discount_code}&email={prefilled_email}`}</span>
              </div>
            </div>
          </ContentWrapper>
        </div>

        <div className="m-4 mt-8">
          <div className="flex">
            <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Sold Out Variants (86'ed)</div>
            <div className="flex-shrink">
              <div
                className="w-auto p-2 mb-2 text-center text-white bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                onClick={() => setShowNewSoldOutVariantModal(true)}>
                Add Sold Out Variant
              </div>
            </div>
          </div>
          <SoldOutVariants />
        </div>
      </div>

      <Modal
        title="Setup A New Tracking Pixel"
        visible={showNewPixelModal}
        onCancel={() => setShowNewPixelModal(false)}
        footer={null}>
        <NewTrackingPixelModal setShowNewPixelModal={setShowNewPixelModal} />
      </Modal>

      <Modal
        title="Connect A New Payment Gateway"
        visible={showNewGatewayModal}
        onCancel={() => setShowNewGatewayModal(false)}
        footer={null}>
        <NewPaymentGatewayModal onClose={() => setShowNewGatewayModal(false)} />
      </Modal>

      <Modal
        title="Create A New Custom Script"
        visible={showNewCustomScriptModal}
        onCancel={() => setShowNewCustomScriptModal(false)}
        footer={null}>
        <NewCustomScriptModal onClose={() => setShowNewCustomScriptModal(false)} />
      </Modal>

      <Modal
        title="Mark Variant Sold Out"
        visible={showNewSoldOutVariantModal}
        onCancel={() => setShowNewSoldOutVariantModal(false)}
        footer={null}>
        <NewSoldOutVariantModal onClose={() => setShowNewSoldOutVariantModal(false)} />
      </Modal>
    </>
  )
}

export default SettingsPage
