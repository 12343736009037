import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { OnboardingContext } from 'contexts/OnboardingContext'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { message } from 'antd'
import OnboardingNavigation from 'components/OnboardingNavigation'

const CREATE_FUNNEL_BULK = gql`
  mutation CreateFunnelBulk(
    $funnel: CreateFunnelInput!
    $upsell: CreateFunnelStepInput
    $downsell: CreateFunnelStepInput
  ) {
    createFunnelBulk(funnel: $funnel, upsell: $upsell, downsell: $downsell) {
      id
    }
  }
`

const SHOP = gql`
  query Shop {
    shop {
      id
      enabled
    }

    funnels {
      id
      name
      tag
      phoneOptional
      enabled
      totalViews
      totalConversionRate
      totalOfferConversionRate
      totalRevenue
    }
  }
`

const CompletedStep = ({ history }) => {
  const { onboardingContext, onboardingDispatch } = useContext(OnboardingContext)

  const [createFunnelBulk] = useMutation(CREATE_FUNNEL_BULK, {
    onCompleted: data => {
      onboardingDispatch({ type: 'LAUNCH_FUNNEL' })
      history.push(`/dashboard/funnels/${data.createFunnelBulk.id}`)
    },
    refetchQueries: [
      {
        query: SHOP,
      },
    ],
    onError: error => {
      message.error(error)
    },
  })

  const create = () => {
    createFunnelBulk({
      variables: {
        funnel: {
          name: onboardingContext.funnel.name,
          tag: onboardingContext.funnel.tag,
          phoneOptional: false,
          shopPaymentGatewayId: onboardingContext.funnel.shopPaymentGatewayId,
          paypalShopPaymentGatewayId: onboardingContext.funnel.paypalShopPaymentGatewayId,
          checkoutTemplate: 'SHOPIFY',
        },
        upsell: onboardingContext.funnel.steps.upsell.cachedProduct
          ? {
              shopifyProductId: onboardingContext.funnel.steps.upsell.cachedProduct.id,
              offerType: onboardingContext.funnel.steps.upsell.offerType,
              offerValue: parseFloat(onboardingContext.funnel.steps.upsell.offerValue),
              shippingCost: 0,
            }
          : undefined,
        downsell: onboardingContext.funnel.steps.downsell.cachedProduct
          ? {
              shopifyProductId: onboardingContext.funnel.steps.downsell.cachedProduct.id,
              offerType: onboardingContext.funnel.steps.downsell.offerType,
              offerValue: parseFloat(onboardingContext.funnel.steps.downsell.offerValue),
              shippingCost: 0,
            }
          : undefined,
      },
    })
  }

  return (
    <div className="">
      <div className="text-2xl text-gray-800 font-semibold text-center">Start Making Money!</div>
      <div className="text-base text-gray-500 text-center mt-2 max-w-xl mx-auto">
        Your funnel is now live! Don't forget to add the funnel product tag to all the products on Shopify that you want
        to trigger this funnel.
      </div>
      <img
        className="mx-auto mt-4"
        src="https://thumbs.gfycat.com/MeatyThickArcherfish-size_restricted.gif"
        alt="wolf"
      />

      <div className="mt-12">
        <OnboardingNavigation nextCallback={create} />
      </div>
    </div>
  )
}

export default withRouter(CompletedStep)
