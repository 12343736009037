import React, { useState, useRef, useEffect } from 'react'
import { Editor, EditorState, ContentState, convertToRaw, convertFromHTML, RichUtils } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

import 'styles/editor.css'

const StyleButton = ({ active, label, style, onToggle }) => {
  const className = `RichEditor-styleButton ${active ? 'RichEditor-activeButton' : ''}`

  const _onToggle = e => {
    e.preventDefault()
    onToggle(style)
  }

  return (
    <span className={className} onMouseDown={_onToggle}>
      {label}
    </span>
  )
}

const BlockStyleControls = ({ editorState, onToggle }) => {
  const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ]

  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

const RichTextEditor = ({ htmlState, onHtmlChange }) => {
  const blocksFromHTML = convertFromHTML(htmlState || '')
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)

  const [editorState, setEditorState] = useState(EditorState.createWithContent(state))
  const [editorClassName, setEditorClassName] = useState('RichEditor-editor')

  const editor = useRef(null)

  useEffect(() => editor.current.focus(), [])

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)
    onHtmlChange(markup)
  }, [editorState, onHtmlChange])

  useEffect(() => {
    const contentState = editorState.getCurrentContent()
    if (
      !contentState.hasText() &&
      contentState
        .getBlockMap()
        .first()
        .getType() !== 'unstyled'
    ) {
      setEditorClassName('RichEditor-editor RichEditor-hidePlaceholder')
    } else {
      setEditorClassName('RichEditor-editor')
    }
  }, [editorState])

  const onChange = state => setEditorState(state)

  const styleMap = {}

  const getBlockStyle = block => {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote'
      default:
        return null
    }
  }

  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onChange(newState)
      return true
    }
    return false
  }

  const onTab = e => onChange(RichUtils.onTab(e, editorState, 4))

  const toggleBlockType = blockType => {
    onChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  return (
    <div className="RichEditor-root">
      <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
      <div className={editorClassName}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          onTab={onTab}
          placeholder="Add a description..."
          ref={editor}
          spellCheck={true}
        />
      </div>
    </div>
  )
}

export default RichTextEditor
