import React, { useState } from 'react'
//import { Provider, ResourcePicker } from '@shopify/app-bridge-react'
import ShopifyProductPicker from 'components/ShopifyProductPicker'
import { useMutation } from '@apollo/client'
import { FUNNEL, CREATE_FUNNEL_STEP } from './GraphQL'
import { message } from 'antd'

const NewFunnelStepCard = ({ funnel, parentFunnelStep, index, isUpsell }) => {
  const [showPicker, setShowPicker] = useState(false)

  const [createFunnelStep] = useMutation(CREATE_FUNNEL_STEP, {
    refetchQueries: [
      {
        query: FUNNEL,
        variables: {
          id: funnel.id,
        },
      },
    ],
    onCompleted: () => {
      message.success('Funnel Step Successfully Created')
    },
    onError: error => {
      message.error(error.message)
    },
  })

  const handleProductSelect = id => {
    createFunnelStep({
      variables: {
        funnelId: funnel.id,
        shopifyProductId: id,
        parentFunnelStepId: parentFunnelStep && !isUpsell ? parentFunnelStep.id : undefined,
      },
    })

    setShowPicker(false)
  }

  return (
    <div className="clearfix">
      <div
        className={
          isUpsell
            ? 'w-full rounded border-2 border-dashed border-gray-400 p-6'
            : 'relative w-11/12 rounded border-2 border-dashed border-gray-400 p-6 float-right'
        }>
        <div className="flex">
          <div className="flex-shrink m-auto">
            <div
              className={`p-2 rounded border border-gray-300 text-center w-auto text-sm font-medium text-gray-600 hover:border-gray-400 cursor-pointer ${
                isUpsell ? '' : '-ml-24'
              }`}
              onClick={() => setShowPicker(true)}>
              Add {isUpsell ? 'Upsell' : 'Downsell'} Offer #{index + 1}
            </div>
          </div>
        </div>
      </div>

      <ShopifyProductPicker open={showPicker} setOpen={setShowPicker} onSelect={handleProductSelect} />
    </div>
  )
}

export default NewFunnelStepCard
