import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import FloatingLabelInput from 'components/FloatingLabelInput'
import {
  TWILIO_NUMBERS,
  SMS_SHORT_LINK_DOMAINS,
  AVAILABLE_NUMBERS,
  CLAIM_NUMBER,
  SMS_AUTOMATIONS,
  CREATE_SMS_AUTOMATION,
} from './GraphQL'
import { Modal, Select, message } from 'antd'

const { Option } = Select

const NewSmsAutomationModal = ({ history, showing, setShowing }) => {
  const [newAutomation, setNewAutomation] = useState({ name: '', trigger: 'ABANDONED_CART', twilioNumberId: null })
  const [updatedDefaultNumber, setUpdatedDefaultNumber] = useState(false)

  const { data: twilioNumbersData, loading: twilioNumbersLoading } = useQuery(TWILIO_NUMBERS)
  const { data: smsShortLinkDomainsData, loading: smsShortLinkDomainsLoading } = useQuery(SMS_SHORT_LINK_DOMAINS)

  const [availableNumbers, { data: availableNumbersData, loading: availableNumbersLoading }] = useLazyQuery(
    AVAILABLE_NUMBERS
  )

  const searchAvailableNumbers = useCallback(() => {
    availableNumbers()
  }, [availableNumbers])

  const [claimNumber] = useMutation(CLAIM_NUMBER, {
    refetchQueries: [
      {
        query: TWILIO_NUMBERS,
      },
    ],
    onCompleted: data => {
      message.success('Successfully claimed!')
      setNewAutomation({ ...newAutomation, twilioNumberId: data.claimNumber.id })
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleClaimNumber = phoneNumber => {
    claimNumber({
      variables: { phoneNumber },
    })
  }

  const [createSmsAutomation] = useMutation(CREATE_SMS_AUTOMATION, {
    refetchQueries: [
      {
        query: SMS_AUTOMATIONS,
      },
    ],
    onCompleted: data => {
      message.success('Automation Successfully Created')
      setShowing(false)
      history.push(`/dashboard/sms-automations/${data.createSmsAutomation.id}`)
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleCreateSmsAutomation = () => {
    createSmsAutomation({
      variables: { smsAutomation: newAutomation, funnelIds: [] },
    })
  }

  useEffect(() => {
    searchAvailableNumbers()
  }, [searchAvailableNumbers])

  useEffect(() => {
    if (newAutomation.twilioNumberId === null) {
      searchAvailableNumbers()
    }
  }, [newAutomation.twilioNumberId, searchAvailableNumbers])

  useEffect(() => {
    if (!updatedDefaultNumber && twilioNumbersData && twilioNumbersData.shop.twilioNumbers[0]) {
      setNewAutomation(current => ({ ...current, twilioNumberId: twilioNumbersData.shop.twilioNumbers[0].id }))
      setUpdatedDefaultNumber(true)
    }
  }, [twilioNumbersData, updatedDefaultNumber])

  useEffect(() => {
    if (smsShortLinkDomainsData && smsShortLinkDomainsData.smsShortLinkDomains[0]) {
      setNewAutomation(current => ({
        ...current,
        smsShortLinkDomainId: smsShortLinkDomainsData.smsShortLinkDomains[0].id,
      }))
    }
  }, [smsShortLinkDomainsData])

  return (
    <Modal
      title="Create New Automation"
      okText="Create"
      onOk={handleCreateSmsAutomation}
      onCancel={() => {
        setShowing(false)
        setNewAutomation(current => ({ ...current, name: '' }))
      }}
      visible={showing}>
      <div className="border-b border-gray-400 pb-6">
        <div className="text-lg font-bold text-gray-800">Trigger</div>
        <div className="text-sm font-light text-gray-600">
          This is the event that starts the beginning of the automation based on your customers behavior.
        </div>
        <div className="mt-2">
          <Select
            style={{ width: '100%' }}
            size="large"
            defaultValue={newAutomation.trigger}
            onChange={value => setNewAutomation({ ...newAutomation, trigger: value })}>
            <Option value="ABANDONED_CART">Abandoned Cart</Option>
          </Select>
        </div>
      </div>

      <div className="border-b border-gray-400 pb-6 mt-6">
        <div className="text-lg font-bold text-gray-800">Name</div>
        <div className="text-sm font-light text-gray-600">Give your SMS Automation a name for internal tracking.</div>
        <div className="mt-2">
          <FloatingLabelInput
            value={newAutomation.name}
            onChange={e => setNewAutomation({ ...newAutomation, name: e.target.value })}
            label="Automation Name"
          />
        </div>
      </div>

      <div className="border-b border-gray-400 pb-6 mt-6">
        <div className="text-lg font-bold text-gray-800">Sending Number</div>
        <div className="text-sm font-light text-gray-600">
          This is the FROM number that your customers will receive your automated messages from, each Better Cart PRO
          account includes their first number at no additional charge. Each additional number is $5/month.
        </div>
        <div className="mt-2">
          <Select
            style={{ width: '100%' }}
            size="large"
            value={newAutomation.twilioNumberId}
            onChange={value => setNewAutomation({ ...newAutomation, twilioNumberId: value })}>
            {!twilioNumbersLoading && twilioNumbersData
              ? twilioNumbersData.shop.twilioNumbers.map(n => (
                  <Option key={n.id} value={n.id}>
                    {n.phoneNumber}
                  </Option>
                ))
              : null}
            <Option value={null}>Claim New Phone Number</Option>
          </Select>
        </div>
        {newAutomation.twilioNumberId === null ? (
          <>
            {availableNumbersLoading ? (
              <div className="text-center text-gray-400">Loading available numbers...</div>
            ) : null}
            {availableNumbersData ? (
              <>
                <div className="flex flex-wrap mt-2">
                  {availableNumbersData.availableNumbers.map((number, index) => {
                    return (
                      <div key={index} className="w-1/2 p-2">
                        <div
                          onClick={() => handleClaimNumber(number.phoneNumber)}
                          className="p-1 text-center border border-gray-500 bg-gray-300 text-gray-800 cursor-pointer rounded-full select-none hover:bg-gray-400">
                          {number.phoneNumber}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="mt-2 mr-2 text-xs text-gray-500 w-full text-right">
                  Tap a bubble to claim your FROM number.
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>

      <div className="mt-6">
        <div className="text-lg font-bold text-gray-800">Link Domain</div>
        <div className="text-sm font-light text-gray-600">
          This is the domain that will be used when generating the resume checkout links within the messages. You can
          choose any domain that you think would have the highest click through rate based on your messaging copy. This
          is totally up to the merchant's preference.
        </div>
        <div className="mt-2">
          <Select
            style={{ width: '100%' }}
            size="large"
            value={newAutomation.smsShortLinkDomainId}
            onChange={value => setNewAutomation({ ...newAutomation, smsShortLinkDomainId: value })}>
            {!smsShortLinkDomainsLoading
              ? smsShortLinkDomainsData.smsShortLinkDomains.map(domain => {
                  return (
                    <Option key={domain.id} value={domain.id}>
                      {domain.domain}
                    </Option>
                  )
                })
              : null}
          </Select>
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(NewSmsAutomationModal)
