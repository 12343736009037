import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { setAuthToken, setCurrentShop } from 'utils/Auth'
import { useCookies } from 'react-cookie'

const COMPLETE_OAUTH_SESSION = gql`
  mutation completeOAuthSession(
    $code: String!
    $hmac: String!
    $host: String
    $timestamp: String!
    $shop: String!
    $state: String!
    $partnerSessionId: String
  ) {
    completeOAuthSession(
      code: $code
      hmac: $hmac
      host: $host
      timestamp: $timestamp
      shop: $shop
      state: $state
      partnerSessionId: $partnerSessionId
    ) {
      user {
        id
        email
      }
      token
      signUp
    }
  }
`

const OAuthCallbackPage = ({ location }) => {
  const { code, hmac, host, timestamp, state, shop } = queryString.parse(location.search)
  const [cookies] = useCookies(['_bc_partner_session_id'])

  setCurrentShop(shop)

  const [completeOAuthSession] = useMutation(COMPLETE_OAUTH_SESSION, {
    update(cache, mutationResult) {
      setAuthToken(shop, mutationResult.data.completeOAuthSession.token)

      if (mutationResult.data.completeOAuthSession.signUp) {
        window.analytics.track("SignUp", {
          shop,
          userId: mutationResult.data.completeOAuthSession.user.id,
          userEmail: mutationResult.data.completeOAuthSession.user.email,
        })
      }

      if (window.top === window.self) {
        window.location.assign(
          `https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_SLUG}/dashboard?token=${mutationResult.data.completeOAuthSession.token}&shop=${shop}`
        )
      } else {
        const app = createApp({
          apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
          shopOrigin: shop,
        })

        Redirect.create(app).dispatch(
          Redirect.Action.APP,
          `/dashboard?token=${mutationResult.data.completeOAuthSession.token}&shop=${shop}`
        )
      }
    },
  })

  useEffect(() => {
    completeOAuthSession({
      variables: { code, hmac, host, timestamp, state, shop, partnerSessionId: cookies._bc_partner_session_id },
    })
  }, [completeOAuthSession, code, hmac, host, timestamp, state, shop, cookies._bc_partner_session_id])

  return <div>Authenticating...</div>
}

export default withRouter(OAuthCallbackPage)
