import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { SOLD_OUT_VARIANTS, DESTROY_SOLD_OUT_VARIANT } from './GraphQL'

const SoldOutVariants = () => {
  const { data, loading } = useQuery(SOLD_OUT_VARIANTS)

  const [destroy, { destroyLoading }] = useMutation(DESTROY_SOLD_OUT_VARIANT, {
    refetchQueries: [
      {
        query: SOLD_OUT_VARIANTS,
      },
    ],
    onError: () => {},
    onCompleted: () => {},
  })

  const handleDestroy = (id) => {
    destroy({
      variables: {
        id,
      },
    })
  }

  return (
    <div className="flex flex-wrap items-start justify-between">
      {loading ? (
        <div className="text-gray-400">Loading...</div>
      ) : (
        <div className="w-full p-4 bg-white border-2 border-gray-200 rounded shadow-xl">
          {data.soldOutVariants.map(variant => (
            <div className="flex items-center justify-between w-full pb-2 mb-2 text-gray-800 border-b border-gray-200" key={variant.id}>
              <div>
                {variant.productVariant.product.title} - {variant.productVariant.title}
              </div>
              <div>
                <div
                  className="px-4 py-2 ml-4 text-center text-gray-800 border border-gray-400 rounded cursor-pointer select-none hover:bg-gray-200"
                  onClick={() => destroyLoading ? null : handleDestroy(variant.id)}>
                  {destroyLoading ? "Loading..." : "Restock"}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SoldOutVariants
