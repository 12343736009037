import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import DateRangeButton from 'components/DateRangeButton'
import ChartGrid from 'components/charts/ChartGrid'
import moment from 'moment'

const SHOP = gql`
  query SHOP {
    shop {
      id
      enabled
      billingPlanType
      billingPlanStatus

      funnels {
        id
        name
      }
    }
  }
`

const DashboardPage = ({ history }) => {
  const { data } = useQuery(SHOP)

  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  })

  const handleDateRangeChange = dateRange => {
    setDateRange(dateRange)
  }

  useEffect(() => {
    if (!window.localStorage.getItem('closed-wizard')) {
      if (data && data.shop.funnels.length < 1) {
        history.push('/dashboard/onboarding/welcome')
      } else if (data && data.shop.billingPlanType === 'LITE' && data.shop.billingPlanStatus === 'ACTIVE') {
        history.push('/dashboard/onboarding/welcome')
      }
    }
  }, [data, history])

  return (
    <div>
      <div className="mx-4 mt-6">
        <div className="flex items-center w-full">
          <div className="flex-grow my-2 text-2xl font-semibold text-gray-800">Overview dashboard</div>
          <div className="flex-shrink">
            <DateRangeButton dateRange={dateRange} onChange={handleDateRangeChange} />
          </div>
        </div>

        <ChartGrid startDate={dateRange.startDate} endDate={dateRange.endDate} />
      </div>
    </div>
  )
}

export default withRouter(DashboardPage)
