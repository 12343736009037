import React from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { message, Popconfirm } from 'antd'
import { FaCog, FaCopy, FaTrash } from 'react-icons/fa'
import { FiLoader } from 'react-icons/fi'
import { FUNNELS, DUPLICATE_FUNNEL, DESTROY_FUNNEL } from './GraphQL'

const FunnelActions = ({ funnel }) => {
  const [duplicateFunnel, { loading: duplicateLoading }] = useMutation(DUPLICATE_FUNNEL, {
    refetchQueries: [
      {
        query: FUNNELS,
      },
    ],
    onCompleted: () => {
      message.success('Funnel Successfully Duplicated')
    },
    onError: error => {
      message.error(error.message)
    },
  })

  const [destroyFunnel, { loading: destroyLoading }] = useMutation(DESTROY_FUNNEL, {
    refetchQueries: [
      {
        query: FUNNELS,
      },
    ],
    onCompleted: () => {
      message.success('Funnel Successfully Deleted')
    },
    onError: error => {
      message.error(error.message)
    },
  })

  const handleFunnelDuplicate = () => {
    duplicateFunnel({
      variables: { id: funnel.id },
    })
  }

  const handleFunnelDelete = () => {
    destroyFunnel({
      variables: { id: funnel.id },
    })
  }

  return (
    <div className="flex content-center">
      <div className="flex-1">
        <Link to={`/dashboard/funnels/${funnel.id}/settings`}>
          <FaCog size="1.5em" className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500" />
        </Link>
      </div>
      <div className="flex-1">
        {duplicateLoading ? (
          <FiLoader
            size="1.5em"
            className="m-auto text-gray-400 cursor-pointer fill-current icon-spin hover:text-gray-500"
          />
        ) : (
          <FaCopy
            size="1.5em"
            className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500"
            onClick={handleFunnelDuplicate}
          />
        )}
      </div>
      <div className="flex-1">
        {destroyLoading ? (
          <FiLoader
            size="1.5em"
            className="m-auto text-gray-400 cursor-pointer fill-current icon-spin hover:text-gray-500"
          />
        ) : (
          <Popconfirm
            title="Are you sure delete this funnel?"
            onConfirm={handleFunnelDelete}
            okText="Yes"
            okType="danger"
            placement="left"
            cancelText="Cancel">
            <FaTrash size="1.5em" className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500" />
          </Popconfirm>
        )}
      </div>
    </div>
  )
}

export default FunnelActions
