import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_SHOP_PAYMENT_GATEWAY, SHOP_PAYMENT_GATEWAYS } from '../GraphQL'
import { Input } from 'antd'

const BraintreeForm = ({ onClose }) => {
  const [merchantId, setMerchantId] = useState('')
  const [publicKey, setPublicKey] = useState('')
  const [privateKey, setPrivateKey] = useState('')

  const [createShopPaymentGateway, { error, loading }] = useMutation(CREATE_SHOP_PAYMENT_GATEWAY, {
    refetchQueries: [
      {
        query: SHOP_PAYMENT_GATEWAYS,
      },
    ],
    onCompleted: data => {
      if (!error) {
        onClose(data.createShopPaymentGateway.id)
      }
    },
  })

  const handleCreate = () => {
    createShopPaymentGateway({
      variables: {
        slug: 'braintree',
        fields: {
          merchant_id: merchantId,
          public_key: publicKey,
          private_key: privateKey,
        },
      },
    })
  }

  return (
    <div>
      <Input size="large" placeholder="Merchant ID" value={merchantId} onChange={e => setMerchantId(e.target.value)} />
      <Input
        className="mt-4"
        size="large"
        placeholder="Public Key"
        value={publicKey}
        onChange={e => setPublicKey(e.target.value)}
      />
      <Input
        className="mt-4"
        size="large"
        placeholder="Private Key"
        value={privateKey}
        onChange={e => setPrivateKey(e.target.value)}
      />
      {loading ? (
        <div className="mt-4 p-2 text-sm text-white text-center bg-gray-500 rounded hover:bg-gray-600">Loading...</div>
      ) : (
        <div
          className="mt-4 p-2 text-sm text-white text-center bg-gray-800 cursor-pointer rounded hover:bg-gray-700"
          onClick={handleCreate}>
          Connect
        </div>
      )}
    </div>
  )
}

export default BraintreeForm
