import gql from 'graphql-tag'

export const FUNNEL_STEP = gql`
  query funnelStep($funnelId: ID!, $id: ID!) {
    funnelStep(funnelId: $funnelId, id: $id) {
      id
      shopifyProductId
      offerType
      offerValue
      shippingCost
      enabled
      shopifyProductId
      position
      enableQuantity

      parentFunnelStep {
        id
      }

      childFunnelStep {
        id
      }

      product {
        id
        title
      }

      draftDesign {
        id
        status
        productTitle
        productTitleHtml
        headline
        headlineBgColor
        headlineColor
        offerHeadline
        offerSubHeadline
        offerDescription
        timerText
        timerTextColor
        timerBgColor
        timerColor
        wasLabel
        nowLabel
        nowColor
        saveLabel
        saveColor
        variantPlaceholder
        confirmText
        confirmTextColor
        confirmBgColor
        declineText
        declineColor
        highlightBannerText
        highlightBannerColor
        highlightBannerBgColor
        productDetails
        descriptionHtml
        enableConfetti
      }

      publishedDesign {
        id
        createdAt
      }

      funnel {
        id

        shop {
          id
          shopifyHostname
        }
      }
    }
  }
`

export const UPDATE_FUNNEL_STEP = gql`
  mutation updateFunnelStep($funnelId: ID!, $id: ID!, $funnelStep: UpdateFunnelStepInput!) {
    updateFunnelStep(funnelId: $funnelId, id: $id, funnelStep: $funnelStep) {
      id
      enableQuantity
    }
  }
`

export const PUBLISH_FUNNEL_STEP_DESIGN = gql`
  mutation publishFunnelStepDesign($funnelId: ID!, $funnelStepId: ID!) {
    publishFunnelStepDesign(funnelId: $funnelId, funnelStepId: $funnelStepId) {
      id
      status
    }
  }
`

export const UPDATE_FUNNEL_STEP_DESIGN = gql`
  mutation updateFunnelStepDesign($funnelId: ID!, $funnelStepId: ID!, $funnelStepDesign: UpdateFunnelStepDesignInput!) {
    updateFunnelStepDesign(funnelId: $funnelId, funnelStepId: $funnelStepId, funnelStepDesign: $funnelStepDesign) {
      id
      status
    }
  }
`
