import React from 'react'
import OnboardingNavigation from 'components/OnboardingNavigation'

const WelcomeStep = () => {
  return (
    <>
      <div className="text-2xl text-gray-800 font-semibold text-center">Welcome To Better Cart!</div>
      <div className="text-base text-gray-500 text-center mt-2 max-w-xl mx-auto">
        Firstly, thanks so much for signing up for Better Cart, we are just as excited as you! Total setup time will
        only require about 5 minutes! We make it super easy to be up and running in just a few clicks.
      </div>
      <div className="mt-12">
        <OnboardingNavigation />
      </div>
    </>
  )
}

export default WelcomeStep
