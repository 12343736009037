import React from 'react'
import InputMask from 'react-input-mask'

const FloatingLabelInput = ({ label, placeholder, value, errors = [], ...props }) => {
  const hasErrors = errors.length > 0

  return (
    <div className="relative w-full">
      <label className={`absolute mt-1 mb-2 px-3 text-xs w-full text-gray-600 ${value ? 'inline-block' : 'hidden'}`}>
        {label}
      </label>
      <InputMask
        {...props}
        value={value || ''}
        placeholder={placeholder ? placeholder : label}
        className={`border rounded border-gray-400 w-full p-3 outline-none shadow-none placeholder-gray-500 focus:shadow-inputoutline focus:border-gray-800 ${
          value ? 'pt-5 pb-1' : ''
        } ${hasErrors ? 'border-red-400 focus:shadow-none' : ''}`}
      />
      {hasErrors ? <div className="text-sm text-red-400">{errors.map(e => e.message).join(', ')}</div> : null}
    </div>
  )
}

export default FloatingLabelInput
