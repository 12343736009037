import gql from 'graphql-tag'

export const FUNNEL = gql`
  query funnel($id: ID!) {
    funnel(id: $id) {
      id
      name
      tag
      phoneOptional
      enabled
      checkoutTemplate
      bumpProductDescription
      showSummary
      showExpressCheckouts
      showTimer
      timerDescription
      timerText
      timerBgColor
      timerTextColor

      shopPaymentGateway {
        id
      }

      paypalShopPaymentGateway {
        id
      }

      shop {
        id
        billingPlanType

        shopPaymentGateways {
          id
          nickname

          paymentGateway {
            id
            name
            logo
            slug
            gatewayType
          }
        }
      }
    }
  }
`

export const UPDATE_FUNNEL = gql`
  mutation updateFunnel($id: ID!, $funnel: UpdateFunnelInput!) {
    updateFunnel(id: $id, funnel: $funnel) {
      id
    }
  }
`
