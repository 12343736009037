import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Table from 'components/Table'
import ContentWrapper from 'components/ContentWrapper'
import { Switch, message } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { SHIPPING_ZONES, UPDATE_SHIPPING_ZONE, DESTROY_SHIPPING_ZONE } from './GraphQL'
import NewShippingZoneModal from './NewShippingZoneModal'
import UpdateShippingZoneModal from './UpdateShippingZoneModal'
import { FaCog, FaEdit, FaTrash } from 'react-icons/fa'
import { FiLoader } from 'react-icons/fi'

const Actions = ({ zone }) => {
  const { id } = zone

  const [showZoneModal, setShowZoneModal] = useState(false)

  const [destroyShippingZone, { loading: destroyLoading }] = useMutation(DESTROY_SHIPPING_ZONE, {
    refetchQueries: [
      {
        query: SHIPPING_ZONES,
      },
    ],
    onCompleted: () => {
      message.success('Zone Successfully Deleted')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleDestroy = () => {
    destroyShippingZone({
      variables: {
        id,
      },
    })
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="flex-shrink">
          <Link to={`/dashboard/shipping-zones/${id}/rates`}>
            <FaCog size="1.5em" className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500" />
          </Link>
        </div>
        <div className="flex-shrink ml-3">
          <FaEdit
            size="1.5em"
            className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500"
            onClick={() => setShowZoneModal(true)}
          />
        </div>
        <div className="flex-shrink ml-3">
          {destroyLoading ? (
            <FiLoader
              size="1.5em"
              className="m-auto text-gray-400 cursor-pointer fill-current icon-spin hover:text-gray-500"
            />
          ) : (
            <FaTrash
              size="1.5em"
              className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500"
              onClick={handleDestroy}
            />
          )}
        </div>
      </div>
      <UpdateShippingZoneModal showing={showZoneModal} setShowing={setShowZoneModal} zone={zone} />
    </>
  )
}

const ShippingZonesPage = ({ match }) => {
  const [showNewZoneModal, setShowNewZoneModal] = useState(false)
  const { loading, error, data } = useQuery(SHIPPING_ZONES)

  const [updateShippingZone] = useMutation(UPDATE_SHIPPING_ZONE, {
    refetchQueries: [
      {
        query: SHIPPING_ZONES,
      },
    ],
    onCompleted: () => {
      message.success('Zone Successfully Updated')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleStatusChange = (id, value) => {
    updateShippingZone({
      variables: { id, shippingZone: { enabled: value } },
    })
  }

  const columns = [
    {
      title: 'Zone Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link to={`${match.url}/${record.id}/rates`} className="hover:underline hover:text-gray-800">
          {text}
        </Link>
      ),
    },
    {
      title: 'Contries',
      dataIndex: 'contries',
      key: 'countries',
      render: (text, record) => (
        <div>{Object.keys(record.countries).includes('*') ? 'ALL' : Object.keys(record.countries).length}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text, record) => (
        <Switch checked={record.enabled} onChange={value => handleStatusChange(record.id, value)} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => <Actions zone={record} />,
    },
  ]

  return (
    <div className="w-full">
      <div className="m-4">
        <div className="flex items-center">
          <div className="flex-1 mb-2 text-2xl font-light text-gray-800 align-top">Shipping Zones</div>
          <div className="flex-shrink">
            <div
              onClick={() => setShowNewZoneModal(true)}
              className="w-auto p-2 mb-2 text-center text-gray-300 bg-gray-800 rounded cursor-pointer hover:bg-gray-700">
              New Shipping Zone
            </div>
          </div>
        </div>

        {loading ? (
          <div className="w-full text-center text-gray-500">Loading...</div>
        ) : (
          <>
            {!data || !data.shippingZones || data.shippingZones.length < 1 ? (
              <>
                <div className="flex items-center justify-center w-full">
                  <img className="w-1/3 mt-16" src="/images/shipping.svg" alt="shipping" />
                </div>
                <div className="w-full">
                  <div className="w-3/5 mx-auto mt-6 text-lg text-center text-gray-800">
                    Create custom shipping zones and rates in Better Cart for a more robust shipping setup. When using
                    our shipping zones, checkouts load faster and have a higher conversion rate.
                  </div>
                  <div
                    onClick={() => setShowNewZoneModal(true)}
                    className="w-1/3 px-6 py-4 mx-auto mt-6 text-lg font-light text-center text-white bg-gray-800 rounded cursor-pointer select-none hover:bg-gray-700">
                    Create Your First Shipping Zone
                  </div>
                </div>
              </>
            ) : (
              <ContentWrapper>
                <Table columns={columns} dataSource={data ? data.shippingZones : []} loading={loading} error={error} />
              </ContentWrapper>
            )}
          </>
        )}
      </div>

      <NewShippingZoneModal showing={showNewZoneModal} setShowing={setShowNewZoneModal} />
    </div>
  )
}

export default withRouter(ShippingZonesPage)
