import gql from 'graphql-tag'

export const SHIPPING_ZONES = gql`
  query shippingZones {
    shippingZones {
      id
      name
      countries
      enabled

      shippingRates {
        id
        name
        price
        conditionType
        conditionMin
        conditionMax
        enabled
      }
    }
  }
`

export const SHIPPING_ZONE = gql`
  query shippingZone($id: ID!) {
    shippingZone(id: $id) {
      id
      name
      countries
      enabled

      shippingRates {
        id
        name
        price
        conditionType
        conditionMin
        conditionMax
        enabled
      }
    }
  }
`

export const SHIPPING_RATES = gql`
  query shippingRates($shippingZoneId: ID!) {
    shippingRates(shippingZoneId: $shippingZoneId) {
      id
      name
      price
      conditionType
      conditionMin
      conditionMax
      enabled
    }
  }
`

export const SHIPPING_RATE = gql`
  query shippingRates($shippingZoneId: ID!, $id: ID!) {
    shippingRate(shippingZoneId: $shippingZoneId, id: $id) {
      id
      name
      price
      conditionType
      conditionMin
      conditionMax
      enabled
    }
  }
`

export const CREATE_SHIPPING_ZONE = gql`
  mutation createShippingZone($shippingZone: CreateShippingZoneInput!) {
    createShippingZone(shippingZone: $shippingZone) {
      id
    }
  }
`

export const UPDATE_SHIPPING_ZONE = gql`
  mutation updateShippingZone($id: ID!, $shippingZone: UpdateShippingZoneInput!) {
    updateShippingZone(id: $id, shippingZone: $shippingZone) {
      id
    }
  }
`

export const DESTROY_SHIPPING_ZONE = gql`
  mutation destroyShippingZone($id: ID!) {
    destroyShippingZone(id: $id) {
      id
    }
  }
`

export const CREATE_SHIPPING_RATE = gql`
  mutation createShippingRate($shippingZoneId: ID!, $shippingRate: CreateShippingRateInput!) {
    createShippingRate(shippingZoneId: $shippingZoneId, shippingRate: $shippingRate) {
      id
    }
  }
`

export const UPDATE_SHIPPING_RATE = gql`
  mutation updateShippingRate($shippingZoneId: ID!, $id: ID!, $shippingRate: UpdateShippingRateInput!) {
    updateShippingRate(shippingZoneId: $shippingZoneId, id: $id, shippingRate: $shippingRate) {
      id
    }
  }
`

export const DESTROY_SHIPPING_RATE = gql`
  mutation destroyShippingRate($shippingZoneId: ID!, $id: ID!) {
    destroyShippingRate(shippingZoneId: $shippingZoneId, id: $id) {
      id
    }
  }
`
