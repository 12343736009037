import React, { useState, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_SMS_MESSAGE, DESTROY_SMS_MESSAGE, SMS_AUTOMATION } from './GraphQL'
import NumberFormat from 'components/NumberFormat'
import RateFormat from 'components/RateFormat'
import CurrencyFormat from 'react-currency-format'
import { Modal, Menu, Dropdown, message } from 'antd'
import { FaEllipsisH, FaBolt } from 'react-icons/fa'
import SmsMessageForm from './forms/SmsMessageForm'

const MessageCard = ({ smsAutomation, smsMessage }) => {
  const [showModal, setShowModal] = useState(false)
  const [localSmsMessage, setLocalSmsMessage] = useState({
    body: smsMessage.body,
    delayUnit: smsMessage.delayUnit,
    delayValue: smsMessage.delayValue,
    image: smsMessage.image,
    imageSrc: smsMessage.imageSrc,
  })

  const [updateSmsMessage] = useMutation(UPDATE_SMS_MESSAGE, {
    refetchQueries: [
      {
        query: SMS_AUTOMATION,
        variables: {
          id: smsAutomation.id,
        },
      },
    ],
    onCompleted: () => {
      setShowModal(false)
      message.success('Message Successfully Updated')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const [destroySmsMessage] = useMutation(DESTROY_SMS_MESSAGE, {
    refetchQueries: [
      {
        query: SMS_AUTOMATION,
        variables: {
          id: smsAutomation.id,
        },
      },
    ],
    onCompleted: () => {
      message.success('Message Successfully Removed')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleUpdateSmsMessage = () => {
    updateSmsMessage({
      variables: {
        smsAutomationId: smsAutomation.id,
        id: smsMessage.id,
        smsMessage: localSmsMessage,
      },
    })
  }

  const handleDestroy = () => {
    destroySmsMessage({
      variables: {
        smsAutomationId: smsAutomation.id,
        id: smsMessage.id,
      },
    })
  }

  const delay = useMemo(() => {
    switch (smsMessage.delayUnit) {
      case 'MINUTE': {
        return 'Mins'
      }
      case 'HOUR': {
        return 'Hours'
      }
      case 'DAY': {
        return 'Days'
      }
      default: {
        return ''
      }
    }
  }, [smsMessage.delayUnit])

  const optionsMenu = (
    <Menu>
      <Menu.Item className="hover:bg-white">
        <div onClick={() => setShowModal(true)} className="text-sm text-gray-800 hover:underline">
          Edit Message
        </div>
      </Menu.Item>
      <Menu.Item className="hover:bg-white">
        <div onClick={handleDestroy} className="text-sm text-red-700 hover:underline">
          Remove Message
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div className="flex items-center justify-end mb-1 mr-2 text-xs text-gray-500">
        <div>
          <FaBolt />
        </div>
        <div>
          {smsMessage.delayValue} {delay}
        </div>
      </div>

      <div className="flex w-full rounded shadow-xl border-2 border-gray-200 overflow-hidden bg-white">
        <div className="flex-shrink m-4 w-1/5">
          <div className="text-gray-800 font-semibold">Body</div>
          <div className="flex flex-col content-between items-stretch h-full ">{smsMessage.body}</div>
        </div>

        <div className="flex-grow m-4">
          <div className="flex flex-wrap">
            <div className="flex-grow w-full flex content-between items-stretch h-full text-center mb-2">
              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <NumberFormat value={smsMessage.sendCount} />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">Sends</div>
              </div>

              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <RateFormat value={Math.floor(smsMessage.clickCount / smsMessage.sendCount) || 0} />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">CTR</div>
              </div>

              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <NumberFormat value={smsMessage.clickCount} />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">Clicks</div>
              </div>

              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <RateFormat value={smsMessage.conversionRate} />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">Conversion</div>
              </div>
            </div>

            <div className="flex-grow w-full flex content-between items-stretch h-full text-center mt-2">
              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <CurrencyFormat
                    value={smsMessage.revenue}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">Revenue</div>
              </div>

              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <CurrencyFormat
                    value={smsMessage.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">Cost</div>
              </div>

              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <RateFormat value={Math.round((smsMessage.revenue / smsMessage.price) * 100) / 100 || 0} />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">ROI</div>
              </div>

              <div className="flex-1 self-center">
                <div className="text-base text-gray-800 font-semibold mb-1">
                  <CurrencyFormat
                    value={Math.round((smsMessage.revenue / smsMessage.sendCount) * 100) / 100 || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                </div>
                <div className="text-xs text-gray-600 font-light mt-1">$/Send</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-shrink m-4 text-right">
          <Dropdown overlay={optionsMenu}>
            <div className="cursor-pointer text-gray-400 hover:text-gray-600">
              <FaEllipsisH className="text-base" />
            </div>
          </Dropdown>

          <Modal
            visible={showModal}
            title="Edit Message"
            okText="Save"
            onOk={handleUpdateSmsMessage}
            onCancel={() => setShowModal(false)}>
            <SmsMessageForm smsMessage={localSmsMessage} setSmsMessage={setLocalSmsMessage} />
          </Modal>
        </div>
      </div>
    </>
  )
}

export default MessageCard
