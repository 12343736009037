import React, { useMemo } from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Tag, Tooltip, message } from 'antd'
import { FaRegCheckSquare, FaTrash } from 'react-icons/fa'

export const SHOP_BILLING = gql`
  query shopBilling {
    shop {
      id

      billingPaymentMethods {
        id
        brand
        last4
        expMonth
        expYear
        primary
      }
    }
  }
`

const UPDATE_PAYMENT_METHOD = gql`
  mutation BillingUpdatePaymentMethod($id: ID!, $paymentMethod: UpdateBillingPaymentMethodInput!) {
    billingUpdatePaymentMethod(id: $id, paymentMethod: $paymentMethod) {
      id
      brand
      last4
      expMonth
      expYear
      primary
    }
  }
`

const DESTROY_PAYMENT_METHOD = gql`
  mutation BillingDestroyPaymentMethod($id: ID!) {
    billingDestroyPaymentMethod(id: $id) {
      id
    }
  }
`

const PaymentMethod = ({ method }) => {
  const { id, brand, last4, expMonth, expYear, primary } = method

  const [update] = useMutation(UPDATE_PAYMENT_METHOD, {
    refetchQueries: [{ query: SHOP_BILLING }],
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const [destroy] = useMutation(DESTROY_PAYMENT_METHOD, {
    refetchQueries: [{ query: SHOP_BILLING }],
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const cardUrl = useMemo(() => {
    switch (brand) {
      case 'American Express': {
        return '/images/amex.svg'
      }
      case 'Dinners Club': {
        return '/images/diners.svg'
      }
      case 'Discover': {
        return '/images/discover.svg'
      }
      case 'JCB': {
        return '/images/jcb.svg'
      }
      case 'MasterCard': {
        return '/images/mastercard.svg'
      }
      case 'Visa': {
        return '/images/visa.svg'
      }
      case 'Unknown': {
        return '/images/generic.svg'
      }
      default: {
        return '/images/generic.svg'
      }
    }
  }, [brand])

  const handleMakeDefault = () => {
    update({
      variables: {
        id,
        paymentMethod: {
          primary: true,
        },
      },
    })
  }

  const handleDestroy = () => {
    if (primary) {
      message.error(
        'Default cards can not be removed, please select an alternate card as the default before removing this card.'
      )
    } else {
      destroy({
        variables: {
          id,
        },
      })
    }
  }

  return (
    <div className="flex items-center h-full">
      <img className="h-10" alt="card" src={cardUrl} />
      <div className="ml-4 text-gray-800">••••</div>
      <div className="ml-4 text-gray-800">{last4}</div>
      <div className="ml-4 text-gray-800">
        {expMonth} / {expYear}
      </div>
      {primary ? (
        <Tag className="ml-6" color="#48bb78">
          Default
        </Tag>
      ) : null}
      <div className="flex-grow text-right mr-4">
        <div className="flex items-center justify-end">
          {!primary ? (
            <div className="mr-4">
              <Tooltip placement="left" title="Make Default">
                <FaRegCheckSquare
                  size="1.6em"
                  className="m-auto fill-current text-gray-400 hover:text-gray-500 cursor-pointer"
                  onClick={handleMakeDefault}
                />
              </Tooltip>
            </div>
          ) : null}
          <div>
            <FaTrash
              size="1.5em"
              className="m-auto fill-current text-gray-400 hover:text-gray-500 cursor-pointer"
              onClick={handleDestroy}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethod
