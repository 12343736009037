import React, { createContext, useReducer, useEffect } from 'react'

const OnboardingContext = createContext()

const steps = [
  {
    position: 0,
    title: 'Welcome',
    nextTitle: 'Get Started',
    id: 'welcome',
  },
  {
    position: 1,
    title: 'Branding',
    nextTitle: 'Next',
    id: 'shop',
  },
  {
    position: 2,
    title: 'Funnel',
    nextTitle: 'Next',
    id: 'funnel',
  },
  {
    position: 3,
    title: 'Gateways',
    nextTitle: 'Next',
    id: 'gateway',
  },
  {
    position: 4,
    title: 'Free Trial',
    nextTitle: 'Start My Free Trial',
    id: 'billing',
  },
  {
    position: 5,
    title: 'Launch',
    nextTitle: 'Launch My Funnel',
    id: 'completed',
  },
]

const initFunnel = {
  name: '',
  tag: '',
  shopPaymentGatewayId: null,
  paypalShopPaymentGatewayId: null,
  steps: {
    upsell: {
      offerType: 'PERCENTAGE',
      offerValue: 25,
      cachedProduct: null,
    },
    downsell: {
      offerType: 'PERCENTAGE',
      offerValue: 25,
      cachedProduct: null,
    },
  },
}

const OnboardingContextProvider = ({ children }) => {
  const saveState = state => {
    return window.localStorage.setItem('onboarding', JSON.stringify(state))
  }

  const loadState = () => {
    if (window.localStorage.getItem('onboarding')) {
      return JSON.parse(window.localStorage.getItem('onboarding'))
    } else {
      const init = {
        steps,
        currentStep: null,
        funnel: initFunnel,
        planActive: false,
        errors: [],
      }
      saveState(init)
      return init
    }
  }

  const initialState = loadState()

  const getNextStep = currentStep => {
    return (
      steps.find(s => s.position === currentStep.position + 1) ||
      steps.sort((a, b) => a.position - b.position)[steps.length - 1]
    )
  }

  const getPrevStep = currentStep => {
    return steps.find(s => s.position === currentStep.position - 1) || steps.sort((a, b) => a.position - b.position)[0]
  }

  const getStepById = id => steps.find(s => s.id === id) || steps.sort((a, b) => a.position - b.position)[0]

  const validateNext = state => {
    switch (state.currentStep.id) {
      case 'welcome':
      case 'shop':
      case 'completed': {
        return false
      }
      case 'funnel': {
        if (state.funnel.name.length < 1 || state.funnel.tag.length < 1) {
          return [{ message: 'Missing funnel name or tag.' }]
        } else {
          return false
        }
      }
      case 'gateway': {
        if (!state.funnel.shopPaymentGatewayId) {
          return [{ message: 'Please select a credit card gateway.' }]
        } else {
          return false
        }
      }
      case 'billing': {
        if (!state.planActive) {
          return [{ message: 'Please add a payment method and activate your trial' }]
        } else {
          return false
        }
      }
      default: {
        return false
      }
    }
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'NEXT': {
        const errors = validateNext(state)
        if (errors) {
          return { ...state, errors, currentStep: state.currentStep }
        } else {
          return { ...state, errors: [], currentStep: getNextStep(state.currentStep) }
        }
      }
      case 'PREV': {
        return { ...state, currentStep: getPrevStep(state.currentStep) }
      }
      case 'SET_STEP_BY_ID': {
        return { ...state, currentStep: getStepById(action.id) }
      }
      case 'UPDATE_FUNNEL_NAME': {
        return { ...state, funnel: { ...state.funnel, name: action.name } }
      }
      case 'UPDATE_FUNNEL_TAG': {
        return { ...state, funnel: { ...state.funnel, tag: action.tag } }
      }
      case 'REMOVE_UPSELL': {
        return {
          ...state,
          funnel: {
            ...state.funnel,
            steps: {
              ...state.funnel.steps,
              upsell: {
                offerType: 'PERCENTAGE',
                offerValue: 25,
                cachedProduct: null,
              },
            },
          },
        }
      }
      case 'REMOVE_DOWNSELL': {
        return {
          ...state,
          funnel: {
            ...state.funnel,
            steps: {
              ...state.funnel.steps,
              downsell: {
                offerType: 'PERCENTAGE',
                offerValue: 25,
                cachedProduct: null,
              },
            },
          },
        }
      }
      case 'UPDATE_FUNNEL_SHOP_GATEWAY': {
        return { ...state, funnel: { ...state.funnel, shopPaymentGatewayId: action.id } }
      }
      case 'UPDATE_FUNNEL_PAYPAL_GATEWAY': {
        return { ...state, funnel: { ...state.funnel, paypalShopPaymentGatewayId: action.id } }
      }
      case 'UPDATE_FUNNEL_STEPS': {
        return { ...state, funnel: { ...state.funnel, steps: action.steps } }
      }
      case 'UPDATE_PLAN': {
        return { ...state, planActive: action.planActive }
      }
      case 'LAUNCH_FUNNEL': {
        return { steps, currentStep: null, funnel: initFunnel, errors: [], planActive: true }
      }
      case 'UPDATE_ERRORS': {
        return { ...state, errors: action.errors }
      }
      default:
        return state
    }
  }

  const [onboardingContext, onboardingDispatch] = useReducer(reducer, initialState)
  const value = { onboardingContext, onboardingDispatch }

  useEffect(() => {
    saveState(onboardingContext)
  }, [onboardingContext])

  useEffect(() => {
    if (onboardingContext.errors.length > 0) {
      setTimeout(() => {
        onboardingDispatch({ type: 'UPDATE_ERRORS', errors: [] })
      }, 3000)
    }
  }, [onboardingContext.errors])

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}

const OnboardingContextConsumer = OnboardingContext.Consumer

export { OnboardingContext, OnboardingContextProvider, OnboardingContextConsumer }
