import React, { useState } from 'react'
import { Modal, Button, message } from 'antd'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { useMutation } from '@apollo/client'
import { CREATE_SHIPPING_RATE, SHIPPING_RATES } from './GraphQL'

const NewShippingRateModal = ({ showing, setShowing, zoneId }) => {
  const [rate, setRate] = useState({
    name: '',
    price: 0,
    conditionType: 'NONE',
    conditionMin: '',
    conditionMax: '',
  })

  const [hasCondition, setHasCondition] = useState(false)

  const [createRate, { loading }] = useMutation(CREATE_SHIPPING_RATE, {
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
    onCompleted: () => {
      message.success('Successfully created new shipping rate!')
      setShowing(false)
    },
    refetchQueries: [
      {
        query: SHIPPING_RATES,
        variables: {
          shippingZoneId: zoneId,
        },
      },
    ],
  })

  const handleCreateShippingRate = () => {
    createRate({
      variables: {
        shippingZoneId: zoneId,
        shippingRate: {
          name: rate.name,
          price: rate.price === '' ? 0 : parseFloat(rate.price),
          conditionType: rate.conditionMin === '' && rate.conditionMax === '' ? 'NONE' : 'PRICE',
          conditionMin: rate.conditionMin === '' ? null : parseFloat(rate.conditionMin),
          conditionMax: rate.conditionMax === '' ? null : parseFloat(rate.conditionMax),
        },
      },
    })
  }

  return (
    <Modal
      title="Create New Rate"
      okText="Create"
      onOk={handleCreateShippingRate}
      onCancel={() => {
        setShowing(false)
      }}
      visible={showing}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setShowing(false)
          }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleCreateShippingRate}>
          Create
        </Button>,
      ]}>
      <div>
        <div className="flex tems-center">
          <div className="w-1/2 pr-2">
            <FloatingLabelInput
              label="Rate Name"
              value={rate.name}
              onChange={e => setRate({ ...rate, name: e.target.value })}
            />
            <div className="mt-1 ml-1 text-xs text-gray-500">Customers will see this at checkout</div>
          </div>
          <div className="w-1/2 pl-2">
            <FloatingLabelInput
              label="Rate Price"
              value={rate.price}
              onChange={e => setRate({ ...rate, price: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4">
          <div
            className="text-gray-800 underline cursor-pointer select-none hover:text-gray-700"
            onClick={() => setHasCondition(c => !c)}>
            {hasCondition ? 'Remove Condition' : 'Add Condition'}
          </div>
        </div>
        {hasCondition ? (
          <div className="mt-4">
            <div className="flex tems-center">
              <div className="w-1/2 pr-2">
                <FloatingLabelInput
                  label="Mininum price"
                  value={rate.conditionMin}
                  onChange={e => setRate({ ...rate, conditionMin: e.target.value })}
                />
              </div>
              <div className="w-1/2 pl-2">
                <FloatingLabelInput
                  label="Maximum price"
                  value={rate.conditionMax}
                  onChange={e => setRate({ ...rate, conditionMax: e.target.value })}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default NewShippingRateModal
