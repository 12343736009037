import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import ShippingZonesPage from 'pages/dashboard/shipping-zones/ShippingZonesPage'
import ShippingRatesPage from 'pages/dashboard/shipping-zones/ShippingRatesPage'

const ShippingZonesRouter = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={ShippingZonesPage} />
      <Route path={`${match.url}/:zoneId/rates`} component={ShippingRatesPage} />
    </Switch>
  )
}

export default withRouter(ShippingZonesRouter)
