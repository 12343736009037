import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Table from 'components/Table'
import ContentWrapper from 'components/ContentWrapper'
import { useQuery, useMutation } from '@apollo/client'
import { SHIPPING_RATES, UPDATE_SHIPPING_RATE, DESTROY_SHIPPING_RATE } from './GraphQL'
import { FaEdit, FaTrash, FaChevronLeft } from 'react-icons/fa'
import { FiLoader } from 'react-icons/fi'
import { Switch, message } from 'antd'
import NewShippingRateModal from './NewShippingRateModal'
import UpdateShippingRateModal from './UpdateShippingRateModal'
import CurrencyFormat from 'react-currency-format'

const RateConditionColumn = ({ rate }) => {
  switch (rate.conditionType) {
    case 'NONE': {
      return <>-</>
    }
    case 'PRICE': {
      return (
        <div>
          <CurrencyFormat
            value={rate.conditionMin > 0 ? rate.conditionMin : 0}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
          {rate.conditionMax ? (
            <>
              {' '}
              -{' '}
              <CurrencyFormat
                value={rate.conditionMax > 0 ? rate.conditionMax : 0}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            </>
          ) : (
            <div>and up</div>
          )}
        </div>
      )
    }
    default: {
      return <>-</>
    }
  }
}

const Actions = ({ shippingZoneId, rate }) => {
  const { id } = rate

  const [showRateModal, setShowRateModal] = useState(false)

  const [destroyShippingRate, { loading: destroyLoading }] = useMutation(DESTROY_SHIPPING_RATE, {
    onCompleted: () => {
      message.success('Rate Successfully Deleted')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
    refetchQueries: [
      {
        query: SHIPPING_RATES,
        variables: {
          shippingZoneId,
        },
      },
    ],
  })

  const handleDestroy = () => {
    destroyShippingRate({
      variables: {
        shippingZoneId,
        id,
      },
    })
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="flex-shrink ml-3">
          <FaEdit
            size="1.5em"
            className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500"
            onClick={() => setShowRateModal(true)}
          />
        </div>
        <div className="flex-shrink ml-3">
          {destroyLoading ? (
            <FiLoader
              size="1.5em"
              className="m-auto text-gray-400 cursor-pointer fill-current icon-spin hover:text-gray-500"
            />
          ) : (
            <FaTrash
              size="1.5em"
              className="m-auto text-gray-400 cursor-pointer fill-current hover:text-gray-500"
              onClick={handleDestroy}
            />
          )}
        </div>
      </div>
      <UpdateShippingRateModal
        showing={showRateModal}
        setShowing={setShowRateModal}
        zoneId={shippingZoneId}
        rate={rate}
      />
    </>
  )
}

const ShippingRatesPage = ({ match }) => {
  const { zoneId } = match.params

  const [showNewRateModal, setShowNewRateModal] = useState(false)

  const { loading, error, data } = useQuery(SHIPPING_RATES, {
    variables: {
      shippingZoneId: zoneId,
    },
  })

  const [updateShippingRate] = useMutation(UPDATE_SHIPPING_RATE, {
    refetchQueries: [
      {
        query: SHIPPING_RATES,
        variables: {
          shippingZoneId: zoneId,
        },
      },
    ],
    onCompleted: () => {
      message.success('Rate Successfully Updated')
    },
    onError: error => {
      message.error(error.graphQLErrors.map(e => e.message).join(', '))
    },
  })

  const handleStatusChange = (id, value) => {
    updateShippingRate({
      variables: { shippingZoneId: zoneId, id, shippingRate: { enabled: value } },
    })
  }

  const columns = [
    {
      title: 'Rate Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Condition',
      key: 'conditionType',
      dataIndex: 'id',
      render: (_, record) => <RateConditionColumn rate={record} />,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: text =>
        parseInt(text) <= 0 ? (
          <>FREE</>
        ) : (
          <CurrencyFormat
            value={text > 0 ? text : 0}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text, record) => (
        <Switch checked={record.enabled} onChange={value => handleStatusChange(record.id, value)} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => <Actions rate={record} shippingZoneId={zoneId} />,
    },
  ]

  return (
    <div className="w-full">
      <div className="sticky top-0 z-50 w-full p-2 bg-white shadow-md">
        <div className="flex items-center">
          <div className="self-center flex-1">
            <Link
              to={`/dashboard/shipping-zones`}
              className="flex text-base font-light text-gray-500 cursor-pointer hover:text-gray-600 hover:underline">
              <FaChevronLeft className="self-center flex-shrink mr-1 group-hover:text-gray-600" />
              <div className="self-center flex-shrink group-hover:text-gray-600">Shipping Zones</div>
            </Link>
          </div>

          <div className="self-center flex-shrink">
            <div className="h-full">
              <div
                onClick={() => setShowNewRateModal(true)}
                className="w-auto p-2 mb-2 text-center text-gray-300 bg-gray-800 rounded cursor-pointer hover:bg-gray-700">
                New Shipping Rate
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-4">
        {loading ? (
          <div className="w-full text-center text-gray-500">Loading...</div>
        ) : (
          <ContentWrapper>
            <Table
              columns={columns}
              dataSource={data ? data.shippingRates : []}
              loading={loading}
              error={error}
              noResultsText="No Shipping Rates"
            />
          </ContentWrapper>
        )}
      </div>

      <NewShippingRateModal showing={showNewRateModal} setShowing={setShowNewRateModal} zoneId={zoneId} />
    </div>
  )
}

export default withRouter(ShippingRatesPage)
