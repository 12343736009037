import React from 'react'
import FloatingLabelInput from 'components/FloatingLabelInput'

export const GoogleAdsDescription = ({ pixel }) => {
  return (
    <>
      <div className="flex items-center justify-between w-full">
        <div>{pixel.data.trackingId}</div>
        <div className="text-gray-500">{pixel.name}</div>
      </div>
      <div className="flex items-center justify-end w-full">
        <div className="text-yellow-500">Only Original Purchases Tracked</div>
      </div>
    </>
  )
}

export const GoogleAdsForm = ({ pixel, setPixel }) => {
  return (
    <>
      <FloatingLabelInput
        label="Nickname"
        value={pixel.name}
        onChange={e => setPixel({ ...pixel, name: e.target.value })}
      />
      <div className="mt-2">
        <FloatingLabelInput
          label="Account ID"
          value={pixel.data.trackingId}
          onChange={e => setPixel({ ...pixel, data: { ...pixel.data, trackingId: e.target.value } })}
        />
      </div>
      <div className="mt-2">
        <FloatingLabelInput
          label="Purchase Event Label"
          value={pixel.data.purchaseLabel}
          onChange={e => setPixel({ ...pixel, data: { ...pixel.data, purchaseLabel: e.target.value } })}
        />
      </div>
    </>
  )
}
