import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_SHOP_PAYMENT_GATEWAY, SHOP_PAYMENT_GATEWAYS } from '../GraphQL'
import { Input } from 'antd'

const NMIForm = ({ onClose }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [createShopPaymentGateway, { error, loading }] = useMutation(CREATE_SHOP_PAYMENT_GATEWAY, {
    refetchQueries: [
      {
        query: SHOP_PAYMENT_GATEWAYS,
      },
    ],
    onCompleted: data => {
      if (!error) {
        onClose(data.createShopPaymentGateway.id)
      }
    },
  })

  const handleCreate = () => {
    createShopPaymentGateway({
      variables: {
        slug: 'nmi',
        fields: {
          username,
          password,
        },
      },
    })
  }

  return (
    <div>
      <Input size="large" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
      <Input
        className="mt-4"
        size="large"
        placeholder="Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      {loading ? (
        <div className="mt-4 p-2 text-sm text-white text-center bg-gray-500 rounded hover:bg-gray-600">Loading...</div>
      ) : (
        <div
          className="mt-4 p-2 text-sm text-white text-center bg-gray-800 cursor-pointer rounded hover:bg-gray-700"
          onClick={handleCreate}>
          Connect
        </div>
      )}
    </div>
  )
}

export default NMIForm
