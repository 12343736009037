import React from 'react'
import { FaChevronDown } from 'react-icons/fa'
import FunnelStepCard from './FunnelStepCard'
import DownsellCard from './DownsellCard'
import NewFunnelStepCard from './NewFunnelStepCard'
import { FiLoader } from 'react-icons/fi'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { useMutation } from '@apollo/client'
import { FUNNEL, MOVE_FUNNEL_STEP_UP, MOVE_FUNNEL_STEP_DOWN } from './GraphQL'

const UpsellCard = ({ index, funnel, funnelStep, isUpsell, startDate, endDate }) => {
  const [moveFunnelStepUp, { loading: upLoading }] = useMutation(MOVE_FUNNEL_STEP_UP, {
    onError: error => {},
  })

  const [moveFunnelStepDown, { loading: downLoading }] = useMutation(MOVE_FUNNEL_STEP_DOWN, {
    onError: error => {},
  })

  const moveUp = () => {
    moveFunnelStepUp({
      variables: {
        funnelId: funnel.id,
        id: funnelStep.id,
      },
      refetchQueries: [
        {
          query: FUNNEL,
          variables: {
            id: funnel.id,
          },
        },
      ],
    })
  }

  const moveDown = () => {
    moveFunnelStepDown({
      variables: {
        funnelId: funnel.id,
        id: funnelStep.id,
      },
      refetchQueries: [
        {
          query: FUNNEL,
          variables: {
            id: funnel.id,
          },
        },
      ],
    })
  }

  return (
    <>
      <div className="flex items-center">
        <div className="flex flex-col justify-between flex-initial h-full mr-3">
          {upLoading || downLoading ? (
            <FiLoader className="mb-2 text-4xl text-gray-400 icon-spin" />
          ) : (
            <>
              <BsArrowUp
                className="mb-2 text-4xl text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={() => moveUp()}
              />
              <BsArrowDown
                className="mt-2 text-4xl text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={() => moveDown()}
              />
            </>
          )}
        </div>
        <div className="w-full">
          {!funnelStep.enabled ? <div className="text-base text-yellow-700 uppercase">Offer Disabled</div> : null}

          <div
            className={`w-full rounded shadow-xl border-2 overflow-hidden ${
              funnelStep.enabled ? 'border-gray-200' : 'border-yellow-400 bg-yellow-100'
            }`}>
            <FunnelStepCard
              index={index}
              funnel={funnel}
              funnelStep={funnelStep}
              isUpsell={isUpsell}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </div>

      <div className="w-full my-6 text-center">
        <FaChevronDown className="m-auto text-xl text-gray-500" />
      </div>

      {funnelStep.childFunnelStep ? (
        <DownsellCard
          funnel={funnel}
          parentFunnelStep={funnelStep}
          funnelStep={funnelStep.childFunnelStep}
          index={index}
          isUpsell={false}
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <NewFunnelStepCard funnel={funnel} parentFunnelStep={funnelStep} index={index} isUpsell={false} />
      )}

      <div className="w-full my-6 text-center">
        <FaChevronDown className="m-auto text-xl text-gray-500" />
      </div>
    </>
  )
}

export default UpsellCard
