import gql from 'graphql-tag'

export const SHOP = gql`
  query shopSettings {
    shop {
      id
      name
      shortId
    }
  }
`
