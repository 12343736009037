import gql from 'graphql-tag'

export const INTEGRATIONS = gql`
  query shopIntegrations {
    shopIntegrations {
      id
      enabled
      apiData
      service
    }
  }
`

export const CREATE_SHOP_INTEGRATION = gql`
  mutation CreateShopIntegration($shopIntegration: CreateShopIntegrationInput!) {
    createShopIntegration(shopIntegration: $shopIntegration) {
      id
      enabled
      apiData
      service
    }
  }
`

export const UPDATE_SHOP_INTEGRATION = gql`
  mutation UpdateShopIntegration($id: ID!, $shopIntegration: UpdateShopIntegrationInput!) {
    updateShopIntegration(id: $id, shopIntegration: $shopIntegration) {
      id
      enabled
      apiData
      service
    }
  }
`

export const DESTROY_SHOP_INTEGRATION = gql`
  mutation DestroyShopIntegration($id: ID!) {
    destroyShopIntegration(id: $id) {
      id
    }
  }
`
