import React, { useState, useMemo, useEffect } from 'react'
import { Checkbox, Input } from 'antd'
import CountryRegionData from 'country-region-data/data.json'
import { FiChevronDown, FiChevronUp, FiSearch } from 'react-icons/fi'
import ReactCountryFlag from 'react-country-flag'
import Fuse from 'fuse.js'

const Country = ({ country, selected, setSelected }) => {
  const [showRegions, setShowRegions] = useState(false)

  const regionCount = useMemo(() => country.regions.length, [country.regions])
  const selectedRegionCount = useMemo(() => {
    if (selected[country.countryShortCode]) {
      return selected[country.countryShortCode].includes('*')
        ? country.regions.length
        : selected[country.countryShortCode].length
    } else {
      return 0
    }
  }, [selected, country.countryShortCode, country.regions])

  const hasRegions = useMemo(() => regionCount > 1, [regionCount])
  const countrySelected = useMemo(() => {
    return (
      selected &&
      Object.keys(selected).includes(country.countryShortCode) &&
      selected[country.countryShortCode] !== undefined
    )
  }, [selected, country.countryShortCode])

  const indeterminate = useMemo(() => {
    if (selected[country.countryShortCode]) {
      if (selected[country.countryShortCode].includes('*')) return false
      if (selected[country.countryShortCode].length === regionCount) return false
      if (selected[country.countryShortCode].length > 0) return true
      return false
    } else {
      return false
    }
  }, [selected, country.countryShortCode, regionCount])

  const handleCheckbox = checked => {
    if (checked.target.checked) {
      setSelected(current => ({ ...current, [country.countryShortCode]: ['*'] }))
    } else {
      setSelected(current => ({ ...current, [country.countryShortCode]: undefined }))
    }
  }

  return (
    <>
      <div className="px-3 py-3 border-b border-gray-400 cursor-pointer hover:bg-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="">
              <Checkbox checked={countrySelected} onChange={handleCheckbox} indeterminate={indeterminate} />
            </div>
            <div className="ml-4">
              <ReactCountryFlag
                svg
                style={{
                  width: '2.3em',
                  height: '2.3em',
                }}
                countryCode={country.countryShortCode}
              />
            </div>
            <div className="ml-2 text-sm text-gray-800">{country.countryName}</div>
          </div>
          {hasRegions ? (
            <div className="flex items-center text-gray-500" onClick={() => setShowRegions(showing => !showing)}>
              <div className="">
                {selectedRegionCount} of {regionCount} regions
              </div>
              <div className="ml-1 text-lg">{showRegions ? <FiChevronUp /> : <FiChevronDown />}</div>
            </div>
          ) : null}
        </div>
      </div>
      {showRegions ? (
        <div className="">
          {country.regions
            .filter(r => r.shortCode)
            .map((region, i) => (
              <Region
                region={region}
                country={country}
                selected={selected}
                setSelected={setSelected}
                key={`${country.countryShortCode}-${region.shortCode}-${i}`}
              />
            ))}
        </div>
      ) : null}
    </>
  )
}

const Region = ({ region, country, selected, setSelected }) => {
  const regionSelected = useMemo(() => {
    if (selected && Object.keys(selected).includes(country.countryShortCode) && selected[country.countryShortCode]) {
      return (
        selected[country.countryShortCode] &&
        (selected[country.countryShortCode].includes(region.shortCode) ||
          selected[country.countryShortCode].includes('*'))
      )
    } else {
      return false
    }
  }, [selected, country.countryShortCode, region.shortCode])

  const handleCheckbox = checked => {
    const countryRegions = selected[country.countryShortCode]

    if (checked.target.checked) {
      if (countryRegions) {
        // Checked when country has others selcted
        setSelected(current => ({ ...current, [country.countryShortCode]: [...countryRegions, region.shortCode] }))
      } else {
        // Checked first region from country
        setSelected(current => ({ ...current, [country.countryShortCode]: [...[], region.shortCode] }))
      }
    } else {
      if (countryRegions) {
        // Uncheck region when country has key
        if (countryRegions.includes('*')) {
          // Uncheck region when all country is selcted
          setSelected(current => ({
            ...current,
            [country.countryShortCode]: country.regions.map(r => r.shortCode).filter(r => r !== region.shortCode),
          }))
        } else {
          // Uncheck region when other regions are individually selected
          setSelected(current => ({
            ...current,
            [country.countryShortCode]: countryRegions.filter(r => r !== region.shortCode),
          }))
        }
      }
    }
  }

  return (
    <div className="flex items-center py-2 pl-8 border-b border-gray-400 cursor-pointer hover:bg-gray-200">
      <div className="">
        <Checkbox checked={regionSelected} onChange={handleCheckbox} />
      </div>
      <div className="ml-4 text-gray-800">{region.name}</div>
    </div>
  )
}

const ShippingZoneCountries = ({ selected, setSelected }) => {
  const [query, setQuery] = useState('')
  const [isRestOfWorld, setIsRestOfWorld] = useState(Object.keys(selected).includes('*'))

  const fuse = new Fuse(
    CountryRegionData.filter(c => c.countryShortCode),
    {
      keys: ['countryName', 'countryShortCode', 'regions.name', 'regions.shortCode'],
    }
  )

  const filterCountries = term => {
    return term.length > 0 ? fuse.search(term).map(r => r.item) : CountryRegionData.filter(c => c.countryShortCode)
  }

  const handleRestOfWorld = checked => {
    setIsRestOfWorld(checked)
    if (checked) {
      setSelected({ '*': ['*'] })
    } else {
      setSelected({})
    }
  }

  useEffect(() => {
    if (isRestOfWorld) {
      if (Object.keys(selected).length > 1) {
        setIsRestOfWorld(false)
        setSelected(current => ({ ...current, '*': undefined }))
      }
    }
  }, [isRestOfWorld, selected, setSelected])

  return (
    <>
      <div className="">
        <Input
          size="large"
          placeholder="Search countries"
          prefix={<FiSearch />}
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
      </div>
      <div className="mt-4 overflow-y-scroll border-t border-gray-400" style={{ maxHeight: '500px' }}>
        <div className="px-3 py-5 border-b border-gray-400 cursor-pointer hover:bg-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Checkbox checked={isRestOfWorld} onChange={e => handleRestOfWorld(e.target.checked)} />
              <div className="ml-5 text-sm text-gray-800">Rest of world</div>
            </div>
          </div>
        </div>

        {filterCountries(query).map(country => (
          <Country country={country} selected={selected} setSelected={setSelected} key={country.countryShortCode} />
        ))}
      </div>
    </>
  )
}

export default ShippingZoneCountries
